import { getJson } from "../utils/httpHelper";

export const login = async (
  baseUrl,
  { username, email, password, tenantId }
) => {
  const response = await fetch(`${baseUrl}/auth/login/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      email,
      password,
      tenant_uuid: tenantId,
    }),
  });
  const responseJson = await getJson(response);
  if (response.status !== 200) {
    return {
      status: "F",
      message: responseJson,
    };
  }
  return { status: "S", message: responseJson };
};

export const register = async (baseUrl, tenantId, data) => {
  const response = await fetch(
    `${baseUrl}/clients/auth/${tenantId}/register/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const responseJson = await getJson(response);
  if (response.status !== 200) {
    return {
      status: "F",
      message: responseJson,
    };
  }
  return { status: "S", message: responseJson };
};
