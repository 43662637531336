import { AppConfig } from "../../constants/config";
import { getPPVEvents } from "../../services/ticketService";
import { CategorySliderType, createSlider } from "../Slider";

export const renderPPVEventSlider = async (
  container,
  {
    slider,
    categoryItem,
    categoryContent,
    sliderContainer,
    videoCategorySlider,
  },
  { apiKey, settings, baseUrl, template_uuid }
) => {
  const ppvEvents = await getPPVEvents(baseUrl, apiKey, {
    channel_id: settings.channelId,
  });

  if (!ppvEvents || !ppvEvents.length) {
    return;
  }

  const PPVEventSliders = ppvEvents
    .filter((ppvEvent) => {
      const eventDatesEnd = moment(ppvEvent.event_dates[2]);
      const today = moment();
      const dayafter3month = moment().add(90, "d");
      return (
        (today.isSame(eventDatesEnd) || today.isBefore(eventDatesEnd)) &&
        (eventDatesEnd.isSame(dayafter3month) || eventDatesEnd.isBefore(dayafter3month))
      );
    })
    .map((event) => ({
      ...event,
      id: event.uuid,
      title: event.name,
      thumbnail: `${AppConfig.mediaUrl}/${settings.tenantId}/tickets/${
        event.uuid
      }.png?${new Date().getTime()}`,
    }));
  $(categoryItem).html("Pay-Per-View Events");
  videoCategorySlider
    .attr("haslist", true)
    .attr("categoryslidertype", CategorySliderType.PPV);
  const sliderItem = await createSlider(
    videoCategorySlider,
    {
      settings,
      apiKey,
      baseUrl,
      template_uuid,
    },
    { results: PPVEventSliders || [] }
  );
  $(sliderContainer).append(sliderItem);
  $(categoryContent).append(sliderContainer);

  $(slider).append(categoryItem);
  $(slider).append(categoryContent);
  $(container).prepend(slider);
};
