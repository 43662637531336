import { buildHeader, buildQueryParam } from "./baseService";
import { getJson } from "../utils/httpHelper";
import {CATEGORY_TYPE_OF_LIST} from "../constants/Category";

export const getVideoListByCategory = async (baseUrl, apiKey, tenantId, categoryId, params) => {
    const api = await fetch(buildQueryParam(`${baseUrl}/clients/vod-category/${categoryId}/videos/`, params), {
        headers: buildHeader(apiKey),
    });
    return getJson(api);
}

export const getVideosByCategory = async (baseUrl, apiKey, tenantId, categoryId, params) => {
    const api = await fetch(buildQueryParam(`${baseUrl}/category/single/${CATEGORY_TYPE_OF_LIST.Video}/${categoryId}/`, params), {
        headers: buildHeader(apiKey),
    });
    return getJson(api);
}

export const getVideoById = async (baseUrl, apiKey, uuid) => {
    const api = await fetch(`${baseUrl}/media/single/${CATEGORY_TYPE_OF_LIST.Video}/${uuid}/`, {
        headers: buildHeader(apiKey),
    });
    return getJson(api);
}

export const searchVideo = async (baseUrl, apiKey, category_type, params) => {
    const api = await fetch(buildQueryParam(`${baseUrl}/media/list/${category_type}/`, params), {
        headers: buildHeader(apiKey),
    });
    return getJson(api);
}
