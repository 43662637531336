import {
  hideLoading,
  loadingPosition,
  loadingSize,
  showLoading,
} from "../Loading";
import { createSlider, SliderType } from "../Slider";
import {
  CATEGORY_SERIES_GRID,
  LevelDrop,
  createDivContainer,
  VIDEO_SEASON_GRID,
  VIDEO_SERIES_GRID,
} from "./constants";
import {
  getCategoriesList,
  CATEGORY_TYPE,
} from "../../services/categoryService";

export const getCategorySeriesGrid = async (
  container,
  { generalSettings: settings, apiKey },
  baseUrl,
  template_uuid
) => {
  let categorySeriesGrids;
  if (container) {
    categorySeriesGrids = $(container)
      .find(`.${CATEGORY_SERIES_GRID.classGridDrop}`)
      .toArray();
  } else {
    categorySeriesGrids = $(`.${CATEGORY_SERIES_GRID.classGridDrop}`)
      .toArray()
      .filter((item) => {
        return $(this).parent(".video-tab-content").length === 0;
      });
  }
  categorySeriesGrids.forEach(async (categorySeriesGrid) => {
    const videoSeasonSliderClone = $(categorySeriesGrid)
      .find(`${VIDEO_SEASON_GRID.selectorGridDropContentSlider}`)
      .clone()
      .first();

    const isEmptyHtml = function (sel) {
      return $.trim(sel.html()) === "";
    };

    const addEventClickDrop = (gridItem, classGridItem, classGridContent) => {
      gridItem.on("click", async () => {
        if (getLevel(gridItem) === LevelDrop.Level1) {
          const gridContentSelector = gridItem.next();
          const selector = gridContentSelector.find(".video-season-grid");
          if (isEmptyHtml(selector)) {
            const vdoCategoryId = gridItem.attr("data-category-id");
            if (vdoCategoryId) {
              const videoSeasonSlider = videoSeasonSliderClone
                .clone()
                .attr("videocategory", vdoCategoryId)
                .attr("slidertype", SliderType.CAROUSEL_SLIDER_DC);
              showLoading(gridItem, loadingSize.SMALL, loadingPosition.CENTER);
              const createdSlider = await createSlider(videoSeasonSlider, {
                settings,
                apiKey,
                baseUrl,
                template_uuid,
              }).catch((err) => {
                hideLoading(
                  gridItem,
                  loadingSize.SMALL,
                  loadingPosition.CENTER
                );
              });
              if (createdSlider) {
                hideLoading(
                  gridItem,
                  loadingSize.SMALL,
                  loadingPosition.CENTER
                );
                gridContentSelector
                  .find(".video-season-grid-content-slider-container")
                  .remove();
                const container = $(
                  `<div class="video-season-grid-content-slider-container" ></div>`
                );
                container.html(videoSeasonSlider);
                selector.after(container);
              }
            }
          }
        }
        $(`.${VIDEO_SERIES_GRID.classGridDropItem}`)
          .find(".s-bottom-arrow")
          .removeClass("active");
        $(gridItem).find(".s-bottom-arrow").addClass("active");
        $(`.${classGridItem}`).removeClass("active");
        $(gridItem).addClass("active");
        $(`.${classGridContent}`).not($(gridItem).next()).hide();
        $(gridItem).next().slideDown(500);
      });
    };

    const getLevel = (gridItem) => {
      const parent = $(gridItem).parent();
      switch (true) {
        case parent.hasClass(CATEGORY_SERIES_GRID.classGridDrop):
          return LevelDrop.Level0;
        case parent.hasClass(VIDEO_SERIES_GRID.classGridDrop):
          return LevelDrop.Level1;
        case parent.hasClass(CATEGORY_SERIES_GRID.classGridDropContent):
          return LevelDrop.Level2;
      }
      return "";
    };

    const addEventClickVideoSeasonGrid = (gridItem, data) => {
      gridItem.on("click", async () => {
        showLoading(gridItem, loadingSize.SMALL);
        $(`.${VIDEO_SEASON_GRID.classGridDropItem}`).removeClass("active");
        $(gridItem).addClass("active");
        if (
          !$(gridItem).next().hasClass(VIDEO_SEASON_GRID.classGridDropContent)
        ) {
          const videoSeasonSlider = videoSeasonSliderClone
            .clone()
            .attr("videocategory", data.id);
          const slider = await createSlider(videoSeasonSlider, {
            settings,
            apiKey,
            baseUrl,
            template_uuid,
          });
          const videoSeasonContent =
            VIDEO_SEASON_GRID.componentsGridDropContent(data, slider);
          $(gridItem).after(videoSeasonContent);
        }
        hideLoading(gridItem, loadingSize.SMALL);
        $(`.${VIDEO_SEASON_GRID.classGridDropContent}`)
          .not($(gridItem).next())
          .hide();
        $(gridItem).next().slideDown(500);
        $(`.${VIDEO_SEASON_GRID.classGridDropContent}`)
          .find("[role=slidelist]")
          .slick("refresh");
      });
    };

    const createVideoSeasonGrid = (data) => {
      const videoSeasonGrid = createDivContainer(
        VIDEO_SEASON_GRID.classGridDrop
      );
      data.forEach((item) => {
        const videoSeasonItem = VIDEO_SEASON_GRID.componentsGridDropItem(item);
        videoSeasonGrid.append(videoSeasonItem);
        addEventClickVideoSeasonGrid(videoSeasonItem, item);
      });
      return videoSeasonGrid;
    };

    const createVideoSeriesGrid = (data) => {
      const videoSeriesGrid = createDivContainer(
        VIDEO_SERIES_GRID.classGridDrop
      );
      data.forEach((item) => {
        const videoSeriesItem = VIDEO_SERIES_GRID.componentsGridDropItem(item);
        const videoSeriesContent = VIDEO_SERIES_GRID.componentsGridDropContent(
          item,
          createVideoSeasonGrid(item.children)
        );
        addEventClickDrop(
          videoSeriesItem,
          VIDEO_SERIES_GRID.classGridDropItem,
          VIDEO_SERIES_GRID.classGridDropContent
        );
        videoSeriesGrid.append(videoSeriesItem).append(videoSeriesContent);
      });
      return videoSeriesGrid;
    };

    $(categorySeriesGrid).empty();
    showLoading(
      $(categorySeriesGrid),
      loadingSize.MEDIUM,
      loadingPosition.CENTER
    );
    // Fetch on Demand categories
    const categorySeries = await getCategoriesList(
      baseUrl,
      apiKey,
      settings.tenantId,
      { type: CATEGORY_TYPE.SERIES }
    );
    hideLoading(
      $(categorySeriesGrid),
      loadingSize.MEDIUM,
      loadingPosition.CENTER
    );
    if (categorySeries?.results?.length > 0) {
      categorySeries?.results.forEach((element) => {
        const categorySeriesItem =
          CATEGORY_SERIES_GRID.componentsGridDropItem(element);
        const categorySeriesContent =
          CATEGORY_SERIES_GRID.componentsGridDropContent(element).append(
            createVideoSeriesGrid(element.children)
          );
        addEventClickDrop(
          categorySeriesItem,
          CATEGORY_SERIES_GRID.classGridDropItem,
          CATEGORY_SERIES_GRID.classGridDropContent
        );
        $(categorySeriesGrid)
          .append(categorySeriesItem)
          .append(categorySeriesContent);
      });
    }
  });
};
