import { createButtonPlay } from "./ButtonPlay";
import { createVideoDescription } from "./VideoDescription";
import { getStatus } from "../../utils/analyticsHelper";
import ClapprStats from "../AnalyticsVideo/clappr-stats";
import { mediaControl } from "./VideoControl";
import { getVideoById } from "../../services/videoService";
import { ContentType } from "../../constants/ContentType";
import { setupPPV } from "../../utils/paymentHelper";
import { searchUrlParam } from "../../utils/httpHelper";
import { appContentAccess } from "../../services/paymentService";

export const renderVideoNoCategory = async (
  { videoPlayerContainer, videoPlayer, videoDescrption, track },
  { settings, apiKey },
  baseUrl,
  videoId
) => {
  $(videoPlayerContainer).css("grid-template-columns", "1fr");
  $(videoPlayerContainer).find(".video-player-item.utils").remove();
  videoPlayer.empty().attr("id", "playerClappr");
  videoDescrption.empty();

  const video = await getVideoById(baseUrl, apiKey, videoId);
  if (!video) {
    return;
  }

  // Setup video
  const content = $(createVideoDescription(video));
  $(videoDescrption).append(content);
  mediaControl(video);

  const player = new Clappr.Player({
    plugins: [HlsjsPlayback, ClapprStats],
    poster: video?.thumbnail,
    width: "100%",
    height: "100%",
    autoPlay: false,
    mimeType: "video/mp4",
    chromeless: true,
    parentId: "#playerClappr",
    mute: true,
    maxBufferLength: 6,
    hlsPlayback: {
      preload: true,
    },
    clapprStats: {
      runEach: "extra.duration",
      onFirstReport: async () => {
        track.resetStatus(getStatus());
        track.send("Start", track.getRqStart());
      },
      onReport: (metrics) => {
        const reportStatus = track.getCurrent().reportStatus;
        if (!reportStatus?.send?.end) {
          track.send("End", track.getRqEnd(metrics));
        }
      },
      runBandwidthTestEvery: 10,
    },
    playback: {
      disableContextMenu: true,
      extrapolatedWindowNumSegments: 10,
      triggerFatalErrorOnResourceDenied: false,
    },
  });

  // if (!video.trailer?.url) {
  player.configure({
    source: Clappr.Utils.Media.mp4,
    poster: video.thumbnail,
    autoPlay: false,
    chromeless: true,
  });
  player.getPlugin("poster").$playWrapper.hide();
  // } else {
  //   player.configure({
  //     source: formatCacheFlyVideoUrl(video.trailer?.url),
  //     poster: video.thumbnail,
  //     autoPlay: true,
  //     chromeless: true,
  //   });
  //  }

  // Validate Content Access
  let app_content_plans = await appContentAccess(baseUrl, apiKey);
  if (
    app_content_plans.hasOwnProperty("plans") &&
    app_content_plans["plans"].length > 0
  ) {
    setupPPV(
      baseUrl,
      apiKey,
      app_content_plans["plans"],
      videoPlayer,
      ContentType.TENANT,
      {}
    );
    return;
  }

  const isOrderProcessing =
    localStorage.getItem("isOrderProcessing") ||
    searchUrlParam("isOrderProcessing") === "true";
  if (isOrderProcessing && video.packages.length > 0) {
    let content_type;
    if (video.packages.filter((p) => p.content_type == ContentType.VOD)[0]) {
      content_type = ContentType.VOD;
    } else if (
      video.packages.filter((p) => p.content_type == ContentType.VIDEO)[0]
    ) {
      content_type = ContentType.VIDEO;
    }

    setupPPV(baseUrl, apiKey, video, videoPlayer, content_type, {}, true);
  }
  const playButton = $(createButtonPlay(video));
  playButton.on("click", async function () {
    if (video.packages.length > 0) {
      let content_type;
      if (video.packages.filter((p) => p.content_type == ContentType.VOD)[0]) {
        content_type = ContentType.VOD;
      } else if (
        video.packages.filter((p) => p.content_type == ContentType.VIDEO)[0]
      ) {
        content_type = ContentType.VIDEO;
      }

      setupPPV(baseUrl, apiKey, video, videoPlayer, content_type, {}, true);
    } else {
      playButton.hide();
      player.configure({ chromeless: false });
      player.load(video.url, "video/mp4", true);
    }
  });
  playButton.appendTo(videoPlayer);
};
