import {
  hideLoading,
  loadingPosition,
  loadingSize,
  showLoading,
} from "../Loading";
import { formatUrl } from "../../utils/domHelper";
import {
  CATEGORY_TYPE,
  getCategoriesList,
} from "../../services/categoryService";
import { $ } from "@clappr/core";

export const getGalleryCategory = async (
  { generalSettings: settings, apiKey },
  baseUrl,
  template_uuid
) => {
  const galleryCategories = $(`[role=gallery]`).toArray();
  if (galleryCategories.length > 0) {
    galleryCategories.forEach(async (galleryCategory) => {
      const galleryItem = $(galleryCategory)
        .find("[role=galleryitem]")
        .first()
        .clone();
      $(galleryCategory).empty();
      showLoading(
        $(galleryCategory),
        loadingSize.MEDIUM,
        loadingPosition.CENTER
      );
      let categories = await getCategoriesList(
        baseUrl,
        apiKey,
        settings.tenantId,
        {
          type: CATEGORY_TYPE.ON_DEMAND,
          page: 1,
          page_size: 10,
          has_video: true,
        }
      );
      categories = categories?.results?.filter(
        (c) =>
          c.videos.length > 0 &&
          c.videos.filter((v) => v.type !== "playlist").length > 0
      );
      if (categories.length > 5) {
        categories = categories.slice(0, 5);
      }
      if (categories.length === 0) {
        $(`[role=gallery]`).html('<p>Please upload your video gallery.</p>');
        $(`[role=gallery]`).css("height","auto");
      } else {
        categories.forEach((category) => {
          const clone = galleryItem.clone();
          $(clone).find("[role=gallerytitle]").html(category?.title);
          $(clone)
            .find("[role=gallerydescription]")
            .html(category?.short_description);
          $(clone).find("img").attr("src", category.thumbnail);
          $(clone).attr("categoryID", category?.id);
          $(clone).on("click", () => {
            const nextURL = `/watch-movies?categoryId=${category?.id}`;
            window.location = formatUrl(nextURL);
          });
          $(galleryCategory).append(clone);
        });
      }
      hideLoading(
        $(galleryCategory),
        loadingSize.MEDIUM,
        loadingPosition.CENTER
      );
    });
  }
};
