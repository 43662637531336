import { redirectTo, searchUrlParam } from "../../utils/httpHelper";
import { formatUrl } from "../../utils/domHelper";
import { renderAuthHeader } from "../Header";

export default async function () {
  const subscriptionWrapper = document.getElementById("thankyou-container");
  const token = localStorage.getItem("loginToken");

  if (subscriptionWrapper) {
    $(".auto-login-link").click(async (e) => {
      e.preventDefault();
      window.location = formatUrl("/");
    });
  }

  if (token) {
    renderAuthHeader(token);
  }

  $("#in9ur").click(function (e) {
    e.preventDefault();

    redirectTo(); 
  });

}
