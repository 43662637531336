import {
  getDefaultReportVideoChannel,
  sendReportChannelStatistic,
  getRqStartVideoChannel,
  getStatus,
} from "../../utils/analyticsHelper";
import Tracking from "../AnalyticsVideo/Tracking";
import { getChannels } from "../../services/channelService";
import AntPlayer from "../AntPlayer";
import { setupUpComingStream } from "../../utils/streamHelper";

export const renderChannelLive = async (
  { generalSettings: settings, apiKey },
  baseUrl
) => {
  const videoStream = $(".bc-channel-live");
  if (videoStream.length > 0) {
    $(".bc-channel-list ul").empty();
    const { results } = await getChannels(baseUrl, apiKey);
    let list = "";
    results.forEach((c) => {
      list += `<li id="${c.id}">${c.title}</li>`;
    });
    $(".bc-channel-list ul").append(list);
    if (results.length > 0) {
      $(".bc-channel-list ul li")[0].classList.add("selected");
    }

    let selectedChannel = results[0];
    const track = new Tracking(
      { settings, apiKey, baseUrl },
      sendReportChannelStatistic,
      getRqStartVideoChannel
    );
    const player = new AntPlayer(".bc-channel-live", {
      playOrder: "hls",
      onPlayerStarted: () => {
        track.init(getDefaultReportVideoChannel(selectedChannel?.id));
        const reportStatus = track.getCurrent().reportStatus;
        if (reportStatus?.send?.start === false) {
          track.resetStatus(getStatus(true));
          track.send("Start", track.getRqStart());
        }
      },
      onPlayerEnded: (metrics) => {
        const reportStatus = track.getCurrent().reportStatus;
        if (!reportStatus?.send?.end) {
          track.send("End", track.getRqEnd(metrics));
        }
      },
    });

    if (window.activeChannelSocket) {
      window.activeChannelSocket.close();
      clearInterval(window.retrivePlaying);
    }

    player.playExternalStream(selectedChannel.stream_url);
    // if (selectedChannel.stream_mode === "EXTERNAL") {
    //   player.playExternalStream(selectedChannel.stream_url);
    // } else {
    //   setupUpComingStream(player, selectedChannel, settings, apiKey);
    // }

    window.onbeforeunload = function () {
      if (player) {
        player.destroy();
      }
      return null;
    };

    $(".bc-channel-list ul li").click((e) => {
      $(".bc-channel-list ul li").removeClass();
      e.target.classList.add("selected");
      const channel = results.filter(
        (r) => r.id === e.target.attributes.id.value
      )[0];
      if (!channel || channel?.stream_url.length === 0) {
        return;
      }
      selectedChannel = channel;

      if (window.activeChannelSocket) {
        window.activeChannelSocket.close();
        clearInterval(window.retrivePlaying);
      }

      player.playExternalStream(channel.stream_url);
      
      // if (channel.stream_mode === "EXTERNAL") {
      //   player.playExternalStream(channel.stream_url);
      // } else {
      //   setupUpComingStream(player, channel, settings, apiKey);
      // }
    });
  }
};
