import { getSubscriptions } from "../components/Subscriptions";
import { getSliders } from "../components/Slider";
import handleThankYou from "../components/ThankYou";
import {
  addScript,
  addStyle,
  hideLoading,
  tempRouting,
  formatUrl,
  showLoading,
} from "../utils/domHelper";
import { getVideoPlayer } from "../components/VideoPlayer";
import { getAudioPlayer } from "../components/AudioPlayer";
import { getBannerSlider } from "../components/BannerSlider/entertainment";
import { getBannerSliderPlayTV } from "../components/BannerSlider/playtv";
import { getVideoTabs } from "../components/VideoTabs";
import { watchLive } from "../components/WatchLive";
import { getCategorySeriesGrid } from "../components/CategorySeriesGrid";
import { getCategories } from "../components/Categories";

import CheckoutFormHandler, {
  completeCheckout,
} from "../components/Form/CheckoutForm";
import LoginFormHandler from "../components/Form/LoginForm";
import SignUpFormHandler from "../components/Form/SignUpForm";
import {singupPlansField, signupSetupPayment} from "../components/Form/SignUpForm";

import { getFeaturedBanner } from "../components/FeaturedBanner";
import { getVODCategories } from "../components/VODCategories";
import { getVODCategoriesSlider } from "../components/VODCategoriesSlider";
import { getVODCategorySeries } from "../components/VODCategorySeries";
import { getLatestVideos } from "../components/LatestVideo";
import { renderChannelLive } from "../components/ChannelLive";
import { getGalleryCategory } from "../components/GalleryCategory";
import { getAudioCategories } from "../components/AudioCategories";
import { getAODCategorySeries } from "../components/AudioCategorySeries";
import { getChannelSliders } from "../components/Slider/Channel";
import { registerCancelCheckoutOrder } from "../components/PPV";
import { searchMedia } from "../components/SearchMedia";
import { getAudioCategoriesSlider } from "../components/AudioCategoriesSlider";
import { renderPPVEvent } from "../components/PPVEvent";
import { embeddedStream } from "../components/EmbeddedStream";

const registerDOMEvent = () => {
  registerCancelCheckoutOrder();
  // Clean socket
  window.addEventListener("unload", function () {
    if (
      window.activeChannelSocket &&
      window.activeChannelSocket.readyState == WebSocket.OPEN
    ) {
      window.activeChannelSocket.close();
      clearInterval(window.retrivePlaying);
    }
  });
};

const initFormIO = async ({ baseUrl, settings, uuid }) => {
  const formContentWrapper = document.getElementsByClassName(
    "form-content-wrapper"
  );

  const getFormCallback = ({ callbackData, formName, settings, baseUrl }) => {
    if (formName === "signIn") {
      return async () =>
        LoginFormHandler(baseUrl, settings.tenantId, callbackData);
    }
    if (formName === "signUp") {
      return async () =>
        SignUpFormHandler(baseUrl, settings.tenantId, callbackData);
    }
    if (formName === "billing") {
      const selfData = callbackData;
      return async () => {
        const resJson = await completeCheckout(
          selfData._data,
          settings,
          baseUrl
        );
        if (resJson.status === "F") {
          callbackData.emit("submitDone", "");
          return;
        }
        let searchParams = new URLSearchParams(window.location.search);
        const redirectTo = searchParams.get("redirect_to");
        window.location = formatUrl(
          `/thank-you${redirectTo ? `?redirect_to=${redirectTo}` : ""}`
        );
        callbackData.emit("submitDone", "");
      };
    }
  };

  if (formContentWrapper && formContentWrapper.length) {
    try {
      showLoading();
      const formData = await fetch(`${baseUrl}/block/list/`, {
        method: "GET",
        headers: {
          Authorization: `Tenant-Key ${settings.apiKey}`,
        },
      });
      const jsonForms = await formData.json();
      await handleThankYou(settings, baseUrl);

      Array.from(formContentWrapper).forEach(async (fw) => {
        const formName = fw.attributes.formtype.value;
        const currentForm = jsonForms.find((el) => el.name === formName);
        let components = currentForm.components.form;
        let subs_component = null;

        // Singup Form
        if (formName == 'Sign Up'){
          let urlSearchParams = new URLSearchParams(window.location.search);
          let params = Object.fromEntries(urlSearchParams.entries());
          let channelId = 'empty';
          if(params.channelId != undefined){
            channelId = params.channelId;
          }

          subs_component = await singupPlansField(baseUrl, settings.apiKey, channelId);
          if (subs_component != null){
            components.splice(components.length -1, 0 , subs_component);
          }
        }

        // console.log(currentForm);
        Formio.createForm(
          fw,
          {
            components: components,
          },
          {
            noAlerts: true,
          }
        )
        .then(function (form) {
          // Singup Form
          if (formName == 'Sign Up'){
            $('.formio-component-submit').append(`
              <p style="margin-top: 30px;text-align: center;">Already have account? <a href="/sign-in" style="font-weight: bold;text-decoration: underline;font-size: 18px;">Login</a></p>
            `);
            if(subs_component != null){
              signupSetupPayment(baseUrl, settings.apiKey);
            }
          }

          form.on(
            "submit",
            getFormCallback({
              callbackData: form,
              formName: currentForm.key,
              settings: settings.generalSettings,
              baseUrl: baseUrl,
              uuid: uuid,
            })
          );
          if (currentForm.key === "billing") {
            CheckoutFormHandler(settings, form, baseUrl);
          }
        })
        .finally(() => {
          hideLoading();
        });

        $('head').append($('<style>').text(`.choices__list {color: black} #stripe-card-element{background: white;padding: 12px;border-radius: 4px;}`));
      });
    } catch (err) {
      hideLoading();
    }
  }
};

export default function App(data = {}) {
  const { configs, settings, base_url: baseUrl, uuid } = data;
  const pageConfig = configs.content;
  // eslint-disable-next-line no-undef
  const editor = grapesjs.init({
    container: "#gjs",
    components: pageConfig["gjs-components"],
    style: pageConfig["gjs-styles"],
    log: ["error"],
  });

  const template = document.createElement("template");
  template.innerHTML = `${editor.getHtml()}`;
  localStorage.setItem("template_uuid", uuid);

  // Remove later when devops fix routing
  tempRouting(uuid);

  // eslint-disable-next-line no-undef
  $(document).ready(async function () {
    hideLoading();
    new WOW().init({
      boxClass: "wow",
      headless: true,
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    // add hidden for class container - fix scroll animation
    $("body > div").css("overflow-x", "hidden");
    $(".video-tabs").hide();
    addScript(pageConfig["gjs-scripts"]);

    await getBannerSlider(settings, baseUrl, uuid);
    await getBannerSliderPlayTV(settings, baseUrl, uuid);
    await getSliders(settings, baseUrl, uuid);
    await getVideoPlayer(settings, baseUrl);
    await getAudioPlayer(settings, baseUrl);
    await getSubscriptions(settings, baseUrl, uuid);
    await handleThankYou(settings, baseUrl, uuid);
    await getVideoTabs(settings, baseUrl, uuid);
    await watchLive(settings, baseUrl, uuid);
    await getCategorySeriesGrid(null, settings, baseUrl, uuid);
    await getCategories(null, settings, baseUrl, uuid);
    await getVODCategories(null, settings, baseUrl, uuid);
    await getVODCategoriesSlider(null, settings, baseUrl, uuid);
    await getAudioCategories(null, settings, baseUrl, uuid);
    await getAudioCategoriesSlider(null, settings, baseUrl, uuid);
    await getAODCategorySeries(null, settings, baseUrl, uuid);
    await getVODCategorySeries(null, settings, baseUrl, uuid);
    await getFeaturedBanner(settings, baseUrl, uuid);
    await getLatestVideos(settings, baseUrl, uuid);
    await renderChannelLive(settings, baseUrl, uuid);
    await getGalleryCategory(settings, baseUrl, uuid);
    await getChannelSliders(settings, baseUrl, uuid);
    await searchMedia(settings, baseUrl, uuid);
    await renderPPVEvent(settings, baseUrl);
    await embeddedStream(settings, baseUrl, uuid);

    // DOM registered
    registerDOMEvent();

    await initFormIO({ baseUrl, settings, uuid });
  });

  addStyle(editor.getCss());
  // Return a new node from template
  return template.content.cloneNode(true);
}
