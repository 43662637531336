export const loadingSize = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};
export const loadingPosition = {
  CENTER: "center",
  STAND_BY: "stand-by",
  BOTTOM: "bottom",
};

export const showLoading = (
  element,
  size,
  position = loadingPosition.STAND_BY
) => {
  if (position === loadingPosition.CENTER) {
    element.addClass("lds-ring-container");
    $(
      `<div class="lds-ring-overlay"><div class="lds-ring ${size} ${position}"><div></div><div></div><div></div><div></div></div></div>`
    ).appendTo(element);
  } else {
    $(
      `<div class="lds-ring ${size} ${position}"><div></div><div></div><div></div><div></div></div>`
    ).appendTo(element);
  }
};
export const hideLoading = (
  element,
  size,
  position = loadingPosition.STAND_BY
) => {
  if (position === loadingPosition.CENTER) {
    element.removeClass("lds-ring-container");
    element.find(`.lds-ring-overlay`).remove();
  }
  element.find(`.lds-ring.${size}.${position}`).remove();
};
