import { PlaybackControl } from "./PlaybackControl"

const defaultStyle = `
  position: static;
  float: left;
  z-index: 999;
  height: 32px;
  width: 28px;
  cursor: pointer;
  opacity: 0.8;
  transform: scale(1.2);
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23FFFFFF'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M6 6h2v12H6zm3.5 6l8.5 6V6z'/%3E%3C/svg%3E") no-repeat center center;
`;

export class SkipPrevious extends PlaybackControl {

  get name() { return 'skip-previous' }
  get attributes() { return { class: 'skip-previous' } }

  handleClickEvent() {
    this.options.selectAudio(this.options.audioIndex - 1);
  }

  renderControl() {
    if (this.options.audioIndex > 0) {
      this.$el.append(Clappr.Styler.getStyleFor(`
        .skip-previous { 
          ${defaultStyle}
          pointer-events: auto;
        }
      `));
    } else {
      this.$el.append(Clappr.Styler.getStyleFor(`
        .skip-previous { 
          ${defaultStyle}
          pointer-events: none;
          opacity: 0.1;
        }
    `));
    }
    
    this.core.mediaControl && this.core.mediaControl.$('.media-control-left-panel').prepend(this.el);
    return this;
  }
}