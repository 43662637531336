import { createButtonPlay } from "./ButtonPlay";
import { createAudioDescription } from "./AudioDescription";
import { createAudioItem } from "./AudioItem";
import { createAudioNextContent } from "./AudioNextContent";
import {
  getDefaultReportAOD,
  getRqStartAOD,
  getStatus,
  sendReportChannelStatistic,
} from "../../utils/analyticsHelper";
import ClapprStats from "../AnalyticsVideo/clappr-stats";
import Tracking from "../AnalyticsVideo/Tracking";
import { mediaControl } from "./AudioControl";
import { getAudioById } from "../../services/audioService";
import { getAODCategoryById } from "../../services/categoryService";
import { FastForward } from "./FastForward";
import { FastBackward } from "./FastBackward";
import { SkipNext } from "./SkipNext";
import { SkipPrevious } from "./SkipPrevious";
import { ContentType } from "../../constants/ContentType";
import { setupPPV } from "../../utils/paymentHelper";
import { NotFoundBannerResource } from "../../constants/Media";
import { searchUrlParam } from "../../utils/httpHelper";
import { renderAudioNoCategory } from "./SingleAudioPlayer";
import { appContentAccess } from "../../services/paymentService";

export const getAudioPlayer = async (
  { generalSettings: settings, apiKey },
  baseUrl
) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const audioPlayerContainer = document.querySelector(
    ".audio-player-container"
  );
  if (audioPlayerContainer == null) {
    return;
  }

  const audioPlayer = $(audioPlayerContainer).find(".audio-player-item.player");
  const audioList = $(audioPlayerContainer).find(
    ".audio-player-item.suggestion .audio-list"
  );
  const videoNextContent = $(audioPlayerContainer).find(
    ".audio-player-item.next .autoplay-content"
  );
  const audioDescrption = $(audioPlayerContainer).find(
    ".audio-player-item.description"
  );

  const track = new Tracking(
    { settings, apiKey, baseUrl },
    sendReportChannelStatistic,
    getRqStartAOD
  );
  track.init(getDefaultReportAOD(params));

  // Validate render options
  if (!params.categoryId && !params.audioId) {
    return;
  } else if (!params.categoryId) {
    await renderAudioNoCategory(
      { audioPlayerContainer, audioPlayer, audioDescrption, track },
      { generalSettings: settings, apiKey },
      baseUrl,
      params.audioId
    );
    return;
  }

  if (!settings.showContinueWatching) {
    $(audioPlayerContainer).find(".audio-player-item.next").remove();
    $(audioPlayerContainer).find(".audio-player-item").addClass("no-grid");
    audioList.addClass("no-grid-list");
  }

  audioList.empty();
  audioPlayer.empty().attr("id", "playerClappr");
  videoNextContent.empty();
  audioDescrption.empty();

  const category = await getAODCategoryById(
    baseUrl,
    apiKey,
    settings.tenantId,
    params.categoryId
  );

  const audios = category.medias?.filter((item) => item.type !== "playlist");
  audioPlayerContainer.setAttribute("player-type", settings.videoStyle);

  let audioIndex = audios.findIndex((item) => item.id === params.audioId);
  audioIndex = audioIndex === -1 ? 0 : audioIndex;
  let autoNext = settings.showContinueWatching;

  const player = new Clappr.Player({
    plugins: [
      HlsjsPlayback,
      ClapprStats,
      FastForward,
      FastBackward,
      SkipNext,
      SkipPrevious,
    ],
    poster: audios[0]?.banner || NotFoundBannerResource,
    width: "100%",
    height: "100%",
    autoPlay: true,
    mimeType: "video/mp4",
    parentId: "#playerClappr",
    maxBufferLength: 6,
    hlsPlayback: {
      preload: true,
    },
    hideMediaControl: false,
    audios,
    audioIndex,
    selectAudio: (index) => {
      if (audios[index]) {
        mapAudio(index);
        mapAudioDescription(index);
        mediaControl(audios[index]);
        if (settings.showContinueWatching) {
          mapAudioNext(index + 1);
        }
      }
    },
    clapprStats: {
      runEach: "extra.duration",
      onFirstReport: async () => {
        track.resetStatus(getStatus());
        track.send("Start", track.getRqStart());
      },
      onReport: (metrics) => {
        const reportStatus = track.getCurrent().reportStatus;
        if (!reportStatus?.send?.end) {
          track.send("End", track.getRqEnd(metrics));
        }
      },
      runBandwidthTestEvery: 10,
    },
    playback: {
      disableContextMenu: true,
      extrapolatedWindowNumSegments: 10,
      triggerFatalErrorOnResourceDenied: false,
      playInline: Clappr.Browser.isMobile,
    },
  });

  const mapAudio = async (index) => {
    audioPlayer.empty();
   // if (!audios[index]?.trailer?.url) {
      player.configure({
        source: Clappr.Utils.Media.mp4,
        poster: audios[index]?.banner || NotFoundBannerResource,
        autoPlay: false,
        chromeless: true,
        audioIndex: index,
      });
      player.getPlugin("poster").$playWrapper.hide();
    // } else {
    //   player.configure({
    //     source: audios[index]?.trailer?.url,
    //     poster: audios[index]?.banner || NotFoundBannerResource,
    //     autoPlay: true,
    //     chromeless: true,
    //     audioIndex: index,
    //   });
    // }

    // Validate Content Access
    let app_content_plans = await appContentAccess(baseUrl, apiKey);
    if (app_content_plans.hasOwnProperty('plans') && app_content_plans['plans'].length > 0) {
      setupPPV(
        baseUrl,
        apiKey,
        app_content_plans['plans'],
        audioPlayer,
        ContentType.TENANT,
        {},
      );
      return;
    }

    const isOrderProcessing =
      localStorage.getItem("isOrderProcessing") ||
      searchUrlParam("isOrderProcessing") === "true";
    if (isOrderProcessing && audios[index].packages && audios[index].packages.length > 0) {
      let content_type;
      if (
        audios[index].packages.filter(
          (p) => p.content_type == ContentType.AOD && category.related_ids.includes(p.content_uuid)
        )[0]
      ) {
        content_type = ContentType.AOD;
      } else if (
        audios[index].packages.filter(
          (p) => p.content_type == ContentType.AUDIO
        )[0]
      ) {
        content_type = ContentType.AUDIO;
      }
      setupPPV(
        baseUrl,
        apiKey,
        audios[index],
        audioPlayer,
        content_type,
        category,
      );
    }

    const playButton = $(createButtonPlay(audios[index]));
    playButton.on("click", function () {
      if (audios[index].packages && audios[index].packages.length > 0) {
        let content_type;
        if (
          audios[index].packages.filter(
            (p) => p.content_type == ContentType.AOD && category.related_ids.includes(p.content_uuid)
          )[0]
        ) {
          content_type = ContentType.AOD;
          console.log(content_type);
        } else if (
          audios[index].packages.filter(
            (p) => p.content_type == ContentType.AUDIO
          )[0]
        ) {
          content_type = ContentType.AUDIO;
        }
        console.log(content_type);
        setupPPV(
          baseUrl,
          apiKey,
          audios[index],
          audioPlayer,
          content_type,
          category,
        );
      } else {
        playButton.hide();
        player.configure({ chromeless: false, audioIndex: index });
        player.load(audios[index].url, "video/mp4", true);
        if (autoNext) {
          player.on(Clappr.Events.PLAYER_ENDED, () => {
            audioIndex = audioIndex + 1;
            selectAudio(audioIndex);
          });
        }
      }
    });
    playButton.appendTo(audioPlayer);
  };

  const mapAudioList = () => {
    audios?.forEach((item, index) => {
      const videoItem = $(createAudioItem(item));
      videoItem.css("cursor", "pointer");
      $(audioList).append(videoItem);
      $(videoItem).on("click", async () => {
        if (audioIndex !== index) {
          await selectAudio(index);
          audioIndex = index;
          const video = audios[audioIndex];
          if (video && video?.id) {
            track.init(
              getDefaultReportAOD({
                categoryId: video.vod,
                audioId: video.id,
              })
            );
          }
        }
      });
    });
  };

  const mapAudioNext = (index) => {
    videoNextContent.empty();
    const video = audios[index];
    const inputSlide = $(audioPlayerContainer).find("#checkbox");
    inputSlide.attr("checked", autoNext);
    inputSlide.on("click", function () {
      autoNext = !autoNext;
      if (!autoNext) {
        $("video").off("ended");
      } else {
        $("video").on("ended", function () {
          selectAudio(index);
        });
      }
    });
    if (!video) {
      index = 0; // if the end of list will be going about the top item
    }
    inputSlide.attr("disabled", false);
    const content = $(createAudioNextContent(audios[index]));
    videoNextContent.css("cursor", "pointer");
    content.on("click", function () {
      selectAudio(index);
    });
    $(videoNextContent).append(content);
  };

  const mapAudioDescription = (index) => {
    audioDescrption.empty();
    const content = $(createAudioDescription(audios[index]));
    $(audioDescrption).append(content);
  };

  const selectAudio = async (index) => {
    if (audios[index]) {
      const audioId = audios[index]?.id;
      const audio = await getAudioById(baseUrl, apiKey, audioId);
      if (!audio) {
        return;
      }
      audios[index] = audio;
      mapAudio(index);
      mapAudioDescription(index);
      mediaControl(audios[index]);
      if (settings.showContinueWatching) {
        mapAudioNext(index + 1);
      }
    }
  };

  const initAudio = () => {
    mapAudioList();
    selectAudio(audioIndex);
  };
  initAudio();
};
