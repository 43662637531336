import { createCarouselSlider } from "./Carousel-Slider";
import { createCarouselSliderDC } from "./Carousel-Slider-DC";

export const getSliders = async ({ generalSettings: settings, apiKey }, baseUrl, template_uuid) => {
  const sliders = $('[role=sliderwrapper][videocategory]').toArray();
  if (sliders && sliders.length > 0) {
    sliders.forEach(async (item) => {
      await createSlider(item, { settings, apiKey, baseUrl, template_uuid})
    })
  }
}

export const CategorySliderType = {
  Video: 'video',
  Audio: 'audio',
  PPV: 'ppv'
};

export const SliderType = {
  AUDIO_CAROUSEL_SLIDER_DC: 'audio-carousel-slider-dc',
  CAROUSEL_SLIDER_DC: 'carousel-slider-dc',
  CAROUSEL_SLIDER: 'carousel-slider',
}

export const createSlider = async (slider, { settings, apiKey, baseUrl, template_uuid }, mediaList = { results: [] }) => {
  const attributes = ['slidestoshowdesktop', 'slidestoshowtablet', 'slidestoshowmobile', 'arrows', 'infinite', 'autoplay', 'dots', 'videocategory', 'slidertype', 'categoryslidertype', 'haslist']
    .reduce((pre, cur) => ({ ...pre, [cur]: $(slider).attr(cur) }), {});
  const { slidestoshowdesktop, slidestoshowtablet, slidestoshowmobile, arrows, infinite, autoplay, dots, videocategory, slidertype, categoryslidertype, haslist } = attributes;
  switch (slidertype) {
    case SliderType.CAROUSEL_SLIDER:
      await createCarouselSlider(slider, videocategory, { settings, apiKey, baseUrl, template_uuid });
      break;
      case SliderType.CAROUSEL_SLIDER_DC:
      case SliderType.AUDIO_CAROUSEL_SLIDER_DC:
      await createCarouselSliderDC(slider,{categoryslidertype, videocategory, haslist}, { settings, apiKey, baseUrl, template_uuid }, mediaList);
      break;
    default:
      break;
  }
  const slideContainer = $(slider).find('[role=slidelist]');
  const optsSlick = {
    slidesToShow: Number(slidestoshowdesktop) || 5,
    arrows: arrows === '' || false,
    infinite: infinite === '' || true,
    autoplay: autoplay === '' || false,
    dots: dots === '' || false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: Number(slidestoshowtablet) || 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: Number(slidestoshowmobile) || 1,
        },
      },
    ],
  }

  $(slideContainer).removeClass('slick-initialized slick-slider');
  $(slideContainer).on('init', function (event, slick) {
    const current_slider = $(this);
    current_slider.find('.slick-current').addClass('slick-first');
    current_slider.find('.slick-active:first').addClass('slick-first');
    current_slider.find('.slick-active:last').addClass('slick-last');
  });
  $(slideContainer).not('.slick-initialized').slick(optsSlick)
  $(slideContainer)
    .on('afterChange', function (slick, currentSlide) {
      const current_slider = $(this);
      current_slider.find('.slick-active').removeClass('slick-first');
      current_slider.find('.slick-slide').removeClass('slick-last');
      current_slider.find('.slick-active:first').addClass('slick-first');
      current_slider.find('.slick-active:last').addClass('slick-last');
    });
  return slider;
}
