import { convertTime } from "../../utils/formatter";
import { OnErrorImg } from "../../constants/Media";

export const createVideoItem = (video) => {
  return `
    <li class="video-item">
        <div class="thumbnail-video-container">
            <img src="${
              video.thumbnail
            }" alt="thumbnail next video" class="thumbnail-video" onerror="${OnErrorImg}">
        </div>
        <div class="video-content">
            <h3 class="video-title">${video.title}</h3>
            <p class="video-time">${convertTime(Number(video.duration))}</p>
        </div>
    </li>
    `;
};
