import {
  hideLoading,
  loadingPosition,
  loadingSize,
  showLoading,
} from "../Loading";
import { formatUrl } from "../../utils/domHelper";
import {
  getCategoriesList,
  CATEGORY_TYPE,
} from "../../services/categoryService";
import { createSlider } from "../Slider";
import { renderPPVEventSlider } from "./PPVEventSlider";

export const getVODCategoriesSlider = async (
  container,
  { generalSettings: settings, apiKey },
  baseUrl,
  template_uuid
) => {
  let categoriesSliders;
  if (container) {
    categoriesSliders = $(container).find(`.vod-categories-slider`).toArray();
  } else {
    categoriesSliders = $(`.vod-categories-slider`)
      .toArray()
      .filter((item) => {
        return $(item).parent(".video-tab-content").length === 0;
      });
  }
  categoriesSliders.forEach(async (slider) => {
    const categoryItemClone = $(slider)
      .find(".vod-categories-slider-item")
      .first()
      .clone();
    const categoryContentClone = $(slider)
      .find(".vod-categories-slider-content")
      .first()
      .clone();
    const sliderContainerClone = $(categoryContentClone)
      .find(".vod-categories-slider-content-slider-container")
      .first()
      .clone();
    const videoCategorySliderClone = $(sliderContainerClone)
      .find(`[role=sliderwrapper]`)
      .first()
      .clone();

    let currentPage = 1;
    let isRendering = false;
    $(slider).empty();
    $(categoryContentClone).empty();
    $(sliderContainerClone).empty();
    await renderPPVEventSlider(
      categoriesSliders,
      {
        slider: $(slider).clone(),
        categoryItem: $(categoryItemClone).clone(),
        categoryContent: $(categoryContentClone).clone(),
        sliderContainer: $(sliderContainerClone).clone(),
        videoCategorySlider: $(videoCategorySliderClone).clone(),
      },
      { settings, apiKey, baseUrl, template_uuid },
    );
    const renderDynamicVODCategory = async function (pageIndex) {
      isRendering = true;
      // Fetch on Demand categories
      const categories = await getCategoriesList(
        baseUrl,
        apiKey,
        settings.tenantId,
        {
          type: CATEGORY_TYPE.ON_DEMAND,
          page: pageIndex,
          page_size: 3,
          has_video: true,
        }
      );

      categories?.results?.forEach(async (category) => {
        const categoryItemNew = categoryItemClone.clone();
        $(categoryItemNew).html(category.title);
        const categoryContentNew = categoryContentClone.clone();
        const sliderContainerNew = sliderContainerClone.clone();
        const videoCategorySlider = videoCategorySliderClone
          .clone()
          .attr("videocategory", category.id)
          .attr("haslist", true);
        const sliderItem = await createSlider(
          videoCategorySlider,
          {
            settings,
            apiKey,
            baseUrl,
            template_uuid,
          },
          { results: category?.videos || [] }
        );
        $(sliderContainerNew).append(sliderItem);
        $(categoryContentNew).append(sliderContainerNew);
        $(slider).append(categoryItemNew);
        $(slider).append(categoryContentNew);
      });
      if (categories.total / 3 > currentPage) {
        isRendering = false;
      }
    };

    showLoading($(slider), loadingSize.MEDIUM, loadingPosition.CENTER);
    await renderDynamicVODCategory(currentPage);
    hideLoading($(slider), loadingSize.MEDIUM, loadingPosition.CENTER);

    $(window).scroll(async function () {
      if (
        (!container ||
          (container && $(container).css("display") === "block")) &&
        !isRendering &&
        $(document).scrollTop() + $(window).height() >=
          $(slider).offset().top + $(slider).height()
      ) {
        currentPage = currentPage + 1;
        showLoading($(slider), loadingSize.MEDIUM, loadingPosition.BOTTOM);
        await renderDynamicVODCategory(currentPage);
        hideLoading($(slider), loadingSize.MEDIUM, loadingPosition.BOTTOM);
      }
    });
  });
};
