import { formatUrl, hideLoading, showLoading } from "../../../utils/domHelper";
import {getVideosByCategory} from "../../../services/videoService";
import { NotFoundResource, OnErrorImg } from "../../../constants/Media";

export const getBannerSliderPlayTV = async (
  { generalSettings: settings, apiKey },
  baseUrl
) => {
  const banner = $("#ptv-banner");
  const categoryId = banner.attr("categoryid");
  if (banner && categoryId && categoryId.length > 0) {
    showLoading();
    const bannerClone = banner.clone();
    banner.empty();
    // Fetch video list
    const videos = await getVideosByCategory(
      baseUrl,
      apiKey,
      settings.tenantId,
      categoryId
    );
    videos?.medias
      ?.filter((item) => item.type !== "playlist")
      .forEach((video) => {
        let poster = video?.poster;
        if (video?.poster === null || video?.poster.length === 0) {
          poster =
            "https://dummyimage.com/500x775/ffffff/e6d1e6&text=No+Poster";
        }
        bannerClone
          .find(".widget-image")
          .find("img")
          .attr("src", poster || NotFoundResource)
          .attr("onError", OnErrorImg);
        bannerClone.find(".widget-image").find("img").attr("srcset", "");
        bannerClone.find(".ptv-banner-title").html(video?.title);
        bannerClone.find(".ptv-banner-description").html(video?.description);
        bannerClone.find("[role=button]").on("click", function () {
          const playUrl = `/watch-movies?categoryId=${categoryId}&videoId=${video.id}`;
          window.location = formatUrl(playUrl);
        });
        banner.append(bannerClone.children());
        return false;
      });
    hideLoading();
  }
};
