import { buildHeader } from "./baseService";
import { getJson } from "../utils/httpHelper";

export const getChannelById = async (baseUrl, apiKey, channelId) => {
  const api = await fetch(`${baseUrl}/clients/channel/${channelId}/`, {
    headers: buildHeader(apiKey),
  });

  return getJson(api, {ignoreErrorTracing: true});
};

export const getChannels = async (baseUrl, apiKey) => {
  const api = await fetch(`${baseUrl}/clients/channel/`, {
    headers: buildHeader(apiKey),
  });

  return getJson(api);
};
