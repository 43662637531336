/* eslint-disable no-undef */
import { AppConfig } from "../../constants/config";
import { StreamType } from "../../constants/AppConstants";
import { isMobile } from "../../utils/appHelper";

export default class AntPlayer {
  constructor(
    wrapperSelector,
    {
      playOrder,
      targetLatency,
      autoplay,
      mute,
      auth,
      onPlayerStarted,
      onPlayerEnded,
    }
  ) {
    this.wrapperSelector = wrapperSelector;
    this.playOrder = playOrder;
    this.targetLatency = targetLatency;
    this.autoplay = autoplay;
    this.mute = mute;
    this.token = auth?.token;
    this.subscriberId = auth?.subscriberId;
    this.subscriberCode = auth?.subscriberCode;
    this.playerStarted = onPlayerStarted;
    this.playerEnded = onPlayerEnded;

    this._initializePlayer = this._initializePlayer.bind(this);
    this._changeVideoMuteStatus = this._changeVideoMuteStatus.bind(this);
    this._playWebRTCVideo = this._playWebRTCVideo.bind(this);
    this._start = this._start.bind(this);
    this._init();
  }

  playInternalStream(channelId, streamType) {
    if (
      channelId &&
      this.channelId === channelId &&
      this.streamType === streamType
    ) {
      return;
    }
    // this._init();
    this.channelId = channelId;
    this.streamType = streamType;
    this.isExternalStream = false;
    this.streamId = this._getStreamId(channelId, streamType);
    this._start();
  }

  playExternalStream(src) {
    // this._init();
    this.channelId = undefined;
    this.externalStreamSrc = src;
    this.isExternalStream = true;
    this._start();
  }

  destroy() {
    clearInterval(this.tryInterval);
    this._hideWebRTCElements();
    this._setHLSElementsVisibility(false);
    this._setPlaceHolderVisibility(true);
  }

  _init() {
    const tmpl = `<div class='stream-play-wrapper'><div id='video-overlay'> <img src="assets/images/loading.gif" alt="loading image" /> </div>
    <div id="video_info"> Stream will start playing automatically<br />when it is live </div>
    <!-- HLS Player --> <div id="video_container"> <video id="video-player" class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto"> <p class="vjs-no-js"> To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a> </p> </video> </div>
    <!-- WebRTC Player -->
    <video id="remoteVideo" controls playsinline></video>
    <!-- 360 player is added dynamically --> 
    <div id="networkWarning">Your connection isn't fast enough to play this stream!</div> <img id="play_button" src="assets/images/play.png" onclick="playWebRTCVideo()" style="position: absolute; top: 30px; left: 30px; display: none;" /> 
    <!-- Mute/Unmute Video Button --> 
    <button id="unmuteButton" title="Mute/Unmute Video"> Unmute </button></div>`;
    // Replace placeholder with video template
    $(this.wrapperSelector).html(tmpl);

    if (this.playOrder == null) {
      this.playOrder = ["webrtc", "hls"];
    } else if (typeof this.playOrder === "string") {
      this.playOrder = this.playOrder.split(",");
    }

    const head = document.getElementsByTagName("head")[0];
    if (this.playOrder.includes("hls") || this.playOrder.includes("vod")) {
      //include videojs -> js

      let js = document.createElement("script");
      js.type = "text/javascript";
      js.src = "assets/js/external/video.js";
      js.onload = function () {
        let trackingJS = document.createElement("script");
        trackingJS.type = "text/javascript";
        trackingJS.src = "assets/js/external/videojs-event-tracking.js";
        head.appendChild(trackingJS);
      };
      head.appendChild(js);

      //include videojs -> css
      let link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = "assets/css/external/video-js.css";
      head.appendChild(link);
    }

    if (this.playType == null || this.playType == "mp4,webm") {
      this.playType = ["mp4", "webm"];
    } else if (this.playType == "webm,mp4") {
      this.playType = ["webm", "mp4"];
    } else if (this.playType == "mp4") {
      this.playType = ["mp4"];
    } else if (this.playType == "webm") {
      this.playType = ["webm"];
    } else if (this.playType == "mov") {
      this.playType = ["mov"];
    }

    this.autoPlay = true;
    if (this.autoplay == "false" || isMobile()) {
      this.autoPlay = false;
    }

    if (this.mute == "true" || this.mute == null) {
      this.mute = true;
    } else {
      this.mute = false;
    }

    if (!this.targetLatency) {
      this.targetLatency = 3;
    }
    this.hlsExtension = "m3u8";
    this.unMuteButton = document.getElementById("unmuteButton");
    this.placeHolder = document.getElementById("video_info");
    this.remoteVideo = document.getElementById("remoteVideo");
    this.iceConnected = false;
    this.streamsFolder = "streams";
    this.streamUrl = AppConfig.streamUrl;

    $("#unmuteButton").click(() => {
      this._changeVideoMuteStatus();
    });
  }

  _changeVideoMuteStatus() {
    // Checks the current "mute" status of the video
    if (this.remoteVideo.muted) {
      // Unmute the video and change the button's text
      this.remoteVideo.muted = false;
      document.getElementById("unmuteButton").innerText = "Mute";
    } else {
      this.remoteVideo.muted = true;
      document.getElementById("unmuteButton").innerText = "Unmute";
    }
  }

  _genericCallback(currentTech) {
    this.placeHolder.innerHTML =
      "Stream will start playing automatically<br/>when it is live";
    setTimeout(() => {
      let index = this.playOrder.indexOf(currentTech);

      if (currentTech == "webrtc") {
        if (!this.iceConnected) {
          if (index == -1 || index == this.playOrder.length - 1) {
            index = 0;
          } else {
            index++;
          }
        }
      } else {
        if (index == -1 || index == this.playOrder.length - 1) {
          index = 0;
        } else {
          index++;
        }
      }

      const tech = this.playOrder[index];

      if (tech == "hls") {
        this._tryToPlay(this.hlsExtension);
      }
    }, 3000);
  }

  _setHLSElementsVisibility(show) {
    document.getElementById("video_container").style.display =
      show == true ? "block" : "none";
  }

  _hideWebRTCElements() {
    this._setWebRTCElementsVisibility(false);
    document.getElementById("play_button").style.display = "none";
  }

  _setWebRTCElementsVisibility(show) {
    document.getElementById("remoteVideo").style.display =
      show == true ? "block" : "none";
  }

  _setPlaceHolderVisibility(show) {
    this.placeHolder.style.display = show == true ? "block" : "none";
  }

  _playWebRTCVideo() {
    this._setWebRTCElementsVisibility(true);

    if (this.mute) {
      this.remoteVideo.nativeElement.muted = true;
      this.unMuteButton.nativeElement.innerText = "Unmute";
    } else {
      this.remoteVideo.nativeElement.muted = false;
      this.unMuteButton.nativeElement.innerText = "Mute";
    }
    if (this.autoPlay) {
      this.remoteVideo.nativeElement
        .play()
        .then(() => {
          // autoplay started
          document.getElementById("play_button").style.display = "none";
        })
        .catch(() => {
          document.getElementById("play_button").style.display = "block";
          // console.log('User interaction needed to start playing');
        });
    }
  }

  _initializePlayer(extension, isExternalStream) {
    this._hideWebRTCElements();
    if (!window.videojs) {
      const self = this;
      const loadVideoJS = setInterval(() => {
        if (window.videojs) {
          self._startPlayer(extension, isExternalStream);
          clearInterval(loadVideoJS);
        }
      }, 1000);
    } else {
      this._startPlayer(extension, isExternalStream);
    }
  }

  _startPlayer(extension, isExternalStream) {
    let type;
    let liveStream = false;
    if (extension === "mp4") {
      type = "video/mp4";
      liveStream = false;
    } else if (extension === "webm") {
      type = "video/webm";
      liveStream = false;
    } else if (extension === "mov") {
      type = "video/mp4";
      alert("Browsers do not support to play mov format");
      liveStream = false;
    } else if (extension === "avi") {
      type = "video/mp4";
      alert("Browsers do not support to play avi format");
      liveStream = false;
    } else if (extension === "m3u8") {
      type = "application/x-mpegURL";
      liveStream = true;
    } else if (extension === "mpd") {
      type = "application/dash+xml";
      liveStream = true;
    } else {
      // console.log('Unknown extension: ' + extension);
      return;
    }

    let player = this.remoteVideo.nativeElement;

    player = window.videojs("video-player", {
      // poster: 'previews/' + preview + '.png',
      liveui: liveStream,
      liveTracker: {
        trackingThreshold: 0,
      },
    });

    player.eventTracking({
      performance: ({ initialLoadTime }) => {
        const playedTime = initialLoadTime * 1000;
        this.playerEnded({ timers: { watch: playedTime } });
      },
    });

    if (!isExternalStream) {
      window.videojs.Hls.xhr.beforeRequest = (options) => {
        options.uri =
          options.uri +
          "?token=" +
          this.token +
          "&subscriberId=" +
          this.subscriberId +
          "&subscriberCode=" +
          this.subscriberCode;
        return options;
      };

      player.src({
        src: this.streamUrl + this.streamId + "." + extension,
        type,
        // withCredentials: true,
      });
    } else {
      player.src({
        src: this.externalStreamSrc,
      });
    }

    if (this.mute) {
      player.muted(true);
    } else {
      player.muted(false);
    }

    if (this.autoPlay) {
      player.ready(() => {
        player.play();
      });
    }

    this._setHLSElementsVisibility(true);
    this._setWebRTCElementsVisibility(false);
    if (typeof player.ready !== "undefined") {
      const self = this;
      player.ready(() => {
        self.playerStarted();
      });
    }
    this._setPlaceHolderVisibility(false);
  }

  _initializeWebRTCPlayer() {}

  _start() {
    if (typeof this.streamId !== "undefined" || this.isExternalStream) {
      if (this.tryInterval) {
        clearInterval(this.tryInterval);
      }
      if (this.playOrder[0] === "hls") {
        this._tryToPlay(this.hlsExtension, this.isExternalStream);
        // this.tryInterval = setInterval(() => {
        //   this._tryToPlay(this.hlsExtension, isExternalStream);
        // }, 3000);
      } else {
        alert(
          "Unsupported play order requested. Supported formats are webrtc and hls. Use something like playOrder=webrtc,hls"
        );
      }
    } else {
      this._hideWebRTCElements();
      this._setHLSElementsVisibility(false);
      this._setPlaceHolderVisibility(true);
    }
  }

  _tryToPlay(type, isExternalStream = false) {
    fetch(
      !isExternalStream
        ? `${this.streamUrl}${this.streamId}.${type}?token=${this.token}&subscriberId=${this.subscriberId}&subscriberCode=${this.subscriberCode}`
        : this.externalStreamSrc,
      { method: "HEAD" }
    )
      .then((response) => {
        if (response.ok) {
          if (response.status === 200) {
            this._initializePlayer(type, isExternalStream);
          }
        } else {
          this._tryToPlay(type, isExternalStream);
          this._hideWebRTCElements();
          this._setHLSElementsVisibility(false);
          this._setPlaceHolderVisibility(true);
        }
      })
      .catch((err) => {
        console.log("Error: " + err);
        this._tryToPlay(type, isExternalStream);
        this._hideWebRTCElements();
        this._setHLSElementsVisibility(false);
        this._setPlaceHolderVisibility(true);
      });
  }

  _getStreamId = (channelId, streamType = StreamType.PLAYLIST) => {
    const isProdStream = AppConfig.env === "production";
    const sType = streamType === StreamType.PLAYLIST ? "playlist" : "rtmp";
    const streamPrefix = isProdStream ? "prod" : "dev";
    return `${streamPrefix}-${sType}-${channelId.replace(/-/g, "")}`;
  };
}
