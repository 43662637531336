import { PlaybackControl } from "./PlaybackControl"

export class FastBackward extends PlaybackControl {

  get name() { return 'fast_backward' }
  get attributes() { return { class: 'fast-backward' } }

  handleClickEvent() {
    this.core.getCurrentPlayback().seek(this.core.getCurrentPlayback().getCurrentTime() - 10);
  }

  renderControl() {
    this.$el.append(Clappr.Styler.getStyleFor(`
      .fast-backward { 
        position: static;
         float: left;
         z-index: 999;
         height: 32px;
         width: 28px;
         cursor: pointer;
         opacity: 0.8;
         transform: scale(0.8);
         background: url("data:image/svg+xml,%3Csvg width='28' height='32' viewBox='0 0 28 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.1211 4.43981V0L5.45442 6.4H14.0756V6.40111C20.5157 6.48838 25.7093 11.7076 25.7093 18.1333C25.7093 24.6134 20.4273 29.8667 13.9116 29.8667C8.12844 29.8667 3.31712 25.7282 2.3084 20.2667H0.132812C1.16458 26.9122 6.94069 32 13.9116 32C21.6121 32 27.8544 25.7917 27.8544 18.1333C27.8544 11.2227 22.7715 5.49288 16.1211 4.43981Z' fill='white'/%3E%3Cpath d='M11.3913 22.4V14.1867H8.07079L7.68359 15.8528H9.32626V22.4H11.3913ZM17.1983 22.5291C15.098 22.5291 13.8543 20.8277 13.8543 18.2933C13.8543 15.7472 15.098 14.0576 17.1983 14.0576C19.2868 14.0576 20.5071 15.7472 20.5071 18.2933C20.5071 20.8277 19.2868 22.5291 17.1983 22.5291ZM17.1983 20.7573C17.9492 20.7573 18.442 19.9595 18.442 18.2933C18.442 16.6389 17.9492 15.8293 17.1983 15.8293C16.4473 15.8293 15.9428 16.6389 15.9428 18.2933C15.9428 19.9595 16.4473 20.7573 17.1983 20.7573Z' fill='white'/%3E%3C/svg%3E%0A") no-repeat center center;
       }
    `));
    this.core.mediaControl && this.core.mediaControl.$('.media-control-left-panel').prepend(this.el);
    return this;
  }
}