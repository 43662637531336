import { currencyFormatter, dateFormatter } from "../../utils/formatter";

export const planOneMapping = (plan) => {

  document.querySelector('.subscription-card-one-header').style.backgroundColor = plan.membershipColor;
  document.querySelector('.subscription-card-one-square').style.backgroundColor = plan.membershipColor;
  document.querySelector('.subscription-card-one-title').innerHTML = plan.title;

  if (plan.isFree) {
    document.querySelector('.subscription-card-one-num').innerHTML = plan.trialPeriodInput;
    document.querySelector('.subscription-card-one-free-trial').innerHTML = `${plan.trialPeriodSelect} Free Trial`;
  }

  document.querySelector('.subscription-card-one-plan-description').innerHTML = plan.description;

  document.querySelector('.subscription-card-one-text-discount').innerHTML = `${currencyFormatter.format(plan.regularPriceInput)} / ${plan.regularPriceSelect}`;

  if (plan.isDiscount) {
    document.querySelector('.subscription-card-one-text-discount').classList.add('subscription-card-one-per-month');
    document.querySelector('.subscription-card-one-price').innerHTML = `${currencyFormatter.format(plan.discountedPriceValue)}`;
    document.querySelector('.subscription-card-one-discount-info').innerHTML = `Valid until <span class="text-weight-bold" style="color: black">${dateFormatter(plan.discountFor)}</span>`;
  }

  document.querySelector('.btn-subscription-one').style.backgroundColor = plan.btnAddColor;
}

export const createCardOne = (plan) => {
  return `
    <div class="subscription-card-one">
      <div class="subscription-card-one-header" style="background-color: ${plan.membershipColor}">
        <div class="subscription-card-one-title">${plan.title}</div>
        <div class="subscription-card-one-square" style="background-color: ${plan.membershipColor}"></div>
      </div>
      <div class="subscription-card-one-content">
        <div class="text-weight-bold subscription-card-one-num" style="display: ${plan.isFree ? 'block' : 'none'}">
          ${plan.trialPeriodInput}
        </div>
        <div class="text-weight-bold subscription-card-one-free-trial style="display: ${plan.isFree ? 'block' : 'none'}">
          ${plan.trialPeriodSelect} Free Trial
        </div>
        <div class="subscription-card-one-text-des subscription-card-one-plan-description">
          ${plan.description}
        </div>
        <div class="subscription-card-one-text-discount text-weight-bold ${plan.isDiscount ? 'per-month' : ''}">
          ${currencyFormatter.format(plan.regularPriceInput)} / ${plan.regularPriceSelect}
        </div>
        <div class="subscription-card-one-price text-weight-bold" style="display: ${plan.isDiscount ? 'block' : 'none'}">
          ${currencyFormatter.format(plan.discountedPriceValue)}
        </div>
        <div class="subscription-card-one-text-des subscription-card-one-discount-info" style="display: ${plan.isDiscount ? 'block' : 'none'}">
          Valid until <span class="text-weight-bold" style="color: black">${dateFormatter(plan.discountFor)}</span>
        </div>
      </div>
      <div class="subscription-card-one-actions subscription-card-btn-atc" style="background-color: ${plan.btnAddColor}">
        <a class="btn-subscription-one">ADD TO CART</a>
      </div>
    </div>
  `
}