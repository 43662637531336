import {
  hideLoading,
  loadingPosition,
  loadingSize,
  showLoading,
} from "../Loading";
import {
  getDefaultReportVideoChannel,
  sendReportChannelStatistic,
  getRqStartVideoChannel,
  getStatus,
} from "../../utils/analyticsHelper";
import { getChannelById } from "../../services/channelService";
import { setupUpComingStream } from "../../utils/streamHelper";
import AntPlayer from "../AntPlayer";
import Tracking from "../AnalyticsVideo/Tracking";

export const watchLive = async (
  { generalSettings: settings, apiKey },
  baseUrl,
  template_uuid
) => {
  const videoStream = $(".vdo-stream-container");
  if (videoStream.length > 0) {
    const channelId = videoStream.attr("channelid");
    showLoading(videoStream, loadingSize.MEDIUM, loadingPosition.CENTER);
    const channel = await getChannelById(baseUrl, apiKey, channelId);
    hideLoading(videoStream, loadingSize.MEDIUM, loadingPosition.CENTER);
    if (!channel || channel?.stream_url.length === 0) {
      return;
    }
    const track = new Tracking(
      { settings, apiKey, baseUrl },
      sendReportChannelStatistic,
      getRqStartVideoChannel
    );
    const player = new AntPlayer("#player-wrapper", {
      playOrder: "hls",
      onPlayerStarted: () => {
        track.init(getDefaultReportVideoChannel(channel?.id));
        const reportStatus = track.getCurrent().reportStatus;
        if (reportStatus?.send?.start === false) {
          track.resetStatus(getStatus(true));
          track.send("Start", track.getRqStart());
        }
      },
      onPlayerEnded: (metrics) => {
        const reportStatus = track.getCurrent().reportStatus;
        if (!reportStatus?.send?.end) {
          track.send("End", track.getRqEnd(metrics));
        }
      },
    });

    if (window.activeChannelSocket) {
      window.activeChannelSocket.close();
      clearInterval(window.retrivePlaying);
    }

    player.playExternalStream(channel.stream_url);
    // if (channel.stream_mode === "EXTERNAL") {
    //   player.playExternalStream(channel.stream_url);
    // } else {
    //   setupUpComingStream(player, channel, settings, apiKey);
    // }

    window.onbeforeunload = function () {
      if (player) {
        player.destroy();
      }
      return null;
    };

  }

};
