import { buildHeader, buildQueryParam } from "./baseService";
import { getJson } from "../utils/httpHelper";
import {CATEGORY_TYPE_OF_LIST} from "../constants/Category";

export const CATEGORY_TYPE = {
    ON_DEMAND: 'on_demand',
    SERIES: 'series'
}

export const getCategoriesList = async (baseUrl, apiKey, tenantId, params) => {
    const api = await fetch(buildQueryParam(`${baseUrl}/clients/vod-category/`, params), {
        headers: buildHeader(apiKey),
    });
    return getJson(api);
}

export const getVODCategoryById = async (baseUrl, apiKey, tenantId, uuid) => {
    const api = await fetch(`${baseUrl}/clients/vod-category/${uuid}/`, {
        headers: buildHeader(apiKey),
    });
    return getJson(api, {ignoreErrorTracing: true});
}

export const getSingleVODCategoryById = async (baseUrl, apiKey, tenantId, uuid) => {
    const api = await fetch(`${baseUrl}/category/single/${CATEGORY_TYPE_OF_LIST.VOD}/${uuid}/`, {
        headers: buildHeader(apiKey),
    });
    return getJson(api, {ignoreErrorTracing: true});
}

export const getAODCategoryById = async (baseUrl, apiKey, tenantId, uuid) => {
    const api = await fetch(`${baseUrl}/category/single/${CATEGORY_TYPE_OF_LIST.AOD}/${uuid}/`, {
        headers: buildHeader(apiKey),
    });
    return getJson(api, {ignoreErrorTracing: true});
}

export const getCategoriesAOD = async (baseUrl, apiKey, tenantId, params) => {
    const api = await fetch(buildQueryParam(`${baseUrl}/clients/aod-category/`, params), {
        headers: buildHeader(apiKey),
    });
    return getJson(api);
}

export const getSingleCategoryById = async (baseUrl, apiKey, type, uuid) => {
    const api = await fetch(`${baseUrl}/category/single/${type}/${uuid}/`, {
        headers: buildHeader(apiKey),
    });
    return getJson(api, {ignoreErrorTracing: true});
}