import { currencyFormatter, dateFormatter } from "../../utils/formatter";

export const createCardFive = (plan) => {
  return `
    <div class="subscription-card-five" style="background-color: ${plan.membershipColor}">
      <div class="subscription-card-five-header">
        <div class="subscription-card-five-title">${plan.title}</div>
        <div class="subscription-card-five-info">
          <div class="subscription-card-five-price text-weight-bold subscription" style="display: ${plan.isDiscount ? 'block' : 'none'}">
            ${currencyFormatter.format(plan.discountedPriceValue)}
          </div>
          <div class="text-weight-bold subscription-card-five-text-discount ${plan.isDiscount ? 'per-month' : ''}">
            ${currencyFormatter.format(plan.regularPriceInput)} / ${plan.regularPriceSelect}
          </div>
          <div class="text-des subscription-card-five-discount-info" style="display: ${plan.isDiscount ? 'block' : 'none'}">
            Valid until <span class="text-weight-bold">${dateFormatter(plan.discountFor)}</span>
          </div>
        </div>
      </div>
      <div class="subscription-card-five-content">
        <div class="text-weight-bold subscription-card-five-num" style="display: ${plan.isFree ? 'block' : 'none'}">
          ${plan.trialPeriodInput}
        </div>
        <div class="text-weight-bold subscription-card-five-free-trial" style="display: ${plan.isFree ? 'block' : 'none'}">
          ${plan.trialPeriodSelect} Free Trial
        </div>
        <div class="text-des subscription-card-five-text-des">
          ${plan.description}
        </div>
      </div>
      <div class="subscription-card-five-actions">
        <a 
          class="subscription-card-five-button subscription-card-btn-signup" 
          style="color: ${plan.btnAddColor}; border-color: ${plan.btnAddColor};"
          data-subscription-id=${plan.uuid}
        >
          SIGN UP
        </a>
      </div>
    </div>
  `
}