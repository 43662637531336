export const createAudioDescription = (audio) => {
  return `
    <div>
        <div class="d-wrapper">
            <div class="d-header">
                <h2>${audio.title}</h2>
                <div class="d-header-interaction">
                    <button class="d-attachment" data-toggle="popover" data-html="true" title="Download" data-content="Not found file"><i class="fas fa-paperclip"></i></button>
                    <a href="#" target="_blank" class="d-download">
                       <svg data-prefix="far" height="25px" data-icon="arrow-to-bottom" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-arrow-to-bottom fa-w-12"><path id="i5c3k" data-gjs-type="svg-in" draggable="true" fill="currentColor" d="M348.5 232.1l-148 148.4c-4.7 4.7-12.3 4.7-17 0l-148-148.4c-4.7-4.7-4.7-12.3 0-17l19.6-19.6c4.8-4.8 12.5-4.7 17.1.2l93.7 97.1V44c0-6.6 5.4-12 12-12h28c6.6 0 12 5.4 12 12v248.8l93.7-97.1c4.7-4.8 12.4-4.9 17.1-.2l19.6 19.6c4.9 4.7 4.9 12.3.2 17zM372 428H12c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h360c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12z" animationType="none"></path></svg>
                    </a>
                    <button class="d-like">
                        <i class="fa fa-heart"></i>
                    </button>
                    <button class="d-shared">
                        <i class="fas fa-share-alt"></i>
                    </button>
                </div>
            </div>
            <div class="d-content">
                <p>${audio.description}</p>
            </div>
            <div class="d-footer">
                <p>CATEGORIES</p>
                <ul class="tag-list">
                    ${audio.tags
                      .map((item) => `<li class="tag-item"> ${item}</li>`)
                      .join("")}
                </ul>
            </div>
        </div>
    </div>`;
};
export const createListFile = (files) => {
  const filesHtml = files.map(
    (item) => ` <li> 
  <a href="${item.url}" target="_blank" class="d-download">
   <i class="fas fa-arrow-down"></i>
    <span>${item.name}</span>
  </a>
 </li>`
  );
  const html = filesHtml.join("");
  return ` 
           <div class="popover-content d-downl">
               <ul>
                     ${html}
               </ul>
          </div>
`;
};
