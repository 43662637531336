export const planFormatter = (plans) => {
  if (plans && plans.length) {
    return plans.map((plan) => {
      const [trialPeriodInput, trialPeriodSelect] = (plan.trial_in && plan.trial_in.split(' ')) || [
        '1',
        'days',
      ];
      const [, regularPriceSelect] = plan.expires_in && plan.expires_in.split(' ');
      const membership_color_type = plan.membership_color && plan.membership_color.includes('rgb') ? 'rgba' : 'hex';
      const act_btn_color_type = plan.act_btn_color_type && plan.act_btn_color_type.includes('rgb') ? 'rgba' : 'hex';
      return {
        uuid: plan.uuid,
        description: plan.description,
        title: plan.title,
        pricing_tbl_style: plan.pricing_tbl_style,
        discount: plan.discount,
        expanded: false,
        trialPeriodSelect,
        regularPriceSelect,
        trialPeriodInput: +trialPeriodInput,
        price: plan.price,
        isFree: !!plan.trial_in,
        isDiscount: !!plan.discount,
        discount_valid_to: plan.discount_valid_to,
        is_active: plan.is_active,
        is_downgrade: plan.is_downgrade,
        is_renewal: plan.is_renewal,
        is_upgrade: plan.is_upgrade,
        membership_color_type,
        membership_color_hex: plan.membership_color,
        membership_color_rgba: plan.membership_color,
        act_btn_color_type,
        act_btn_color_hex: plan.act_btn_color,
        act_btn_color_rgba: plan.act_btn_color,

        membershipColor: plan.membership_color,
        btnAddColor: plan.act_btn_color,
        discountedPriceValue: plan.discount,
        regularPriceInput: plan.price,
        discountFor: plan.discount_valid_to
      };
    });
  }
  return [];
}
