export const ContentType = {
    AUDIO: 1,
    VIDEO: 2,
    SUBSCRIPTION: 3,
    VOD: 4,
    AOD: 5,
    CHANNEL: 6,
    PPV_EVENT: 7,
    TENANT: 8
}
