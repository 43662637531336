import { createListFile } from "./AudioDescription";

export const mediaControl = (audio) => {
  const ctrBtnDownload = audio?.downloadable && audio?.url?.length > 0;
  const ctrBtnAttachment = audio?.attachments && audio.attachments.length > 0;
  const btnDownload = $(`.d-download`);
  const btnAttachment = $(`.d-attachment`);
  if (!ctrBtnDownload) {
    btnDownload.addClass("d-disable");
  } else {
    if (audio.url && audio.url.length > 0) {
      btnDownload.attr("href", audio.url);
    }
    btnDownload.removeClass("d-disable");
  }
  if (!ctrBtnAttachment) {
    btnAttachment.addClass("d-disable");
  } else {
    btnAttachment.removeClass("d-disable");
    $('[data-toggle="popover"]').popover({
      placement: "top",
      trigger: "focus",
    });
    btnAttachment.attr("data-content", createListFile(audio.attachments));
    btnAttachment.next(".popover").addClass("popover-danger");
  }
};
