import { formatUrl, showAlert } from "./domHelper";

export const getJson = async (response, options = {}) => {
  if (response.status === 204) {
    return undefined;
  }
  const jsonResponse = await response.json();
  if (response.status >= 200 && response.status <= 299) {
    return jsonResponse;
  } else if (response.status === 401) {
    return redirectPageLogin();
  } else if (response.status === 403) {
    localStorage.removeItem("loginToken");
    window.location.reload();
  } else if (!options.ignoreErrorTracing){
    // Alert error
    if (typeof jsonResponse === "string") {
      showAlert(jsonResponse);
    } else {
      showAlert(jsonResponse.message || jsonResponse.detail);
    }
    //throw new Error(jsonResponse.message);
  }
};

export const redirectPageSignUp = (targetUrl) => {
  const urls = window.location.pathname.split("/");
  window.location = !targetUrl
    ? formatUrl(
        `/sign-up?redirect_to=/${urls[urls.length - 1].replace(".html", "")}${
          window.location.search
        }`
      )
    : formatUrl(`/sign-up?redirect_to=${targetUrl}`);
};

export const redirectPageLogin = (targetUrl) => {
  const urls = window.location.pathname.split("/");
  window.location = !targetUrl
    ? formatUrl(
        `/sign-in?redirect_to=/${urls[urls.length - 1].replace(".html", "")}${
          window.location.search
        }`
      )
    : formatUrl(`/sign-in?redirect_to=${targetUrl}`);
};

export const getRedirectLink = (target, searchParams) => {
  const urls = (target || window.location.pathname).split("/");
  return `/${urls[urls.length - 1].replace(".html", "")}${
    searchParams || window.location.search
  }`;
};

export const redirectTo = () => {
  let searchParams = new URLSearchParams(window.location.search);
  const redirectTo = searchParams.get("redirect_to");
  if (redirectTo) {
    searchParams.delete("redirect_to");
    let rd_to = formatUrl(
      `${redirectTo}${
        searchParams.toString() ? `&${searchParams.toString()}` : ""
      }`
    );
    window.location = rd_to;
  } else {
    window.location = formatUrl("/");
  }
  return redirectTo;
};

export const searchUrlParam = (key) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
};
