import { buildHeader, buildQueryParam } from "./baseService";
import { getJson } from "../utils/httpHelper";

export const getPPVEvents = async (baseUrl, apiKey, params) => {
  const api = await fetch(buildQueryParam(`${baseUrl}/billing/ticket/list/`, params), {
    headers: buildHeader(apiKey),
  });

  return getJson(api);
};

export const getPPVEventList = async (baseUrl, apiKey, params) => {
  const api = await fetch(buildQueryParam(`${baseUrl}/clients/event-ticket/list/`, params), {
    headers: buildHeader(apiKey),
  });

  return getJson(api);
};