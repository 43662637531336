import {
  hideLoading,
  loadingPosition,
  loadingSize,
  showLoading,
} from "../Loading";
import { formatUrl } from "../../utils/domHelper";
import {
  getCategoriesList,
  CATEGORY_TYPE,
} from "../../services/categoryService";
import { OnErrorImg, NotFoundResource } from "../../constants/Media";

export const getVODCategories = async (
  container,
  { generalSettings: settings, apiKey },
  baseUrl,
  template_uuid
) => {
  let categoriesGrids;
  if (container) {
    categoriesGrids = $(container).find(`.vod-categories-grid`).toArray();
  } else {
    categoriesGrids = $(`.vod-categories-grid`)
      .toArray()
      .filter((item) => {
        return $(item).parent(".video-tab-content").length === 0;
      });
  }
  categoriesGrids.forEach(async (grid) => {
    const categoryItemClone = $(grid)
      .find(".vod-categories-grid-item")
      .first()
      .clone();
    const categoryContentClone = $(grid)
      .find(".vod-categories-grid-content")
      .first()
      .clone();
    const videoGridClone = $(categoryContentClone)
      .find(".vod-videos-grid")
      .first()
      .clone();
    const videoGridItemClone = $(videoGridClone)
      .find(".vod-videos-grid-item")
      .first()
      .clone();
    const videoGridContentClone = $(videoGridClone)
      .find(".vod-videos-grid-content")
      .first()
      .clone();
    let currentPage = 1;
    let isRendering = false;
    $(grid).empty();
    $(categoryContentClone).empty();
    $(videoGridClone).empty();
    const renderDynamicVODCategory = async function (pageIndex) {
      isRendering = true;
      // Fetch on Demand categories
      const categories = await getCategoriesList(
        baseUrl,
        apiKey,
        settings.tenantId,
        {
          type: CATEGORY_TYPE.ON_DEMAND,
          page: pageIndex,
          page_size: 3,
          has_video: true,
        }
      );

      categories?.results?.forEach((category) => {
        const categoryItemNew = categoryItemClone.clone();
        $(categoryItemNew).html(category.title);
        const categoryContentNew = categoryContentClone.clone();
        const videoGridNew = videoGridClone.clone();
        category?.videos?.forEach((video) => {
          const videoGridItemNew = videoGridItemClone.clone();
          const videoGridContentNew = videoGridContentClone.clone();
          videoGridItemNew
            .find("img")
            .attr("src", video?.thumbnail || NotFoundResource)
            .attr("onError", OnErrorImg);
          videoGridContentNew
            .find(".vod-videos-grid-content-title")
            .html(video?.title);
          videoGridContentNew
            .find(".vod-videos-grid-content-description")
            .html(video?.description);
          videoGridContentNew
            .find("img")
            .attr("src", video?.thumbnail || NotFoundResource)
            .attr("onError", OnErrorImg);
          videoGridContentNew
            .find(".vod-videos-grid-content-bannersubnowbtn")
            .on("click", function () {
              const nextURL = `/watch-movies?categoryId=${category.id}&videoId=${video.id}`;
              window.location = formatUrl(nextURL);
            });
          $(videoGridItemNew).click(() => {
            $(`.vod-videos-grid-item`).removeClass("active");
            $(videoGridItemNew).addClass("active");
            $(`.vod-videos-grid-content`)
              .not($(videoGridItemNew).next())
              .hide();
            $(videoGridItemNew).next().slideDown(500);
          });
          videoGridNew.append(videoGridItemNew);
          videoGridNew.append(videoGridContentNew);
        });
        categoryContentNew.append(videoGridNew);
        $(grid).append(categoryItemNew);
        $(grid).append(categoryContentNew);
      });
      if (categories.total / 3 > currentPage) {
        isRendering = false;
      }
    };

    showLoading($(grid), loadingSize.MEDIUM, loadingPosition.CENTER);
    await renderDynamicVODCategory(currentPage);
    hideLoading($(grid), loadingSize.MEDIUM, loadingPosition.CENTER);

    $(window).scroll(async function () {
      if (
        (!container ||
          (container && $(container).css("display") === "block")) &&
        !isRendering &&
        $(document).scrollTop() + $(window).height() >=
          $(grid).offset().top + $(grid).height()
      ) {
        currentPage = currentPage + 1;
        showLoading($(grid), loadingSize.MEDIUM, loadingPosition.BOTTOM);
        await renderDynamicVODCategory(currentPage);
        hideLoading($(grid), loadingSize.MEDIUM, loadingPosition.BOTTOM);
      }
    });
  });
};
