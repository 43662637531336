import { formatUrl, hideLoading, showLoading } from "../../utils/domHelper";
import { getVideoListByCategory } from "../../services/videoService";

export const getFeaturedBanner = async (
  { generalSettings: settings, apiKey },
  baseUrl,
  template_uuid
) => {
  const bannerContainer = $(".featured-banner-container");
  const banner = $(bannerContainer).find(".featured-banner");
  const categoryId = banner.attr("categoryid");
  if (banner && categoryId && categoryId.length > 0) {
    showLoading();
    const bannerClone = banner.clone();
    banner.empty();
    const videos = await getVideoListByCategory(
      baseUrl,
      apiKey,
      settings.tenantId,
      categoryId
    );
    videos?.results
      ?.filter((item) => item.type !== "playlist")
      .forEach((video) => {
        let poster = video?.thumbnail;
        if (!poster) {
          poster =
            "https://dummyimage.com/500x775/ffffff/e6d1e6&text=No+Poster";
        }
        bannerContainer.css("background-image", "url(" + poster + ")");
        bannerClone.find(".featured-banner-video-title").html(video?.title);
        bannerClone
          .find(".featured-banner-video-description")
          .html(video?.description);
        bannerClone.find("[role=buttontrailer]").on("click", function () {
          const playUrl = `/watch-movies?categoryId=${categoryId}&videoId=${video.id}`;
          window.location = formatUrl(playUrl);
        });
        banner.append(bannerClone.children());
        return false;
      });
    hideLoading();
  }
};
