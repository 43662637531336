export const createDivContainer = (className) =>
  $(`<div class="${className}"></div>`);

export const CATEGORY_SERIES_GRID = {
  classGridDrop: "vod-category-series-grid",
  classGridDropItem: "vod-category-series-grid-item",
  classGridDropContent: "vod-category-series-grid-content",
};

export const LevelDrop = {
  Level0: "0",
  Level1: "1",
  Level2: "2",
};

export const VIDEO_SERIES_GRID = {
  classGridDrop: "vod-video-series-grid",
  classGridDropItem: "vod-video-series-grid-item",
  classGridDropContent: "vod-video-series-grid-content",
};

export const VIDEO_SEASON_GRID = {
  classGridDrop: "vod-video-season-grid",
  classGridDropItem: "vod-video-season-grid-item",
  classGridDropContent: "vod-video-season-grid-content",
  selectorGridDropContentSlider: "[role=sliderwrapper]",
};
