import { buildHeader, buildSecureApiHeader, buildQueryParam } from "./baseService";
import { getJson } from "../utils/httpHelper";

export const getSubscriptionPlans = async (baseUrl, apiKey, channelId, params) => {
  const subscriptionPlans = await fetch(
    buildQueryParam(`${baseUrl}/subscription-plan/${channelId}/list/`, params),  
    {  
      method: "GET",
      headers: buildHeader(apiKey),
    }
  );
  return getJson(subscriptionPlans);
};

export const processSubscriptionCheckout = async (baseUrl, data) => {
  const response = await fetch(
    `${baseUrl}/billing/subscription/subscribe/`,
    {
      method: "POST",
      headers: buildSecureApiHeader(),
      body: data,
    }
  );
  const responseJson = getJson(response);
  if (response.status !== 200) {
    return {
      status: "F",
      message: responseJson.message,
    };
  }
  return { status: "S", message: responseJson };
};

export const verifyCouponCode = async (baseUrl, apiKey, data) => {
  const response = await fetch(
    `${baseUrl}/billing/offer/verify/`,
    {
      method: "POST",
      headers: buildHeader(apiKey),
      body: data,
    }
  );

  return getJson(response);
}

export const purchasePPVOrder = async (baseUrl, apiKey, data) => {
  const response = await fetch(
    `${baseUrl}/billing/order/purchase/`,
    {
      method: "POST",
      headers: buildHeader(apiKey),
      body: data,
    }
  );

  return getJson(response);
}

export const purchaseSubscription = async (baseUrl, apiKey, data) => {
  const response = await fetch(
    `${baseUrl}/billing/subscription/subscribe/`,
    {
      method: "POST",
      headers: buildHeader(apiKey),
      body: data,
    }
  );

  return getJson(response);
}

export const getActiveGateway = async (baseUrl) => { 
  const response = await fetch(`${baseUrl}/gateway-setting/list/`, {
    headers: buildSecureApiHeader(),
  });

  const gateways = await getJson(response);
  return gateways.filter(gateway => gateway.is_default)[0];
}

export const getGatewayActive = async (baseUrl, apiKey) => {
  const response = await fetch(`${baseUrl}/gateway-setting/list/`, {
    headers: buildHeader(apiKey),
  });

  const gateways = await getJson(response);
  return gateways.filter(gateway => gateway.is_default)[0];
}

export const appContentAccess = async (baseUrl, apiKey) => {
  const response = await fetch(`${baseUrl}/billing/subscription/app-content/`, {
    headers: buildHeader(apiKey),
  });

  return await getJson(response);
}