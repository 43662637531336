import { getPPVEventList} from "../../services/ticketService";
import { OnErrorPoster, NotFoundPoster } from "../../constants/Media";
import { setupPPV } from "../../utils/paymentHelper";
import { ContentType } from "../../constants/ContentType";
import { formatUrl } from "../../utils/domHelper";

if (window.location.href.includes("purchase-ppv-event.html")) {
  const style = document.createElement('style');
  const styleForppv = document.createElement('style');

  style.innerHTML = `
    .ppv-event-banner-list{
      display:none;
    }
    #loading{
      display:flex!important;
    }
  `;
  styleForppv.innerHTML = `
    .ppv-event-banner-list{
      display:block;
    }
    #loading{
      display:none!important;
    }
  `;
  document.head.appendChild(style);
  setTimeout(function() {
  document.head.appendChild(styleForppv);
  }, 20000);
}


export const renderPPVEvent = async ({ apiKey, generalSettings }, baseUrl) => {
  let content_type = ContentType.PPV_EVENT;
  
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const ppvEventItem = $(".ppv-event-banner-item");
  ppvEventItem.hide();

  if (params.eventId || params.channelId) {
    let ppvEvents = await getPPVEventList(baseUrl, apiKey, {
      uuid: params.eventId,
    });
    ppvEvents = ppvEvents['event_list'];
    if(ppvEvents.length > 0){
      let ppvEventData = ppvEvents[0];
      ppvEventData.event_dates = JSON.parse(ppvEventData.event_dates);
      ppvEventItem.find(".ppv-event-banner-title").html(ppvEventData.name);
      ppvEventItem.find(".ppv-event-banner-desc").html(ppvEventData.description);
      ppvEventItem
        .find(".ppv-event-banner-img")
        .attr("src", ppvEventData.image_url || NotFoundPoster)
        .attr("onError", OnErrorPoster);
      ppvEventItem.find(".ppv-event-banner-sub").html(`${moment(ppvEventData.event_dates.lower).format('lll')} - ${moment(ppvEventData.event_dates.upper).format('lll')}`);

      let current_datetime = moment.utc();

      let event_datetime = moment(ppvEventData.event_dates.lower);
      let event_diff_datetime = current_datetime.diff(event_datetime, 'minuts');
      
      let event_pre_datetime = moment(ppvEventData.pre_registration_start_date);
      let event_pre_diff_datetime = current_datetime.diff(event_pre_datetime, 'minuts');

      if(ppvEventData.is_purchased){
        if(event_diff_datetime >= 0){
          ppvEventItem.find('.ppv-event-banner-btn').html('Watch Now').addClass('ppv_event_watch_live_btn');
        }
        else{
          $('.ppv-event-banner-btn').remove();
          $('.ppv-event-banner-btn-wrapper').html(`
            <p style="color:green">
              This event will start at ${moment(ppvEventData.event_dates.lower).format('hh:mm A')} on ${moment(ppvEventData.event_dates.lower).format('ll')} you will be able to watch it by going to this page 
              <b><a href="#" class="ppv_event_watch_live_btn">${ppvEventData.name}</a></b>
            </p>
          `);
        }

        $('.ppv_event_watch_live_btn').on('click', function(){
          const nextURL = `/watch-live`;
          window.location = formatUrl(nextURL);
        });
      }
      else{
        if(event_pre_diff_datetime >= 0){
          ppvEventItem.find('.ppv-event-banner-btn').on('click', function(e){
            e.preventDefault();
            
            let data = {
              'uuid': ppvEventData.uuid,
              'title': ppvEventData.name,
              'price': ppvEventData.price,
              'price_currency': ppvEventData.price_currency,
              'event_dates': ppvEventData.event_dates,
              'content_type': content_type,
            }
            setupPPV(baseUrl, apiKey, data, ppvEventItem, content_type, {});
          });
        }
        else{
          $('.ppv-event-banner-btn').remove();
          $('.ppv-event-banner-btn-wrapper').html(`
            <p style="color:red">
              Registration for this event will start at ${moment(ppvEventData.pre_registration_start_date).format('hh:mm A')} on ${moment(ppvEventData.pre_registration_start_date).format('ll')}.
            </p>
          `);
        }
      }
    }
    else{
      ppvEventItem.removeAttr("id");
      ppvEventItem.find(".ppv-event-banner-title").remove();
      ppvEventItem.find(".ppv-event-banner-desc").html('No Event Available');
      ppvEventItem
        .find(".ppv-event-banner-bg-image-blur")
        .remove();
        ppvEventItem
        .find(".ppv-event-banner-img").remove();
        ppvEventItem.find(".ppv-event-banner-sub").remove();
        ppvEventItem.find('.ppv-event-banner-btn').remove();
    }

    ppvEventItem.show();
  }
};
