import {
  hideLoading,
  loadingPosition,
  loadingSize,
  showLoading,
} from "../Loading";
import { formatUrl } from "../../utils/domHelper";
import {
  getCategoriesList,
  CATEGORY_TYPE,
  getCategoriesAOD,
} from "../../services/categoryService";
import { NotFoundResource, OnAudioErrorImg } from "../../constants/Media";

export const getAudioCategories = async (
  container,
  { generalSettings: settings, apiKey },
  baseUrl,
  template_uuid
) => {
  let categoriesGrids;
  if (container) {
    categoriesGrids = $(container).find(`.audio-categories-grid`).toArray();
  } else {
    categoriesGrids = $(`.audio-categories-grid`)
      .toArray()
      .filter((item) => {
        return $(item).parent(".video-tab-content").length === 0;
      });
  }
  for (const grid of categoriesGrids) {
    const categoryItemClone = $(grid)
      .find(".audio-categories-grid-item")
      .first()
      .clone();
    const categoryContentClone = $(grid)
      .find(".audio-categories-grid-content")
      .first()
      .clone();
    const audioGridClone = $(categoryContentClone)
      .find(".audio-videos-grid")
      .first()
      .clone();
    const videoGridItemClone = $(audioGridClone)
      .find(".audio-videos-grid-item")
      .first()
      .clone();
    const videoGridContentClone = $(audioGridClone)
      .find(".audio-videos-grid-content")
      .first()
      .clone();
    let currentPage = 1;
    let isRendering = false;
    $(grid).empty();
    $(categoryContentClone).empty();
    $(audioGridClone).empty();
    const renderDynamicAudioCategory = async function (pageIndex) {
      isRendering = true;
      // Fetch on Demand categories
      const categories = await getCategoriesAOD(
        baseUrl,
        apiKey,
        settings.tenantId,
        {
          type: CATEGORY_TYPE.ON_DEMAND,
          page: pageIndex,
          page_size: 3,
          has_audio: true,
        }
      );

      categories?.results?.forEach((category) => {
        const categoryItemNew = categoryItemClone.clone();
        $(categoryItemNew).html(category.title);
        const categoryContentNew = categoryContentClone.clone();
        const videoGridNew = audioGridClone.clone();
        category?.audios?.forEach((audio) => {
          const audioGridItemNew = videoGridItemClone.clone();
          const audioGridContentNew = videoGridContentClone.clone();
          audioGridItemNew
            .find("img")
            .attr("src", audio?.thumbnail || NotFoundResource)
            .attr("onError", OnAudioErrorImg);
          audioGridContentNew
            .find(".audio-videos-grid-content-title")
            .html(audio?.title);
          audioGridContentNew
            .find(".audio-videos-grid-content-description")
            .html(audio?.description);
          audioGridContentNew
            .find("img")
            .attr("src", audio?.thumbnail || NotFoundResource)
            .attr("onError", OnAudioErrorImg);
          audioGridContentNew
            .find(".audio-videos-grid-content-bannersubnowbtn")
            .on("click", function () {
              const nextURL = `/play-audio?categoryId=${category.id}&audioId=${audio.id}`;
              window.location = formatUrl(nextURL);
            });
          $(audioGridItemNew).click(() => {
            $(`.audio-videos-grid-item`).removeClass("active");
            $(audioGridItemNew).addClass("active");
            $(`.audio-videos-grid-content`)
              .not($(audioGridItemNew).next())
              .hide();
            $(audioGridItemNew).next().slideDown(500);
          });
          videoGridNew.append(audioGridItemNew);
          videoGridNew.append(audioGridContentNew);
        });
        categoryContentNew.append(videoGridNew);
        $(grid).append(categoryItemNew);
        $(grid).append(categoryContentNew);
      });
      if (categories.total / 3 > currentPage) {
        isRendering = false;
      }
    };

    showLoading($(grid), loadingSize.MEDIUM, loadingPosition.CENTER);
    await renderDynamicAudioCategory(currentPage);
    hideLoading($(grid), loadingSize.MEDIUM, loadingPosition.CENTER);

    $(window).scroll(async function () {
      if (
        (!container ||
          (container && $(container).css("display") === "block")) &&
        !isRendering &&
        $(document).scrollTop() + $(window).height() >=
          $(grid).offset().top + $(grid).height()
      ) {
        currentPage = currentPage + 1;
        showLoading($(grid), loadingSize.MEDIUM, loadingPosition.BOTTOM);
        await renderDynamicAudioCategory(currentPage);
        hideLoading($(grid), loadingSize.MEDIUM, loadingPosition.BOTTOM);
      }
    });
  }
};
