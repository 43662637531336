import { AppConfig } from "../constants/config";
import { StreamType } from "../constants/AppConstants";

export const getPlayingStreamId = (videoUrl) => {
  const splitUrls = videoUrl.split("/");
  for (let i = 0; i < splitUrls.length; i++) {
    const su = splitUrls[i];
    if (su.includes(".mp4")) {
      return su.split(".")[0].split("_")[0];
    }
  }
  return null;
};

export const getDuration = (time) => {
  return moment.duration(
    moment(time, "hh:mm:ss").diff(moment("00:00:00", "hh:mm:ss"))
  );
};

export const durationToSecond = (duration) => {
  if (!duration) return 0;
  try {
    const split = duration.split(":");
    const hours = +split[0];
    const minutes = +split[1];
    const seconds = +split[2];
    return hours * 60 * 60 + minutes * 60 + seconds;
  } catch (error) {
    return 0;
  }
};

export const getPlayingTime = (startDate, videos, position, duration) => {
  const durationHistory = moment
    .duration(moment(new Date()).diff(moment(startDate)))
    .asSeconds();
  let totalPlayHistory = 0;
  videos.forEach((v, i) => {
    if (i < position) {
      totalPlayHistory += getDuration(v.video.duration || 0).asSeconds();
    }
  });
  const vDuration = parseFloat(duration);
  const playingTime = durationHistory - totalPlayHistory;
  return playingTime > vDuration ? 0 : playingTime;
};

export const setupUpComingStream = (player, channel, settings, apiKey) => {
  player.playInternalStream.bind(player);
  window.activeChannelSocket = new WebSocket(
    `${AppConfig.wsUrl}/tenant/${settings.tenantId}/?tenant-key=${apiKey}`
  );

  window.activeChannelSocket.addEventListener("open", () => {
    const subId = new Date().getTime();
    window.retrivePlaying = setInterval(() => {
      window.activeChannelSocket.send(
        JSON.stringify({
          action: "retrieve_playing_index",
          request_id: subId,
          channel_id: channel.id,
        })
      );
    }, 1000);
  });
  // Listen for messages
  window.activeChannelSocket.addEventListener("message", async (event) => {
    const { data } = JSON.parse(event.data);
    if (data && data.playlist_status) {
      if (data.rtmp_status === "broadcasting") {
        player.playInternalStream(channel.id, StreamType.BROADCASTING);
      } else if (data.playlist_status === "broadcasting") {
        player.playInternalStream(channel.id, StreamType.PLAYLIST);
      } else {
        player.destroy();
      }
    }
  });

  window.activeChannelSocket.addEventListener("close", async (event) => {
    if (event.code == 1000 || event.code == 1001) {
      console.log("Channel WS closed");
    } else {
      console.log("Channel WS closed. Retrying... ", event.code);
      setupUpComingStream(player, channel, settings, apiKey);
    }
  });
};