import {
  hideLoading,
  loadingPosition,
  loadingSize,
  showLoading,
} from "../Loading";
import {
  getDefaultReportVideoChannel,
  getStatus,
} from "../../utils/analyticsHelper";
import { getChannelById } from "../../services/channelService";
import AntPlayer from "../AntPlayer";
import {renderStreamTimeline} from "../Slider/Channel";
  
export const embeddedStream = async (
  { generalSettings: settings, apiKey },
  baseUrl,
  template_uuid
) => {
  var currentURL = window.location.href;
  if (currentURL.indexOf("embedded-stream.html") !== -1) {
    showLoading($(document.body), loadingSize.LARGE, loadingPosition.CENTER);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const channelId = params['channelId'];
    
    $('body').html('<div class="embedded_stream_container"><div class="embeded_stream"></div><div class="streaming-timeline"></div></div>');

    const streamPlayer = new AntPlayer(".streaming-timeline", {
      playOrder: "hls",
      onPlayerStarted: () => {
        track.init(getDefaultReportVideoChannel(channelInfo?.id));
        const reportStatus = track.getCurrent().reportStatus;
        if (reportStatus?.send?.start === false) {
          track.resetStatus(getStatus(true));
          track.send("Start", track.getRqStart());
        }
      },
      onPlayerEnded: (metrics) => {
        const reportStatus = track.getCurrent().reportStatus;
        if (!reportStatus?.send?.end) {
          track.send("End", track.getRqEnd(metrics));
        }
      },
    });

    window.onbeforeunload = function () {
      if (streamPlayer) {
        streamPlayer.destroy();
      }
      return null;
    };
    
    const channelInfo = await getChannelById(baseUrl, apiKey, channelId);
    hideLoading($(document.body), loadingSize.LARGE, loadingPosition.CENTER);
    await renderStreamTimeline(streamPlayer, $('.embeded_stream'), channelInfo, [], {
      settings,
      baseUrl,
      apiKey,
    });
  }
  
};
  