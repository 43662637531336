/* eslint-disable no-undef */
import { register } from "../../../services/authService";
import { redirectTo } from "../../../utils/httpHelper";
import { getSubscriptionPlans } from "../../../services/paymentService";
import { getGatewayActive } from "../../../services/paymentService";
import { showAlert } from "../../../utils/domHelper";
import { durationText, durationUnit } from "../../../utils/appHelper";

let cardElement = null;
let stripe = null;
let plans = [];
let selectedPlan = null;

export default async function (baseUrl, tenantId, cb) {
  const { firstName, lastName, userName, email, password, plan } = cb._data;
  let payload = {
    first_name: firstName,
    last_name: lastName,
    username: userName,
    email: email,
    password: password,
    plan_id: plan,
  };
  if (cardElement != null && stripe != null) {
    // Check if selectedPlan is not null before accessing its properties
    if (selectedPlan && !selectedPlan.is_free) {
      payload["payment_method_id"] = await stripeCard(cb._data);
      if (payload["payment_method_id"] == null) {
        showAlert("Card details are not correct");
        cb.emit("submitDone", "");
        return;
      }
    }
  }

  const resJson = await register(baseUrl, tenantId, payload);
  if (resJson.status === "F") {
    cb.emit("submitDone", "");
    return;
  }
  cb.emit("submitDone", "");
  localStorage.setItem("loginToken", JSON.stringify(resJson.message));
  redirectTo();
}

// Add plans dropdown in formio component
export const singupPlansField = async (baseUrl, apiKey, channelId) => {
  // In this step, we are fetching a list of subscription plans from some source. (referred to as getSubscriptionPlans)
  const jsonPlans = await getSubscriptionPlans(baseUrl, apiKey, channelId, {});
  let sub_plans = [];

  if (jsonPlans && jsonPlans.length > 0) {
    plans = jsonPlans;
    // Looping through the plans and adding each plan's value and label to the sub_plans array.
    $.each(jsonPlans, function (index, value) {
      sub_plans.push({
        value: value.uuid,
        label: `${value.title} ($${value.price} Per ${durationUnit(
          value.expires_in
        )})`,
      });
    });

    let defaultSelectedPlan = null;
    let planLabel = null;

    if (sub_plans.length === 1) {
      // If there's only one plan, set it as the default selected plan
      defaultSelectedPlan = sub_plans[0].value;
      planLabel = sub_plans[0].label;
    }
    const selectComponent = {
      type: "select",
      label: "Monthly Plan",
      key: "plan",
      customClass: sub_plans.length === 1 ? "non-clickable-select" : "",
      data: { values: sub_plans },
      dataSrc: "values",
      template: "<span>{{ item.label }}</span>",
      input: true,
      defaultValue: defaultSelectedPlan,
    };
    if (sub_plans.length === 1) {
      selectComponent.title = planLabel;

      // If there's only one plan
      return selectComponent;
    } else if (sub_plans.length > 1) {
      return {
        type: "select",
        label: "Select a Plan",
        key: "plan",
        placeholder: "Select Plan",
        data: { values: sub_plans },
        dataSrc: "values",
        template: "<span>{{ item.label }}</span>",
        input: true,
      };
    }
  }
  return null;
};

// Add CSS using jQuery to a parent box with class "form-content-wrapper"
$(document).ready(function () {
  const customCss = `
    /* Style the form container */
    .form-content-wrapper {
      padding: 30px;
      border-radius: 10px;
      background-color: #ffffff!important;
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.19);
      width: 600px;
      margin:auto;
      padding-bottom: 20px!important;
      padding-top: 40px!important;
    }

    /* Style form labels */
    .form-group label {
      font-weight: bold;
      color: #000000;
      font-size: 16px;
    }

    #stripe-card-fields label {
    font-weight: bold;
    color: #000000;
    font-size: 16px;
    margin-bottom:0px!important;
    }

    /* Style text inputs */
    .form-content-wrapper input ,  .form-content-wrapper .form-control{
      background-color: #d5e5fb!important;
      width: 100%!important;
      padding: 10px!important;
      border-radius: 5px!important;
      border:0px!important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .signup-wrapper-container #stripe-card-element{
      background-color: #d5e5fb!important;
      width: 100%!important;
      padding: 10px!important;
      border-radius: 5px!important;
      border:0px!important;
    }
    #stripe-card-fields{
      margin: 2px;
     margin-top: 0px!important;
    }
    .form-content-wrapper .btn{
      background-color: #00182E;
      border-color: #00182E;
      padding: 12px 20px;
      font-size:18px!important;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity:1;
    }

    .form-content-wrapper p{
      color:#000000!important;
      margin-top: 4px!important;
    }
    .form-content-wrapper a{
      color:#000000!important;
      text-decoration: none!important;
    }
    /* Additional styles for .non-clickable-select */
    .form-content-wrapper .non-clickable-select {
      cursor: default;
      pointer-events: none;
    }
    .form-content-wrapper .non-clickable-select .choices__button {
      background: #d5e5fb!important;
      background-image: none!important;
      z-index: 999999;
      margin-right: 0px!important;
      width: 40px!important;
      opacity: 1!important;
    }
    .form-content-wrapper   div#stripe-card-element{
      height: 38px;
      position: relative;
      top: 4px;
      display:block!important;
    }
    @media screen and (min-width:769px){
      .signup-wrapper-container .form-content-wrapper .formio-form{
        display: flex;
        flex-wrap:wrap;
        justify-content: space-around;
        width:100%;
      }
      .form-content-wrapper .choices{
        width:100%!important;
      }
      .signup-wrapper-container .form-content-wrapper .form-group, #stripe-card-fields{
        width: 48%;
      }
    }
    
  
    @media screen and (max-width:769px){
      .form-content-wrapper{
        padding:10px!important;
        font-size:14px!important;
        width: 100%!important;
        margin:auto;
      }
      .form-content-wrapper .form-group{
        margin-bottom:4px!important;
      }
    }
  `;

  const styleElement = document.createElement("style");
  styleElement.innerHTML = customCss;
  document.head.appendChild(styleElement);
});

// Init Payment flow
export const signupSetupPayment = async (baseUrl, apiKey) => {
  const activeGW = await getGatewayActive(baseUrl, apiKey);
  if (activeGW.gateway_provider == 2) {
    let stripeApiKey = activeGW.credential_information.publishable_api_key;
    signupStripePayment(stripeApiKey);
  }
};

// Stripe Payment
async function signupStripePayment(stripeApiKey) {
  const script_script = document.createElement("script");
  script_script.src = "https://js.stripe.com/v3/";
  script_script.async = true;

  // Load stripe script
  script_script.addEventListener("load", () => {
    $(".formio-component-plan")
      .after(`<div id="stripe-card-fields" class="form-row" style="margin: 2px;margin-bottom: 30px;">
    <label for="stripe-card-element">Credit or debit card</label>
    <div id="stripe-card-element" style="width:100%"></div>
  </div>`);

    stripe = Stripe(stripeApiKey);
    const elements = stripe.elements();
    cardElement = elements.create("card", {
      hidePostalCode: true,
    });
    cardElement.mount("#stripe-card-element");
  });
  document.head.appendChild(script_script);

  // If plan's length is one / more than one code changes
  if (plans.length === 1) {
    selectedPlan = plans[0];
    if (selectedPlan.is_free) {
      $("#stripe-card-fields").hide();
    } else {
      $("#stripe-card-fields").show();
    }
  } else if (plans.length > 1) {
    // on change plan
    $('select[name="data[plan]"]').change(function () {
      if (plans.length > 1) {
        selectedPlan = plans.filter((item) => item.uuid === this.value);
        selectedPlan = selectedPlan[0];
        if (selectedPlan.is_free) {
          $("#stripe-card-fields").hide();
        } else {
          $("#stripe-card-fields").show();
        }
      }
    });
  }
}

// Stripe Card
async function stripeCard(payload) {
  const result = await stripe.createPaymentMethod({
    type: "card",
    card: cardElement,
    billing_details: {
      name: payload["userName"],
      email: payload["email"],
    },
  });

  if ("paymentMethod" in result) {
    return result.paymentMethod.id;
  } else {
    return null;
  }
}
