import { formatUrl } from "../../utils/domHelper";
import { getSingleCategoryById } from "../../services/categoryService";
import { OnErrorImg } from "../../constants/Media";
import { CATEGORY_TYPE_OF_LIST } from "../../constants/Category";

export const createCarouselSlider = async (
  slider,
  videocategory,
  { settings, apiKey, baseUrl }
) => {
  const slideContainer = $(slider).find("[role=slidelist]");
  const slide = $(slideContainer).find("[role=slide]").first().clone();
  slideContainer.empty();
  $(slider)
    .find("[role=sliderviewall]")
    .on("click", function () {
      const nextURL = `/category?categoryId=${videocategory}`;
      window.location = formatUrl(nextURL);
    });

  const categoryVideos = await getSingleCategoryById(baseUrl, apiKey, CATEGORY_TYPE_OF_LIST.VOD, videocategory);

  categoryVideos.medias
    ?.filter((item) => item.type !== "playlist")
    .forEach((video) => {
      const slideClone = slide.clone();
      $(slideClone)
        .find("[role=slideimage]")
        .attr("src", video.thumbnail)
        .attr("onError", OnErrorImg);
      $(slideClone).find("[role=slidetitle]").html(video.title);
      $(slideClone).find("[role=slidedescription]").html(video.description);

      $(slideClone)
        .find("[role=slideplay]")
        .on("click", function () {
          const nextURL = `/watch-movies?categoryId=${videocategory}&videoId=${video.id}`;
          window.location = formatUrl(nextURL);
        });

      $(slideContainer).append(slideClone);
    });
};
