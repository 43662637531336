import {
  hideLoading,
  loadingPosition,
  loadingSize,
  showLoading,
} from "../Loading";
import { formatUrl } from "../../utils/domHelper";
import {
  getCategoriesList,
  CATEGORY_TYPE,
} from "../../services/categoryService";
import { getVideoListByCategory } from "../../services/videoService";
import { NotFoundResource, OnErrorImg } from "../../constants/Media";

export const getCategories = async (
  container,
  { generalSettings: settings, apiKey },
  baseUrl,
  template_uuid
) => {
  let categoriesGrids;
  if (container) {
    categoriesGrids = $(container).find(`.categories-grid`).toArray();
  } else {
    categoriesGrids = $(`.categories-grid`)
      .toArray()
      .filter((item) => {
        return $(item).parent(".video-tab-content").length === 0;
      });
  }
  categoriesGrids.forEach(async (grid) => {
    const loading = `<div class="lds-facebook"><div></div><div></div><div></div></div>`;
    var addEvent = function (
      parentEl,
      classgriddropcontent,
      classgriddropitem,
      firstTime
    ) {
      const each = (items, clb) => {
        const arr = items || [];
        for (let i = 0; i < arr.length; i++) clb(arr[i], i);
      };
      const el = document;
      const handleClickItem = function (item) {
        // remove all active
        $(`.${classgriddropitem}`).removeClass("active");
        $(item).addClass("active");
        $(`.${classgriddropcontent}`).not($(item).next()).hide();
        $(item).next().slideDown(500);

        // == active
        $(`.${classgriddropitem}`).find(".bottom-arrow").hide();
        $(item).find(".bottom-arrow").show();
      };
      if (firstTime) {
        handleClickItem(parentEl);
      }
      const getGridItems = () => el.querySelectorAll(`.${classgriddropitem}`);
      each(getGridItems(), (item) => {
        $(item).click(() => {
          handleClickItem(item);
        });
      });
    };
    const templateGridItem = $(grid).find(".categories-grid-item").first();
    const gridContent = $(grid).find(".categories-grid-content").first();
    let gridContentClone = gridContent.clone();
    let videoGrid = gridContentClone.find("videos-grid").first().clone();
    const videoGridItem = gridContentClone
      .find(".videos-grid .videos-grid-item")
      .first();
    const videoGridContent = gridContentClone
      .find(".videos-grid .videos-grid-content")
      .first();
    gridContentClone.empty();
    gridContent.find("videos-grid").empty();
    $(grid).empty();
    gridContent.empty();
    // render grid category
    showLoading($(grid), loadingSize.MEDIUM, loadingPosition.CENTER);
    // Fetch on Demand categories
    const categories = await getCategoriesList(
      baseUrl,
      apiKey,
      settings.tenantId,
      { type: CATEGORY_TYPE.ON_DEMAND }
    );
    hideLoading($(grid), loadingSize.MEDIUM, loadingPosition.CENTER);
    categories?.results?.forEach((category) => {
      const clone = templateGridItem.clone();
      $(clone)
        .find("img")
        .attr("src", category.thumbnail || NotFoundResource)
        .attr("onError", OnErrorImg);
      $(clone).attr("categoryID", category?.id);
      $(grid).append(clone);
    });
    addEvent(document, "categories-grid-content", "categories-grid-item");
    // render grid videos
    $(".categories-grid-item").on("click", async function () {
      const videocategory = $(this).attr("categoryID");
      $(this).append(loading);
      // Fetch video list
      const vodCategory = await getVideoListByCategory(
        baseUrl,
        apiKey,
        settings.tenantId,
        videocategory
      );
      $(this).find(".lds-facebook").remove();
      //clear data old content after
      let _this = $(this);
      let next = _this.next();
      if (next.hasClass("categories-grid-content")) {
        next.remove();
      }
      gridContentClone.empty();
      videoGrid = $(`<div class='videos-grid'></div>`);
      vodCategory?.results
        ?.filter((item) => item.type !== "playlist")
        .forEach((video) => {
          const videoGridItemClone = videoGridItem.clone();
          const videoGridContentClone = videoGridContent.clone();
          videoGridItemClone.find("img").attr("src", video?.thumbnail);
          videoGridContentClone
            .find(".videos-grid-content-title")
            .html(video?.title);
          videoGridContentClone
            .find(".videos-grid-content-description")
            .html(video?.description);
          videoGridContentClone.find("img").attr("src", video?.banner);
          videoGridContentClone
            .find(".videos-grid-content-bannersubnowbtn")
            .on("click", function () {
              const nextURL = `/watch-movies?categoryId=${videocategory}&videoId=${video.id}`;
              window.location = formatUrl(nextURL);
            });
          videoGrid.append(videoGridItemClone);
          videoGrid.append(videoGridContentClone);
        });
      gridContentClone.append(videoGrid);
      gridContentClone.insertAfter(_this);
      addEvent(_this, "videos-grid-content", "videos-grid-item", true);
    });
  });
};
