import {
  hideLoading,
  loadingPosition,
  loadingSize,
  showLoading,
} from "../Loading";
import { createSlider } from "../Slider";
import {
  CATEGORY_SERIES_GRID,
  VIDEO_SEASON_GRID,
  VIDEO_SERIES_GRID,
} from "./constants";
import {
  getCategoriesList,
  CATEGORY_TYPE,
} from "../../services/categoryService";
import { OnErrorImg, NotFoundResource } from "../../constants/Media";

export const getVODCategorySeries = async (
  container,
  { generalSettings: settings, apiKey },
  baseUrl,
  template_uuid
) => {
  let categorySeriesGrids;
  if (container) {
    categorySeriesGrids = $(container)
      .find(`.${CATEGORY_SERIES_GRID.classGridDrop}`)
      .toArray();
  } else {
    categorySeriesGrids = $(`.${CATEGORY_SERIES_GRID.classGridDrop}`)
      .toArray()
      .filter((item) => {
        return $(item).parent(".video-tab-content").length === 0;
      });
  }
  categorySeriesGrids.forEach(async (categorySeriesGrid) => {
    const categorySeriesItemClone = $(categorySeriesGrid)
      .find(`.${CATEGORY_SERIES_GRID.classGridDropItem}`)
      .first()
      .clone();
    const categorySeriesContentClone = $(categorySeriesGrid)
      .find(`.${CATEGORY_SERIES_GRID.classGridDropContent}`)
      .first()
      .clone();
    const videoSeriesGridClone = $(categorySeriesContentClone)
      .find(`.${VIDEO_SERIES_GRID.classGridDrop}`)
      .first()
      .clone();
    const videoSeriesItemClone = $(videoSeriesGridClone)
      .find(`.${VIDEO_SERIES_GRID.classGridDropItem}`)
      .first()
      .clone();
    const videoSeriesContentClone = $(videoSeriesGridClone)
      .find(`.${VIDEO_SERIES_GRID.classGridDropContent}`)
      .first()
      .clone();
    const videoSeasonGridClone = $(videoSeriesContentClone)
      .find(`.${VIDEO_SEASON_GRID.classGridDrop}`)
      .first()
      .clone();
    const videoSeasonItemClone = $(videoSeasonGridClone)
      .find(`.${VIDEO_SEASON_GRID.classGridDropItem}`)
      .first()
      .clone();
    const videoSeasonContentClone = $(videoSeasonGridClone)
      .find(`.${VIDEO_SEASON_GRID.classGridDropContent}`)
      .first()
      .clone();
    const videoSeasonSliderClone = $(videoSeasonContentClone)
      .find(`${VIDEO_SEASON_GRID.selectorGridDropContentSlider}`)
      .first()
      .clone();
    let currentPage = 1;
    let isRendering = false;
    const getCategorySeries = async (pageIndex) => {
      return await getCategoriesList(baseUrl, apiKey, settings.tenantId, {
        type: CATEGORY_TYPE.SERIES,
        page: currentPage,
        page_size: 3,
      });
    };

    const renderDynamicCategorySeries = async function (pageIndex) {
      isRendering = true;
      const categorySeries = await getCategorySeries(pageIndex);
      if (categorySeries?.results?.length > 0) {
        categorySeries?.results.forEach((category) => {
          const categorySeriesItemNew = categorySeriesItemClone
            .clone()
            .html(category.title);
          const categorySeriesContentNew = categorySeriesContentClone.clone();
          categorySeriesContentNew.empty();
          const videoSeriesGridNew = videoSeriesGridClone.clone();
          videoSeriesGridNew.empty();

          const videoSeriesList = category.children || [];

          videoSeriesList.forEach((videoSeries) => {
            const videoSeriesItemNew = videoSeriesItemClone.clone();
            const videoSeriesContentNew = videoSeriesContentClone.clone();
            const videoSeasonList = videoSeries.children || [];
            $(videoSeriesItemNew)
              .find("img")
              .attr("src", videoSeries?.thumbnail || NotFoundResource)
              .attr("onError", OnErrorImg);
            $(videoSeriesContentNew)
              .find(`.${VIDEO_SERIES_GRID.classGridDropContent}-title`)
              .html(videoSeries?.title);
            $(videoSeriesContentNew)
              .find(`.${VIDEO_SERIES_GRID.classGridDropContent}-description`)
              .html(videoSeries?.short_description);
            $(videoSeriesContentNew)
              .find(`.${VIDEO_SEASON_GRID.classGridDrop}`)
              .remove();
            $(videoSeriesItemNew).click(async () => {
              $(`.${VIDEO_SERIES_GRID.classGridDropItem}`).removeClass(
                "active"
              );
              $(`.${VIDEO_SERIES_GRID.classGridDropItem}`)
                .parent()
                .removeClass("selected");

              if (videoSeasonList.length === 0) {
                if (
                  $(videoSeriesContentNew).find(
                    `${VIDEO_SEASON_GRID.selectorGridDropContentSlider}`
                  ).length === 0
                ) {
                  showLoading(
                    videoSeriesItemNew,
                    loadingSize.SMALL,
                    loadingPosition.CENTER
                  );
                  const videoSeasonSlider = videoSeasonSliderClone
                    .clone()
                    .attr("videocategory", videoSeries.id);
                  const slider = await createSlider(videoSeasonSlider, {
                    settings,
                    apiKey,
                    baseUrl,
                    template_uuid,
                  });
                  $(videoSeriesContentNew).append(slider);
                  hideLoading(
                    videoSeriesItemNew,
                    loadingSize.SMALL,
                    loadingPosition.CENTER
                  );
                }
                $(videoSeriesContentNew)
                  .find("[role=slidelist]")
                  .slick("refresh");
              }
              $(videoSeriesItemNew).addClass("active");
              $(videoSeriesItemNew).parent().addClass("selected");
              $(`.${VIDEO_SERIES_GRID.classGridDropContent}`)
                .not($(videoSeriesItemNew).next())
                .hide();
              $(videoSeriesItemNew).next().slideDown(500);
            });

            const videoSeasonGridNew = videoSeasonGridClone.clone();
            $(videoSeasonGridNew).empty();

            videoSeasonList.forEach((videoSeason) => {
              const videoSeasonItemNew = videoSeasonItemClone.clone();
              const videoSeasonContentNew = videoSeasonContentClone.clone();
              $(videoSeasonItemNew)
                .find(`.${VIDEO_SEASON_GRID.classGridDropItem}-title`)
                .html(videoSeason?.title);

              $(videoSeasonItemNew).click(async () => {
                showLoading(videoSeasonItemNew[0], loadingSize.SMALL);

              $(videoSeasonContentNew)
                .find("img")
                .attr("src", videoSeason?.thumbnail);
              $(videoSeriesContentNew)
                .find(`.${VIDEO_SEASON_GRID.classGridDropContent}-title`)
                .html(videoSeason?.title);
              $(videoSeasonContentNew)
                .find(`.${VIDEO_SEASON_GRID.classGridDropContent}-description`)
                .html(videoSeason?.short_description);
              $(videoSeasonContentNew)
                .find(`${VIDEO_SEASON_GRID.selectorGridDropContentSlider}`)
                .remove();
                $(`.${VIDEO_SEASON_GRID.classGridDropContent}`)
                  .not($(videoSeasonItemNew).next())
                  .hide();
                $(`.${VIDEO_SEASON_GRID.classGridDropItem}`).removeClass(
                  "active"
                );
                $(`.${VIDEO_SEASON_GRID.classGridDropItem}`)
                  .parent()
                  .removeClass("selected");
                $(videoSeasonItemNew).addClass("active");
                $(videoSeasonItemNew).parent().addClass("selected");
                if (
                  $(videoSeasonContentNew).find(
                    `${VIDEO_SEASON_GRID.selectorGridDropContentSlider}`
                  ).length === 0
                ) {
                  const videoSeasonSlider = videoSeasonSliderClone
                    .clone()
                    .attr("videocategory", videoSeason.id);
                  const slider = await createSlider(videoSeasonSlider, {
                    settings,
                    apiKey,
                    baseUrl,
                    template_uuid,
                  });
                  $(videoSeasonContentNew)
                    .find(
                      `.${VIDEO_SEASON_GRID.classGridDropContent}-slider-container`
                    )
                    .append(slider);
                }
                hideLoading(videoSeasonItemNew, loadingSize.SMALL);
                $(videoSeasonItemNew).next().slideDown(500);
                $(videoSeasonContentNew)
                  .find("[role=slidelist]")
                  .slick("refresh");
              });

              $(videoSeasonGridNew)
                .append(videoSeasonItemNew)
                .append(videoSeasonContentNew);
            });
            // TODO
            $(videoSeriesContentNew).append(videoSeasonGridNew);
            $(videoSeriesGridNew)
              .append(videoSeriesItemNew)
              .append(videoSeriesContentNew);
          });

          $(categorySeriesContentNew).append(videoSeriesGridNew);
          $(categorySeriesGrid)
            .append(categorySeriesItemNew)
            .append(categorySeriesContentNew);
        });
      }
      if (categorySeries.total / 3 > currentPage) {
        isRendering = false;
      }
    };
    $(categorySeriesGrid).empty();
    showLoading(
      $(categorySeriesGrid),
      loadingSize.MEDIUM,
      loadingPosition.CENTER
    );
    await renderDynamicCategorySeries(currentPage);
    hideLoading(
      $(categorySeriesGrid),
      loadingSize.MEDIUM,
      loadingPosition.CENTER
    );

    $(window).scroll(async function () {
      if (
        (!container ||
          (container && $(container).css("display") === "block")) &&
        !isRendering &&
        $(document).scrollTop() + $(window).height() >=
          $(categorySeriesGrid).offset().top + $(categorySeriesGrid).height()
      ) {
        currentPage = currentPage + 1;

        showLoading(
          $(categorySeriesGrid),
          loadingSize.MEDIUM,
          loadingPosition.BOTTOM
        );
        await renderDynamicCategorySeries(currentPage);
        hideLoading(
          $(categorySeriesGrid),
          loadingSize.MEDIUM,
          loadingPosition.BOTTOM
        );
      }
    });
  });
};
