import { planFormatter } from "../../utils/mappers";
import { formatUrl, hideLoading, showLoading } from "../../utils/domHelper";
import { createCardFive } from "./PlanFive";
import { createCardFour } from "./PlanFour";
import { createCardOne } from "./PlanOne";
import { createCardSix } from "./PlanSix";
import { createCardThree } from "./PlanThree";
import { createCardTwo } from "./PlanTwo";
import { getSubscriptionPlans } from "../../services/paymentService";

export const getSubscriptions = async ({ apiKey }, baseUrl) => {
  const subscriptionWrapper = document.querySelectorAll(
    ".subscription-card-wrapper"
  );

  if (subscriptionWrapper && subscriptionWrapper.length > 0) {
    showLoading();
    subscriptionWrapper.forEach(async (element) => {
      const queries = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let channelId = queries.channelId; 
      let planId = queries.planId; 
      if(!channelId && !planId) {
        channelId = element.parentElement.attributes.channelid.value;
      } else if (!channelId) {
        channelId = 'empty';
      }
      const jsonPlans = await getSubscriptionPlans(baseUrl, apiKey, channelId, {uuid: planId});
      let fPlans = planFormatter(jsonPlans);
      planGenerator(element, fPlans);
      setTimeout(() => {
        attachSubscriptionEvents();
        hideLoading();
      }, 0);
    });
  }
};

const planGenerator = (planWrapper, plans) => {
  planWrapper.innerHTML = "";
  plans.forEach((plan) => {
    const template = document.createElement("template-card");
    if (plan.pricing_tbl_style === 1) {
      template.innerHTML = createCardOne(plan);
    }
    if (plan.pricing_tbl_style === 2) {
      template.innerHTML = createCardTwo(plan);
    }
    if (plan.pricing_tbl_style === 3) {
      template.innerHTML = createCardThree(plan);
    }
    if (plan.pricing_tbl_style === 4) {
      template.innerHTML = createCardFour(plan);
    }
    if (plan.pricing_tbl_style === 5) {
      template.innerHTML = createCardFive(plan);
    }
    if (plan.pricing_tbl_style === 6) {
      template.innerHTML = createCardSix(plan);
    }
    planWrapper.appendChild(template);
  });
}

export const attachSubscriptionEvents = () => {
  $(".subscription-card-btn-atc").click(() => {
    alert("Not supported yet!");
  });
  $(".subscription-card-btn-purchase").click(() => {
    alert("Not supported yet!");
  });

  const token = localStorage.getItem("loginToken");

  $(".subscription-card-btn-signup").click((e) => {
    const subscriptionId = e.target.dataset["subscriptionId"];
    localStorage.setItem("selectedPlan", subscriptionId);
    if (!token) {
      window.location = formatUrl("/sign-up?redirect_to=/check-out");
    } else {
      window.location = formatUrl("/check-out");
    }
  });

  if (token) {
    $(".subscription-card-btn-signup").html("UPGRADE");
  }
};
