import { registerPayment } from "../components/PPV";
import { ContentType } from "../constants/ContentType";

export const setupPPV = (
  baseUrl,
  apiKey,
  data,
  container,
  content_type,
  category,
  isSingle = false,
) => {

  if(content_type == ContentType.PPV_EVENT || content_type == ContentType.CHANNEL || content_type == ContentType.TENANT){
    let is_sub = (content_type == ContentType.CHANNEL || content_type == ContentType.TENANT);
    registerPayment(baseUrl, apiKey, data, container, is_sub);
  }
  else{
    let ppvPackage;
    let title;
    if (content_type == ContentType.AUDIO || content_type == ContentType.VIDEO || isSingle){
      ppvPackage = data.packages?.filter((p) => p.content_type == content_type)[0];
      title = data.title;
    }
    else{
      ppvPackage = category.packages.filter(
        (p) =>
          (p.content_type == ContentType.VOD || p.content_type == ContentType.AOD) &&
          category.related_ids.includes(p.content_uuid)
      )[0];
      title = category.title;
    }

    if (ppvPackage) {
      const isSubscription = ppvPackage.subscription_plans && ppvPackage.subscription_plans.length > 0;
      if(isSubscription) {
        const plan = ppvPackage.subscription_plans;
        registerPayment(baseUrl, apiKey, plan, container, true);
      } else {
        let data_obj = {
          'uuid': ppvPackage.uuid,
          'title': title,
          'price': ppvPackage.price,
          'price_currency': ppvPackage.price_currency,
          'content_type': ppvPackage.content_type,
        }
        registerPayment(baseUrl, apiKey, data_obj, container, false);
      }
    }
  }
};