import { durationText, durationUnit } from "../../utils/appHelper";

export const createStripePurchaseCard = (data, is_subscription) => {
  let is_single = true;
  if ($.type(data) == "array") {
    is_single = false;
  }
  let paymentDetails = "";
  let plans_field = "";
  let cardFields = "";

  if (is_single) {
    if (is_subscription) {
      const singlePlan = data[0];
      paymentDetails = `
        <p class="purchase-card-title">SUBSCRIPTION</p>
        <p class="purchase-card-video-title" style="margin-bottom: 0;padding: 0px;">${
          data.title
        }</p>
        <p class="purchase-card-video-title" style="margin-bottom: 0;padding-bottom: 0;">Subscribe now to watch this content</p>
        ${
          data.trial_in
            ? '<p class="purchase-card-video-title" style="margin-bottom: 0;padding-bottom: 0;">Free Trial: ' +
              durationText(data.trial_in) +
              "</p>"
            : ""
        }
        <p class="purchase-card-price price-p" style="text-align:center;">
          $${singlePlan?.price} 
          <span class="purchase-card-video-title" style="color: black;padding: 0px;">(Per ${durationUnit(
            singlePlan.expires_in
          )})<span>
        </p>
      `;
    } else {
      paymentDetails = `
        <p class="purchase-card-title">PAY PER VIEW</p>
        <p class="purchase-card-video-title">${data?.title}</p>
        <p class="purchase-card-price" style="text-align:center;padding-top:0px">$${data?.price}</p>
      `;
    }

    // For a single plan, set the default value and show cardFields
    cardFields = `
      <div class="form-row">
        <label for="stripe-card-element">Credit or debit card</label>
        <div id="stripe-card-element" style="width:100%"></div>
      </div>
    `;
  } else {
    paymentDetails = `<p class="purchase-card-title">${
      is_subscription ? "SUBSCRIPTION" : "PAY PER VIEW"
    }</p>
    <p class="purchase-card-video-title">${
      is_subscription ? "Subscribe now to watch this content" : data.title
    }</p>`;
    plans_field = `<div class="form-row plans_dropdown ${
      data.length === 1 ? "select-single" : ""
    }" style="margin-top:10px">
    <label for="${
      is_subscription && data.length === 1 ? data[0].title : "Plans"
    }">${is_subscription && data.length === 1 ? data[0].title : "Plans"}</label>
    <select name="plan_id" style="width:100%" id="plan_value">`;

    data.forEach(function (val, index) {
      plans_field += `<option value="${val.uuid}" ${
        index === 0 ? "selected" : ""
      }>${val.title} ($${val.price} Per ${durationUnit(
        val.expires_in
      )})</option>`;
    });

    plans_field += `</select></div>`;

    // For multiple plans, show cardFields initially
    cardFields = `
      <div class="form-row stripeFieldBox">
        <label for="stripe-card-element">Credit or debit card</label>
        <div id="stripe-card-element" style="width:100%"></div>
      </div>
    `;
  }

  return `
    <div class="modal" id="stripePurchaseCard" style="display: flex;align-items: center; justify-content: center;">
      <style>
        label{
          font-size: 15px;
          margin-bottom: 0px;
        }
        #card-element {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
        }
        
        .StripeElement {
          width: 100%;
          padding: 10px;
          font-size: 16px;
          line-height: 24px;
          border: 1px solid #ccc;
          border-radius: 0px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .StripeElement .__PrivateStripeElement{
          width:100%;
        }
        
        .StripeElement--focus {
          border-color: #000;
        }
        #coupon_code, #email, #first_name, #last_name, #plan_value, #user_name, #password{
          font-size: 16px;
          line-height: 24px;
          border: 1px solid #ccc;
          border-radius: 4px;
          padding: 10px;
        }
        #stripe-card-errors{
          margin-top: 20px;
          text-align: left;
          color: red;
          font-size: 16px;
          margin-bottom: 10px;
        }
        .purchase-card-price{
          padding: 10px;
          margin-bottom: 0px;
          font-size: 26px;
        }
        .coupon_apply_btn {
          outline: none;
          border: none;
          background: black;
          color: white;
          font-size: 18px;
          font-weight: 400;
          padding: 10px 20px;
        }

        #form-Info-Box .col{
          display: flex;
          justify-content: center;
          align-items: start;
          flex-direction: column;
        }
        /* For a single plan, hide the dropdown arrow */
        .plans_dropdown.select-single select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-image: none;
          pointer-events:none;
        }
        .coupon_field{
          margin-top:14px;
        }
        .purchase-card{
          width:500px;
          font-family: Roboto, Arial, Helvetica, sans-serif;
        }
        .purchase-card input, .purchase-card select{
          border-radius:0px!important;
        }
        .coupon_field .colOne{
          width: 75%;
          margin: auto;
          margin-left: 0;
          display: flex;
          justify-content: center;
          align-items: center;      
        }
        .coupon_field .colTwo{
          width:24%;
          margin: auto;
          margin-right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
      
        }
        .coupon_field .colTwo button{
          width:100%;
        }
        @media screen and (max-width:601px){
          .purchase-card{
            width:90%!important;
          }
          .purchase-card label{
            font-size:12px;
          }
          .purchase-card input, .purchase-card select{
            padding:6px!important;
            font-size: 12px!important;
          }
          .purchase-card #form-Info-Box{
            display:block;
            margin-top:10px!important;
          }
          .purchase-card #form-Info-Box .col{
            padding: 2px 0px;
          }
          .purchase-card .purchase-card-video-title{
            font-size: 14px!important;
            padding: 4px 0px 06px 0px;
          }
          .purchase-card .price_details .purchase-card-title{
            font-size: 20px!important;
          }
          .purchase-card #stripe-payment-form{
            padding:0px 20px!important;
          }
          .coupon_field .colOne, .coupon_field .colTwo{
            width:100%;
          }
          .coupon_field .colTwo button{
            width:100%;
            margin:6px auto;
            font-size: 14px;
          }
          button#stripe-submit-payment{
            font-size: 14px;
          }
          .secndCol{
            margin-top:8px;
          }
          .purchase-card input::placeholder{
            font-size:12px;
          }
          .purchase-card #stripe-card-errors{
            font-size: 12px;
            margin-top:10px;
          }
          .purchase-card button#stripe-submit-payment{
            margin-bottom: 10px;
          }
          .purchase-card .alreadyAccount{
            text-align: center;
            font-size: 12px;
            margin-bottom: 30px;
          }
          .purchase-card .alreadyAccount a{
            font-weight: bold;
            font-size: 12px;
          }
          .purchase-card .StripeElement{
            height:auto;
          }
           .purchase-card-price{
          padding: 10px;
          margin-bottom: 0px;
          font-size: 16px;
        }
        #stripePurchaseCard{
          width: 98%;
          display: flex;
          align-items: flex-start!important;
          margin: 24px auto;
          left:0;
          right:0;
        }
        }
        @media screen and (max-width:1025px) and (min-width:601px){
          #stripePurchaseCard{
            width: 98%;
            display: flex;
            align-items: flex-start!important;
            margin: 24px auto;
            left:0;
            right:0;
            top:120px;
          }
        }
        .closeStripePurchaseCardBox{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 20px;
          margin-top: 10px;
        }
        .closeStripePurchaseCardBox #closeStripePurchaseCard{
          position: unset;
          width: 30px;
          color:#000000;
        }
        .purchase-card .purchase-card-title{
          text-align: center;
          font-size: 24px;
          font-weight: 600;
          padding: 0px;
          margin-top: 0px;
          margin-bottom:0px;
          letter-spacing: 3px;  
        }
        .purchase-card .purchase-card-video-title{
          font-size:18px;
          font-weight:400;
          margin-bottom:0px!important;
        }
       .purchase-card .purchase-card-buy-now-container{
          margin:0px;
        }
        .purchase-card .form-row{
          margin:0px;
        }
        .purchase-card-buy-now{
          margin-bottom: 30px;
        }
        .purchase-card button {
          border-radius: 0px;
          margin: 0px auto;
          box-shadow:0px 0px 4px 0px #aaa;
        }
        button#stripe-submit-payment{
          width:90%;
          margin-bottom: 20px;
        }
        #stripe-payment-form a{
          text-decoration: none!important;
          color: #000000;
        }

        .form-row.stripeFieldBox {
          margin-top: 10px;
        }
        
        .alreadyAccount{
          text-align: center;
          font-size: 16px;
          margin-bottom: 30px;
        }
        .alreadyAccount a{
          font-weight: bold;
          font-size: 16px;
        }
      </style>

      <div class="purchase-card">
       <div  class="closeStripePurchaseCardBox"> 
       <span id="closeStripePurchaseCard" class="close-purchase-card">&times;</span></div>
        <div class="price_details">${paymentDetails}</div>
        <div class="purchase-card-buy-now-container">
          
          <form id="stripe-payment-form" style="width:100%;padding: 0px 30px;text-align:center;">

            <div class="plans_dropdown">${plans_field}</div>
            
            <div class="plannedValue">${cardFields}</div>

            <div class="form-row" id="form-Info-Box" style="text-align: left;margin-top: 10px;">
              <div class="col" style="padding-left:0px">
                <label for="first_name">First Name</label>
                <input type="first_name" id="first_name" name="first_name" placeholder="Enter your first name" style="width:100%" required>
              </div>
              <div class="col secndCol" style="padding-right:0px">
                <label for="last_name">Last Name</label>
                <input type="last_name" id="last_name" name="last_name" placeholder="Enter your last name" style="width:100%" required>
              </div>
            </div>

            <div class="form-row" id="form-Info-Box" style="text-align: left;margin-top: 10px;">
              <div class="col" style="padding-left:0px">
              <label for="email">Email</label>
              <input type="email" id="email" name="email" placeholder="Enter your email" style="width:100%" required>
              </div>
              <div class="col secndCol" style="padding-right:0px">
                <label for="password">Password</label>
                <input type="password" id="password" name="password" placeholder="Enter your password" style="width:100%" required>
              </div>
            </div>
            <div class="coupon_field"></div>
            
            <div id="stripe-card-errors" role="alert"></div>
          
            <button id="stripe-submit-payment" class="purchase-card-buy-now">Submit Payment</button>

            <p class="alreadyAccount">Already have an account? <a href="/sign-in">Login</a></p>
          </form>

        </div>
      </div>
    </div>
  `;
};

export const addCardFeilds = (data, is_subscription) => {
  $(".coupon_field").html(`<div class="form-row" style="text-align: left">
    <div class="colOne">
      <input type="coupon_code" id="coupon_code" name="coupon_code" style="width:100%" required, placeholder="Coupon Code">
    </div>
    <div class="colTwo">
      <button type="button" id="coupon_apply_btn" class="coupon_apply_btn">Apply</button>
    </div>
  </div>`);

  $(".card_fields")
    .html(`<div class="form-row" style="margin-bottom: 12px;margin-top: 15px">
    <label for="stripe-card-element">Credit or debit card</label>
    <div id="stripe-card-element" style="width:100%"></div>
  </div>`);
};
