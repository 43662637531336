import { formatUrl } from "../../utils/domHelper";
import { CategorySliderType } from ".";
import { getSingleCategoryById } from "../../services/categoryService";
import {
  OnErrorImg,
  NotFoundResource,
  NotFoundAudioResource,
  OnAudioErrorImg,
} from "../../constants/Media";
import { CATEGORY_TYPE_OF_LIST } from "../../constants/Category";

export const createCarouselSliderDC = async (
  slider,
  { videocategory, categoryslidertype, haslist },
  { settings, apiKey, baseUrl },
  mediaList
) => {
  const slideContainer = $(slider).find("[role=slidelist]");
  const slideContents = $(slider).find("[role=slidecontents]");
  const slide = $(slideContainer).find("[role=slide]").first().clone();
  const slideContent = $(slideContents)
    .find("[role=slidecontent]")
    .first()
    .clone();
  slideContainer.empty();
  slideContents.empty();

  let category;
  if (!haslist) {
    const categoryType =
      categoryslidertype === CategorySliderType.Audio
        ? CATEGORY_TYPE_OF_LIST.AOD
        : CATEGORY_TYPE_OF_LIST.VOD;
    category = await getSingleCategoryById(
      baseUrl,
      apiKey,
      categoryType,
      videocategory
    );
  }

  const notFoundImage =
    categoryslidertype === CategorySliderType.Audio
      ? NotFoundAudioResource
      : NotFoundResource;
  const errImage =
    categoryslidertype === CategorySliderType.Audio
      ? OnAudioErrorImg
      : OnErrorImg;

  const mediaData = haslist ? mediaList?.results : category?.medias;
  (mediaData || [])
    ?.filter((item) => item.type !== "playlist")
    .forEach((media) => {
      const slideClone = slide.clone();
      $(slideClone)
        .find("[role=slideimage]")
        .attr("src", media.thumbnail || notFoundImage)
        .attr("onError", errImage);
      $(slideClone).find("[role=slidetitle]").html(media.title);

      const slideContentClone = slideContent.clone();
      $(slideContentClone).attr("id", media.id);
      $(slideContentClone)
        .find("[role=slidecontentimage]")
        .attr("src", media.thumbnail || notFoundImage)
        .attr("onError", errImage);
      $(slideContentClone).find("[role=slidecontenttitle]").html(media.title);
      $(slideContentClone)
        .find("[role=slidecontentdescription]")
        .html(media.description);
      $(slideContentClone)
        .find("[role=slidecontendescription]")
        .html(media.description);

      $(slideClone).on("click", function () {
        $(`[role=slidecontent]:not(#${media.id})`).hide();
        $(slideContentClone).slideToggle(500);
      });

      if (categoryslidertype === CategorySliderType.PPV) {
        const timeElement = (
          $(slideContentClone).find("[role=slidecontendescription]").clone() ||
          $(slideContentClone).find("[role=slidecontendescription]").clone()
        )
          .attr("role", "slidecontenttime")
          .css({
            height: "auto",
            backgroundColor: "white",
            borderRadius: " 0.25rem",
            marginTop: "8px",
            width: "fit-content",
            padding: "4px 8px",
            color: "black",
            textShadow: "none",
            fontWeight: "600",
            fontSize: "14px",
          })
          .html(
            `${moment(media.event_dates[1]).format("lll")} - ${moment(
              media.event_dates[2]
            ).format("lll")}`
          );
        $(slideContentClone)
          .find("[role=slidecontenttitle]")
          .after(timeElement);
        $(slideContentClone).find("[role=slidecontentplay]").html("BUY NOW");
      }

      $(slideContentClone)
        .find("[role=slidecontentplay]")
        .on("click", function () {
          let nextURL = "";
          switch (categoryslidertype) {
            case CategorySliderType.Video:
              nextURL = `/watch-movies?categoryId=${videocategory}&videoId=${media.id}`;
              break;
            case CategorySliderType.Audio:
              nextURL = `/play-audio?categoryId=${videocategory}&audioId=${media.id}`;
              break;
            case CategorySliderType.PPV:
              nextURL = `/purchase-ppv-event.html?eventId=${media.id}`;
              break;
            default:
              nextURL = `/watch-movies?categoryId=${videocategory}&videoId=${media.id}`;
              break;
          }
          window.location = formatUrl(nextURL);
        });

      $(slideContentClone)
        .find("[role=slidecontentclose]")
        .on("click", () => {
          $(slideContentClone).fadeOut();
        });
      $(slideContainer).append(slideClone);
      $(slideContents).append(slideContentClone);
    });
};
