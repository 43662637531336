import {VIDEOS_SEARCH_COMPONENT, VIDEOS_SEARCH_GRID_DROP_OPTIONS} from "./constants";
import {searchVideo} from "../../services/videoService";
import {hideLoading, loadingPosition, loadingSize, showLoading} from "../Loading";

export const searchMedia = async ({generalSettings: settings, apiKey},
                                  baseUrl
) => {
    function renderSearchComponent() {
        return `
     <div class="search-container">
      <div class="row">    
        <div class="col-xs-12 col-xs-offset-2">
        <div class="input-group" style="top: -8px;">
                <div class="search-panel">
                <select  id="search-option"  name="search-option" class="form-select">
                    <option value="2" selected>Video</option>
                    <option value="1" >Audio</option>
                </select>
                </div>
                <input type="hidden" name="search_param" value="all" id="search_param">         
                <input type="search" id="search-input" class="form-control s-input" name="search-input" placeholder="Search by title video or audio">
                <span class="input-group-btn btn-group">
                    <button id="search-btn" class="btn btn-default" type="button"><i class="fas fa-search"></i></button>
                    <button id="cancel-btn" class="btn btn-default" style="display: none" type="button"><i class="fas fa-times"></i></button>
                </span>
            </div>
        </div>
  </div>
</div>
    `
    }

    const videoTabContainer = $(".video-tabs-container");
    const videoTabContents = $(".video-tabs-contents");
    const videoTabContent = $(`<div class="video-tabs-contents-result">
       <div id="result-search" class="video-tab-content"  style="display: block;">
       </div>
    </div>
    `);
    videoTabContents.append(videoTabContent);
    videoTabContainer.append(renderSearchComponent());
    const searchContainer = videoTabContainer.find(".search-container");
    const searchOption = videoTabContainer.find("#search-option");
    const searchPanel = videoTabContainer.find(".search-panel");
    const searchBtn = $(videoTabContainer).find("#search-btn");
    const cancelBtn = $('#cancel-btn');
    const searchInput = $('#search-input');
    var indexHide = 0;
    const resultSearch = videoTabContents.find('#result-search');
    const contentResults = videoTabContents.find('.video-tabs-contents-result');
    VIDEOS_SEARCH_COMPONENT.onInit(VIDEOS_SEARCH_GRID_DROP_OPTIONS)
    searchInput.hide();
    searchPanel.hide();
    cancelBtn.click(function () {
        contentResults.hide();
        cancelBtn.hide();
        searchInput.hide("slow");
        searchPanel.hide("slow");
        videoTabContainer.find('[role*="video-tab"]').toArray().forEach(item => $(item).show());
        videoTabContents.find('[role*="video-tab-panel"]').toArray().forEach((item, i) => {
                if (indexHide === i) {
                    $(item).show()
                }
            }
        );
    })
    searchBtn.hover(function () {
        contentResults.show();
        cancelBtn.show();
        searchInput.show("slow");
        searchPanel.show("slow");
        videoTabContainer.find('[role*="video-tab"]').toArray().forEach(item => $(item).hide());
        videoTabContents.find('[role*="video-tab-panel"]').toArray().forEach((item, i) => {
            if ($(item).css("display") === "block") {
                indexHide = i;
            }
            $(item).hide()
        });
    })

    function isEmptySearchInput() {
        const s = searchInput.val();
        return s === "" || s.trim().length === 0;
    }

    let isRendering = false;
    let currentPage = 1;
    async function loadMore(searchText) {
        showLoading(
            $(resultSearch),
            loadingSize.MEDIUM,
            loadingPosition.CENTER
        );
        await renderContent(searchText, currentPage, true);
        hideLoading(
            $(resultSearch),
            loadingSize.MEDIUM,
            loadingPosition.CENTER
        );
        const videosGridSearch = videoTabContents.find('#videos-grid-search');

        $(window).scroll(async function () {
            if (
                (!resultSearch ||
                    (resultSearch && $(resultSearch).css("display") === "block")) &&
                !isRendering &&
                $(document).scrollTop() + $(window).height() >=
                $(videosGridSearch).offset().top + $(videosGridSearch).height()
            ) {
                currentPage = currentPage + 1;

                showLoading(
                    $(resultSearch),
                    loadingSize.MEDIUM,
                    loadingPosition.BOTTOM
                );
                await renderContent(searchText, currentPage);
                hideLoading(
                    $(resultSearch),
                    loadingSize.MEDIUM,
                    loadingPosition.BOTTOM
                );
            }
        });
    }

    async function renderContent(searchText, currentPage, isCallFirst) {
        isRendering = true;
        const categoryType = searchOption.find(':selected').val();
        const res = await searchVideo(baseUrl, apiKey, categoryType, {
            page_number: currentPage,
            page_size: VIDEOS_SEARCH_GRID_DROP_OPTIONS.pageSize,
            //title__icontains: searchText
            // description__icontains: searchText,
            search_texts: searchText
        });
        if (!res) {
            return;
        }
        const videosGridSearch = videoTabContents.find('#videos-grid-search');
        res.result.forEach(item => {
            const itemTemp = VIDEOS_SEARCH_COMPONENT.componentsGridDropItem(VIDEOS_SEARCH_GRID_DROP_OPTIONS, {image: item.thumbnail, categoryType})
            const contentTemp = VIDEOS_SEARCH_COMPONENT.componentsGridDropContent(VIDEOS_SEARCH_GRID_DROP_OPTIONS, {
                id: item.id,
                image: item.thumbnail,
                title: item.title,
                desc: item.description
            })
            videosGridSearch.append(itemTemp)
            videosGridSearch.append(contentTemp)
        })
        const {total_page} = res;
        if (total_page > currentPage) {
            isRendering = false;
        }
        if (isCallFirst) {
            resultSearch.prepend(`<div class="block-result__header">
             <h5>Found <strong>${total_page === 1 ? res.result.length : total_page * VIDEOS_SEARCH_GRID_DROP_OPTIONS.pageSize || 0}</strong> results for keyword <strong>'${searchText}'</strong></h5>
        </div>`);
        }
        VIDEOS_SEARCH_COMPONENT.script(VIDEOS_SEARCH_GRID_DROP_OPTIONS);
    }

    searchBtn.click(async function () {
        const s = searchInput.val();
        if (isEmptySearchInput()) {
            return;
        }
        videoTabContents.find('[role*="video-tab-panel"]').toArray().forEach(item => $(item).hide());
        resultSearch.show();
        resultSearch.html(``);
        resultSearch.append(`<div id="videos-grid-search" data-gjs-type="videos-grid" class="videos-grid">
         </div>
        `);
        currentPage = 1;
        await loadMore(s)
    })
};
