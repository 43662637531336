import {
  hideLoading,
  loadingPosition,
  loadingSize,
  showLoading,
} from "../Loading";
import { formatUrl } from "../../utils/domHelper";
import {
  getCategoriesList,
  CATEGORY_TYPE,
  getCategoriesAOD,
} from "../../services/categoryService";
import { CategorySliderType, createSlider } from "../Slider";

export const getAudioCategoriesSlider = async (
  container,
  { generalSettings: settings, apiKey },
  baseUrl,
  template_uuid
) => {
  let categoriesSliders;
  if (container) {
    categoriesSliders = $(container).find(`.audio-categories-slider`).toArray();
  } else {
    categoriesSliders = $(`.audio-categories-slider`)
      .toArray()
      .filter((item) => {
        return $(item).parent(".video-tab-content").length === 0;
      });
  }
  for (const slider of categoriesSliders) {
    const categoryItemClone = $(slider)
      .find(".audio-categories-slider-item")
      .first()
      .clone();
    const categoryContentClone = $(slider)
      .find(".audio-categories-slider-content")
      .first()
      .clone();
      const sliderContainerClone = $(categoryContentClone)
      .find(".audio-categories-slider-content-slider-container")
      .first().clone();
    const videoCategorySliderClone = $(sliderContainerClone)
    .find(`[role=sliderwrapper]`)
    .first()
    .clone();

    let currentPage = 1;
    let isRendering = false;
    $(slider).empty();
    $(categoryContentClone).empty();
    $(sliderContainerClone).empty();
    const renderDynamicAudioCategory = async function (pageIndex) {
      isRendering = true;
      // Fetch on Demand categories
      const categories = await getCategoriesAOD(
        baseUrl,
        apiKey,
        settings.tenantId,
        {
          type: CATEGORY_TYPE.ON_DEMAND,
          page: pageIndex,
          page_size: 3,
          has_audio: true,
        }
      );

      categories?.results?.forEach(async (category) => {
        const categoryItemNew = categoryItemClone.clone();
        $(categoryItemNew).html(category.title);
        const categoryContentNew = categoryContentClone.clone();
        const sliderContainerNew = sliderContainerClone.clone();
        const videoCategorySlider = videoCategorySliderClone
        .clone().attr("categoryslidertype", CategorySliderType.Audio)
        .attr("videocategory", category.id).attr("haslist", true);;
        const sliderItem = await createSlider(videoCategorySlider, {
          settings,
          apiKey,
          baseUrl,
          template_uuid,
        }, {results: category?.audios || []});
        $(sliderContainerNew).append(sliderItem);
        $(categoryContentNew).append(sliderContainerNew);
        $(slider).append(categoryItemNew);
        $(slider).append(categoryContentNew);
      });
      if (categories.total / 3 > currentPage) {
        isRendering = false;
      }
    };

    showLoading($(slider), loadingSize.MEDIUM, loadingPosition.CENTER);
    await renderDynamicAudioCategory(currentPage);
    hideLoading($(slider), loadingSize.MEDIUM, loadingPosition.CENTER);

    $(window).scroll(async function () {
      if (
        (!container ||
          (container && $(container).css("display") === "block")) &&
        !isRendering &&
        $(document).scrollTop() + $(window).height() >=
          $(slider).offset().top + $(slider).height()
      ) {
        currentPage = currentPage + 1;
        showLoading($(slider), loadingSize.MEDIUM, loadingPosition.BOTTOM);
        await renderDynamicAudioCategory(currentPage);
        hideLoading($(slider), loadingSize.MEDIUM, loadingPosition.BOTTOM);
      }
    });
  }
};
