import { convertTime } from "../../utils/formatter";
import { OnErrorImg } from "../../constants/Media";

export const createVideoNextContent = (video) => {
  return `
    <div class="next-video-thumbnail-container">
        <img src="${
          video.thumbnail
        }" alt="thumbnail next video" class="next-video-thumbnail" onerror="${OnErrorImg}"/>
    </div>
    <div class="next-video-content">
        <h3 class="next-video-title">${video.title}</h3>
        <p class="next-video-time">${convertTime(Number(video.duration))}</p>
    </div>
    `;
};
