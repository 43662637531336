import { currencyFormatter, dateFormatter, hexToRgbA } from "../../utils/formatter";

export const createCardSix = (plan) => {
  
  let currentColor = plan.membershipColor;
  if (!currentColor.includes('rgb')) {
    currentColor = hexToRgbA(plan.membershipColor);
  }
  const packageColor = 'rgba' + currentColor.slice(3, currentColor.length - 1).concat(',0.95)');

  return `
    <div class="subscription-card-six">
      <div class="subscription-card-six-top" style="background-color: ${packageColor}">
      </div>
      <div class="subscription-card-six-header" style="background-color: ${packageColor}">
        <div class="subscription-card-six-title" style="background-color: ${plan.membershipColor}">
          ${plan.title}
        </div>
        <div class="subscription-card-six-info" style="background-color: ${packageColor}">
          <div class="subscription-card-six-price text-weight-bold" style="display: ${plan.isDiscount ? 'block' : 'none'}">
            ${currencyFormatter.format(plan.discountedPriceValue)}
          </div>
          <div class="subscription-card-six-text-discount text-weight-bold ${plan.isDiscount ? 'per-month' : ''}">
            ${currencyFormatter.format(plan.regularPriceInput)} / ${plan.regularPriceSelect}
          </div>
          <div class="text-des subscription-card-six-discount-info" style="display: ${plan.isDiscount ? 'block' : 'none'}">
            Valid until <span class="text-weight-bold">${dateFormatter(plan.discountFor)}</span>
          </div>
          <div class="subscription-card-six-bottom-polygon" style="background-color: ${packageColor}">
          </div>
        </div>
      </div>
      <div class="subscription-card-six-content">
        <div class="text-weight-bold subscription-card-six-num" style="display: ${plan.isFree ? 'block' : 'none'}">
          ${plan.trialPeriodInput}
        </div>
        <div class="text-weight-bold subscription-card-six-free-trial" style="display: ${plan.isFree ? 'block' : 'none'}">
          ${plan.trialPeriodSelect} Free Trial
        </div>
        <div class="subscription-card-six-text-des">
          ${plan.description}
        </div>
      </div>
      <div class="subscription-card-six-actions">
        <a class="subscription-card-six-button subscription-card-btn-signup" style="background-color: ${plan.btnAddColor}">SIGN UP</a>
      </div>
    </div>
  `
}