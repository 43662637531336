import { PlaybackControl } from "./PlaybackControl"

export class FastForward extends PlaybackControl {

  get name() { return 'fast_forward' }
  get attributes() { return { class: 'fast-forward' } }

  handleClickEvent() {
    this.core.getCurrentPlayback().seek(this.core.getCurrentPlayback().getCurrentTime() + 10);
  }

  renderControl() {
    this.$el.append(Clappr.Styler.getStyleFor(`
      .fast-forward { 
        position: static;
        float: left;
        z-index: 999;
        height: 32px;
        width: 28px;
        cursor: pointer;
        opacity: 0.8;
        transform: scale(0.8);
        background: url("data:image/svg+xml,%3Csvg width='28' height='32' viewBox='0 0 28 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8672 4.43981V0L22.5339 6.4H13.9126V6.40111C7.47254 6.48838 2.27894 11.7076 2.27894 18.1333C2.27894 24.6134 7.56096 29.8667 14.0767 29.8667C19.8598 29.8667 24.6712 25.7282 25.6799 20.2667H27.8555C26.8237 26.9122 21.0476 32 14.0767 32C6.37622 32 0.133869 25.7917 0.133869 18.1333C0.133869 11.2227 5.21675 5.49288 11.8672 4.43981Z' fill='white'/%3E%3Cpath d='M11.3913 22.4V14.1867H8.07079L7.68359 15.8528H9.32626V22.4H11.3913ZM17.1983 22.5291C15.098 22.5291 13.8543 20.8277 13.8543 18.2933C13.8543 15.7472 15.098 14.0576 17.1983 14.0576C19.2868 14.0576 20.5071 15.7472 20.5071 18.2933C20.5071 20.8277 19.2868 22.5291 17.1983 22.5291ZM17.1983 20.7573C17.9492 20.7573 18.442 19.9595 18.442 18.2933C18.442 16.6389 17.9492 15.8293 17.1983 15.8293C16.4473 15.8293 15.9428 16.6389 15.9428 18.2933C15.9428 19.9595 16.4473 20.7573 17.1983 20.7573Z' fill='white'/%3E%3C/svg%3E%0A") no-repeat center center;
      }

    `))
    this.core.mediaControl && $(this.el).insertBefore(this.core.mediaControl.$('.media-control-indicator:first'));
    return this;
  }
}
