export function isMobile() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}


export function durationText(text){
  let durationText = text.trim();
  let durationParts = durationText.split(' ');
  let unit = durationParts[1].trim();
  let singularUnit = unit.slice(0, unit.length - 1);
  singularUnit = singularUnit.charAt(0).toUpperCase() + singularUnit.slice(1)
  unit = unit.charAt(0).toUpperCase() + unit.slice(1)
  durationText = durationParts[0] + ' ' + (parseInt(durationParts[0]) > 1 ? unit : singularUnit);
  return durationText
}

export function durationUnit(text){
  let durationText = text.trim();
  let durationParts = durationText.split(' ');
  let unit = durationParts[1].trim();
  let singularUnit = unit.slice(0, unit.length - 1);
  return singularUnit.charAt(0).toUpperCase() + singularUnit.slice(1);
}