import { buildHeader, buildQueryParam } from "./baseService";
import { getJson } from "../utils/httpHelper";
import {CATEGORY_TYPE_OF_LIST} from "../constants/Category";

export const getAudiosByCategory = async (baseUrl, apiKey, tenantId, categoryId, params) => {
  const api = await fetch(buildQueryParam(`${baseUrl}/clients/aod-category/${categoryId}/audios/`, params), {
    headers: buildHeader(apiKey),
  });
  return getJson(api);
}

export const getAudioById = async (baseUrl, apiKey, uuid) => {
  const api = await fetch(`${baseUrl}/media/single/${CATEGORY_TYPE_OF_LIST.Audio}/${uuid}/`, {
      headers: buildHeader(apiKey),
  });
  return getJson(api, {ignoreErrorTracing: true});
}
