import {appendStyle, formatUrl} from "../../utils/domHelper";
import {OnErrorImg,OnAudioErrorImg} from "../../constants/Media";

export const VIDEOS_SEARCH_GRID_DROP_OPTIONS = {
  typeGridDrop: 'videos-grid',
  typeGridDropItem: 'videos-grid-item',
  typeGridDropContent: 'videos-grid-content',
  classGridDrop: 'videos-grid',
  classGridDropItem: 'videos-grid-item',
  classGridDropContent: 'videos-grid-content',
  pageSize: 12,
}
export const VIDEOS_SEARCH_COMPONENT  = {
  onInit :(config)=>{
    appendStyle(`${VIDEOS_SEARCH_COMPONENT.styleGridDropContent(VIDEOS_SEARCH_GRID_DROP_OPTIONS)}`);
    appendStyle(`${VIDEOS_SEARCH_COMPONENT.styleGridDropItem(VIDEOS_SEARCH_GRID_DROP_OPTIONS)}`);
  },
  componentsGridDropItem: (config, {image, categoryType}) => [
    `
 <div data-gjs-type="videos-grid-item" class="videos-grid-item active">
   <img width="100%" src="${image}" onerror="${categoryType == 1 ? OnAudioErrorImg : OnErrorImg}" alt="IMAGE VIDEO">
       <div class="bottom-arrow"></div>
    </div> 
`,
  ],
  componentsGridDropContent: (config, data) => [
    `    <div class="${config.classGridDropContent}">
          <img class="${config.classGridDropContent}-img" src="${data.image}" onerror="${OnErrorImg}">
          <div class="${config.classGridDropContent}-bg"></div>
          <div class="${config.classGridDropContent}-content">
            <div class="${config.classGridDropContent}-title">${data.title}</div>
            <div class="${config.classGridDropContent}-description">${data.desc}</div>
            <div class="${config.classGridDropContent}-watch-btn">
              <button class="bannerbtn ${config.classGridDropContent}-bannersubnowbtn" role="button" data-id="${data.id}">WATCH NOW</button>
            </div>
          </div>
        </div>`,
  ],
  script: function (config) {
    const { classGridDropContent, classGridDropItem } = config;
    const el = document;
    const each = (items, clb) => {
      const arr = items || [];
      for (let i = 0; i < arr.length; i++) clb(arr[i], i);
    };
    const getGridItems = () => el.querySelectorAll(`.${classGridDropItem}`);
    each(getGridItems(), (item) => {
      $(item).click(() => {
        // remove all active
        $(`.${classGridDropItem}`).removeClass('active');
        $(item).addClass('active');
        $(`.${classGridDropItem}`).find('.bottom-arrow').removeClass('arrow-active');
        $(item).find('.bottom-arrow').addClass('arrow-active');
        $(`.${classGridDropContent}`).find("[role=button]").on("click", function () {
          const id = $(this).attr('data-id')
          const playUrl = `/watch-movies?videoId=${id}`;
          window.location = formatUrl(playUrl);
        });
        $(`.${classGridDropContent}`).not($(item).next()).hide();
        $(item).next().slideDown(500);
      });
    });
  },
  styleGridDropItem: (config) => `
    .${config.typeGridDrop}  {
      display: grid;
      grid-auto-flow: dense;
      grid-template-columns: repeat(auto-fit, calc(25%));
      margin-top: 0px;
      margin-right: 8px;
      margin-bottom: 0px;
      margin-left: 8px;
      color: white;
      }
      .${config.classGridDropItem} {
        margin-top: 8px;
        margin-right: 8px;
        margin-bottom: 0px;
        margin-left: 8px;
        position: relative;
      }
      .${config.classGridDropItem}.active {
      }
    `,
  styleGridDropContent: (config) => `
      .${config.classGridDropContent}-item {
          height: 100%;
          width: 100%;
      }
      .${config.classGridDropContent} {
        grid-column: 1 / -1;
        margin: 0px -8px;
        display: none;
        position: relative;
      }
      .${config.classGridDropContent}-bg {
        top: 0px;
        width: 50%;
        height: 100%;
        position: absolute;
        background: #1d1c1c;
        opacity: 0.7;
      }
       .${config.classGridDropContent}-bg:after {
          left: calc(100%);
          width: 35%;
          height: 100%;
          content: "";
          display: block;
          position: relative;
          background-image: linear-gradient(90deg, rgb(21, 21, 21) 0px, transparent);
      }

      .${config.classGridDropContent}-content {
          color: white;
          width: 48%;
          z-index: 1000;
          position: absolute;
          left: 10px;
          top: 40px;
          padding: 0px 8px;
      }
      .${config.classGridDropContent}-title {
        animation: enterContentRight 0.6s ease forwards;
        font: 400 40px/1 Helvetica Neue, Helvetica, Arial, sans-serif;
        text-shadow: 0 1px 10px #000;
        font-size: 20px;
      }

      .${config.classGridDropContent}-sub-title {
        padding-top: 5px;
        font: 500 14px/20px Helvetica Neue, Helvetica, Arial, sans-serif;
        text-shadow: 0 1px 10px #000;
      }
      .${config.classGridDropContent}-description {
        font: 14px/20px Helvetica Neue, Helvetica, Arial, sans-serif;
        padding-top: 8px;
        padding-bottom: 8px;
        text-shadow: 0 1px 10px #000;
        height: 100px;
        white-space: pre-line;
        text-overflow: ellipsis;
        overflow-x: hidden;
        overflow-y: auto;
        line-height: 22px;
      }
      .${config.classGridDropContent}-watch-btn {
        padding-top: 20px;
        display: inline-block;
      }

      .${config.classGridDropContent}-bannerbtn {
        display: inline-block;
        text-decoration: none;
        color: white;
        padding: 14px 40px;
        border-radius: 4px;
        transition: 0.3s;
      }

      .${config.classGridDropContent}-bannersubnowbtn:hover {
        background: transparent;
        color: #fff;
      }
      .${config.classGridDropContent}-img {
        float: right;
        width: 50%;
        height: inherit;
        margin-top: 0px;
        text-align: right;
        padding: 24px;
      }

      .${config.classGridDropContent}-bannersubnowbtn {
        font-size: 21px;
        line-height: 16.24px;
        font-weight: 400;
        padding-top: 16px;
        padding-right: 40px;
        padding-bottom: 16px;
        padding-left: 40px;
        background: #ff1200;
        transition: 0.5s;
        color: white;
      }
      .${config.classGridDropContent}-bannersubnowbtn:hover {
        background: transparent;
        color: #fff;
      }
     .bottom-arrow::after {
        content: "";
        position: absolute;
        left: 0px;
        right: 0px;
        margin-top: 2px;
        margin-right: auto;
        margin-bottom: 0px;
        margin-left: auto;
        width: 0px;
        height: 0px;
        border-top-width: 14px;
        border-top-style: solid;
        border-top-color: white;
        border-left-width: 14px;
        border-left-style: solid;
        border-left-color: transparent;
        border-right-width: 14px;
        border-right-style: solid;
        border-right-color: transparent;
        z-index: 2;
     }

    .bottom-arrow {
        border-bottom: 4px solid white;
        display: none;
        z-index: 2;
    }
    .arrow-active {
        display: block;
    }

    @media screen and (max-width: 768px) {
      .${config.classGridDropContent}-content{
        top: 35%;
        width: 100%;
      }

      .${config.classGridDropContent}-img {
        width: 100%;
      }

      .${config.classGridDropContent}-bg {
        width: 100%;
      }
    }

    @media screen and (max-width: 500px) {
      .${config.classGridDropContent}-content{
        top: 35%;
        width: 100%;
      }

      .${config.classGridDropContent}-img {
        width: 100%;
      }

      .${config.classGridDropContent}-bg {
        width: 100%;
      }
    }
    `,
};
