import { login } from "../../../services/authService";
import { redirectTo } from "../../../utils/httpHelper";

export default async function (baseUrl, tenantId, cb) {
  const { email, username, password } = cb._data;
  const resJson = await login(baseUrl, { email, username, password, tenantId });
  if (resJson.status === "F") {
    cb.emit("submitDone", "");
    return;
  }
  cb.emit("submitDone", "");
  localStorage.setItem("loginToken", JSON.stringify(resJson.message));
  redirectTo();
}
