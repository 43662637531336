export const dateFormatter = (date) => {
  if (!date || date === 'None' ) { return '' }
  const fDate = new Date(date);
  const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(fDate);
  const m = new Intl.DateTimeFormat('en', { month: 'short' }).format(fDate);
  const d = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(fDate);
  return `${m} ${d}, ${y}`
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const hexToRgbA = (hex) => {
  try {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgb(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255] + ')';
    }
  } catch (error) {
    return hex;
  }
}


export const convertTime = (sec) => {
  var hours = Math.floor(sec / 3600);
  (hours >= 1) ? sec = sec - (hours * 3600) : hours = '00';
  var min = Math.floor(sec / 60);
  (min >= 1) ? sec = sec - (min * 60) : min = '00';
  (sec < 1) ? sec = '00' : void 0;

  sec = Math.ceil(sec);
  (min.toString().length == 1) ? min = '0' + min : void 0;
  ((sec).toString().length == 1) ? sec = '0' + sec : void 0;

  return hours + ':' + min + ':' +  sec;
}



export const convertMilliseconds = (miliseconds, format) => {
  let days, hours, minutes, seconds, total_hours, total_minutes, total_seconds;

  total_seconds = parseInt(Math.floor(miliseconds / 1000));
  total_minutes = parseInt(Math.floor(total_seconds / 60));
  total_hours = parseInt(Math.floor(total_minutes / 60));
  days = parseInt(Math.floor(total_hours / 24));

  seconds = parseInt(total_seconds % 60);
  minutes = parseInt(total_minutes % 60);
  hours = parseInt(total_hours % 24);

  switch (format) {
      case 's':
          return total_seconds;
      case 'm':
          return total_minutes;
      case 'h':
          return total_hours;
      case 'd':
          return days;
      default:
          return {d: days, h: hours, m: minutes, s: seconds};
  }
};