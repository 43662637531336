import { currencyFormatter, dateFormatter, hexToRgbA } from "../../utils/formatter";

export const createCardThree = (plan) => {
  
  let currentColor = plan.membershipColor;
  if (!currentColor.includes('rgb')) {
    currentColor = hexToRgbA(plan.membershipColor);
  }
  const packageColor = 'rgba' + currentColor.slice(3, currentColor.length - 1).concat(',0.95)');

  return `
    <div class="subscription-card-three">
      <div class="subscription-card-three-header">
        <div class="subscription-card-three-polygon-left" style="background-color: ${plan.membershipColor}">
          <span class="subscription-card-three-title">${plan.title}</span>
        </div>
        <div class="subscription-card-three-polygon-right" style="background-color: ${packageColor}">
          <div class="subscription-card-three-polygon-right-content">
            <div class="subscription-card-three-price text-weight-bold" style="display: ${plan.isDiscount ? 'block' : 'none'}">
              ${currencyFormatter.format(plan.discountedPriceValue)}
            </div>
            <div class="subscription-card-three-text-discount text-weight-bold ${plan.isDiscount ? 'per-month' : ''}" style="display: ${plan.isDiscount ? 'block' : 'none'}">
              ${currencyFormatter.format(plan.regularPriceInput)} / ${plan.regularPriceSelect}
            </div>
            <div class="text-des subscription-card-three-discount-info" style="display: ${plan.isDiscount ? 'block' : 'none'}">
              Valid until <span class="text-weight-bold">${dateFormatter(plan.discountFor)}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="subscription-card-three-content">
        <div class="text-weight-bold subscription-card-three-num" style="display: ${plan.isFree ? 'block' : 'none'}">
          ${plan.trialPeriodInput}
        </div>
        <div class="text-weight-bold subscription-card-three-free-trial" style="display: ${plan.isFree ? 'block' : 'none'}">
          ${plan.trialPeriodSelect} Free Trial
        </div>
        <div class="subscription-card-three-text-des">
          ${plan.description}
        </div>
      </div>
      <div class="subscription-card-three-actions">
        <a class="subscription-card-three-button subscription-card-btn-purchase" style="background-color: ${plan.btnAddColor}; border-color: ${plan.btnAddColor}">PURCHASE</a>
      </div>
    </div>
  `
}