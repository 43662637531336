import { OnErrorImg, NotFoundResource } from "../../../constants/Media";
import { SliderType } from ".";
export const createChannelCarouselSlider = async (
  slideContainer,
  slide,
  { settings, apiKey, baseUrl },
  channels,
  type
) => {

  channels.results.forEach((channel) => {
    const slideClone = slide.clone();
    $(slideClone).attr("data-id", channel.id);
    $(slideClone).addClass("channel-slide-item");
    $(slideClone)
      .find("[role=slideimage]")
      .attr("src", channel.thumbnail)
      .attr("onError", OnErrorImg);
    $(slideClone).find("[role=slidetitle]").html(channel.title);
    $(slideClone)
      .find("[role=slidedescription]")
      .html(channel.short_description);

    if (type === SliderType.CHANNEL_CAROUSEL_SLIDER_2) {
      $(slideClone)
        .find("[role=slidebackground]")
        .css(
          "background-image",
          `url('${channel.thumbnail || NotFoundResource}')`
        );
    }

    $(slideContainer).append(slideClone);
  });
};
