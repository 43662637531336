import { getCategories } from "../Categories";
import { getCategorySeriesGrid } from "../CategorySeriesGrid";
import { getVODCategoriesSlider } from "../VODCategoriesSlider";
import { getVODCategories } from "../VODCategories";
import { getVODCategorySeries } from "../VODCategorySeries";
import { getAudioCategories } from "../AudioCategories";
import { getAudioCategoriesSlider } from "../AudioCategoriesSlider";
import { getAODCategorySeries } from "../AudioCategorySeries";

export const getVideoTabs = async (settings, baseUrl, template_uuid) => {
  const videoTabs = $(".video-tabs");
  videoTabs.css({ cursor: "pointer" });
  const runScriptTabContent = (element) => {
    getCategories(element, settings, baseUrl, template_uuid);
    getVODCategories(element, settings, baseUrl, template_uuid);
    getVODCategoriesSlider(element, settings, baseUrl, template_uuid);
    getCategorySeriesGrid(element, settings, baseUrl, template_uuid);
    getVODCategorySeries(element, settings, baseUrl, template_uuid);
    getAudioCategories(element, settings, baseUrl, template_uuid);
    getAudioCategoriesSlider(element, settings, baseUrl, template_uuid);
    getAODCategorySeries(element, settings, baseUrl, template_uuid);
  };
  if (videoTabs.length > 0) {
    videoTabs.show();
    const videoTabList = $("[role='video-tab']").toArray();
    videoTabList.forEach((element) => {
      if ($(element).hasClass("video-tab-active")) {
        const tabContentId = $(element).attr("aria-controls");
        runScriptTabContent($(`#${tabContentId}`));
      } else {
        $(element).on("click", async () => {
          const contentId = $(element).attr("aria-controls");
          runScriptTabContent($(`#${contentId}`));
          $(element).off();
        });
      }
    });
  }
};
