import { convertMilliseconds } from "../../utils/formatter";

export default class Tracking {
  constructor(config, sendReport, buildRq) {
    this.sendReport = sendReport;
    this.buildRq = buildRq;
    this.config = config;
  }

  init(data) {
    this.rp_data = data;
  }

  resetStatus(status) {
    this.rp_data.reportStatus = status;
  }

  async send(eventName, data) {
    if (eventName === "End") {
      this.rp_data.reportStatus.send.start = false;
      this.rp_data.reportStatus.send.end = true;
      if (data.watch === 0) {
        return;
      }
      return this.sendReport(this.config, eventName, data);
    } else {
      const rs = await this.sendReport(this.config, eventName, data);
      if (eventName === "Start") {
        const { uuid } = rs;
        this.rp_data.currentPlaying.uuid = uuid;
      }
      return rs;
    }
  }

  setRpData(data) {
    this.rp_data = data;
  }

  getCurrent() {
    return this.rp_data;
  }

  getRqStart() {
    return this.buildRq(this.rp_data);
  }

  getRqEnd(metrics) {
    const model = this.getReportFromMetrics(metrics);
    return {
      ...model,
      uuid: this.rp_data.currentPlaying.uuid,
    };
  }

  getReportFromMetrics = (metrics) => {
    return {
      watch: convertMilliseconds(metrics?.timers?.watch || 0, "s"),
    };
  };
}
