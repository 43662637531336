import { OnErrorImg, OnTimeItemErrorImg } from "../../constants/Media";
import { getPPVEvents } from "../../services/ticketService";
import { durationToSecond } from "../../utils/streamHelper";

const calculateTimeItemWidth = (item, playingTime, i) => {
  const w_1m = 20;
  const w_tn = i === 0 ? 150 : 0;
  const minutes = Math.floor(durationToSecond(item.duration) / 60);
  return w_tn + w_1m * (minutes < 0 ? 0 : minutes);
};

const renderTimeInfo = (item, w, i, pDuration, playingTime) => {
  const itemMins = Math.floor(durationToSecond(item.duration) / 60);
  const startTimeline = moment(new Date()).add(
    pDuration - playingTime,
    "minutes"
  );
  const endTimeline = moment(new Date()).add(
    itemMins + pDuration - playingTime,
    "minutes"
  );
  const timeline = `${startTimeline.format("LT")} - ${endTimeline.format(
    "LT"
  )}`;
  if ((w >= 280 && i === 0) || (w >= 100 && i !== 0)) {
    return `<p>${item.title}</p>
    <p>${timeline}</p>`;
  }
  return timeline;
};

const renderPlaylists = async (
  channel,
  isSelectedChannel,
  { playingTime, position },
  ppvEvents
) => {
  if (channel.stream_mode === "EXTERNAL") {
    return `
        <div class="external">
            <p>${channel.title}</p>
            <p>${channel.short_description}</p>
        </div>
    `;
  } else {
    let timeItems = ``;
    // if (!isSelectedChannel) {
    //   const { uuid } = playlists.filter((pl) => pl.status === "NOWPLAYING")[0];
    //   plDetails = await getPlaylistById(baseUrl, apiKey, uuid);
    // }
    const { videos_order } = channel.playlists.filter(
      (pl) => pl.status === "NOWPLAYING"
    )[0] || {};
    if (!position || !isSelectedChannel) {
      position = channel.position;
    }
    let durationTotal = 0;
    let videos = videos_order || [];
    let historyDuration = 0;
    //   if (!isSelectedChannel) {
    //   playingTime = channel.current_playing_time;
    //   }

    const filterData = filterTimelineVideos(
      {
        videos,
      },
      position
    );
    videos = [
      ...ppvEvents,
      ...filterData.map((item, i) => {
        durationTotal =
          i > 0
            ? durationTotal + Math.floor(durationToSecond(item.duration) / 60)
            : historyDuration;
        return {
          ...item,
          startTimeline: moment(new Date()).add(
            durationTotal - playingTime,
            "minutes"
          ),
        };
      }),
    ]
      .sort((a, b) => {
        durationTotal = 0;
        return moment(a.startTimeline).diff(b.startTimeline, "minutes");
      })
      .map((item, i) => {
        if (item.type !== "PPV") {
          const w = calculateTimeItemWidth(item, playingTime / 60, i);
          const timeline = renderTimeInfo(
            item,
            w,
            i,
            durationTotal,
            playingTime / 60
          );
          durationTotal =
            durationTotal + Math.floor(durationToSecond(item.duration) / 60);
          return {
            ...item,
            timeline,
            w,
          };
        }
        return item;
      });
    videos.forEach((v, i) => {
      if (i === 0 && isSelectedChannel && v.type !== "PPV") {
        $(".streaming-timeline-title")
          .after(`<div class="streaming-timeline-video"> 
                  <img class="streaming-timeline-video-poster" src="${v.thumbnail}" onError="${OnTimeItemErrorImg}" />
                  <div class="streaming-timeline-video-content">
                    <img  class="streaming-timeline-video-thumbnail" src="${v.thumbnail}" onError="${OnTimeItemErrorImg}" /> 
                    <div class="streaming-timeline-video-info" >
                      <p class="video-title">${v.title}</p>
                      <p class="video-description">${v.description}</p>
                    </div>
                  </div>
                
                </div>`);
      }
      if ((v.w < 100 && i > 0) || (v.w < 280 && i == 0)) {
        timeItems += `<div title="${
          v.title
        }" data-toggle="popover" data-toggle="popover" data-trigger="hover" data-content="${
          v.timeline
        }" class="time-item ${
          i === 0 && isSelectedChannel && v.type !== "PPV"
            ? "now"
            : i === 0 && v.type !== "PPV"
            ? "first"
            : v.type === "PPV"
            ? "ppv"
            : ""
        }" style="width: ${v.w}px">
            ${
              i === 0 && v.type !== "PPV"
                ? `<img src="${v.thumbnail}" onError="${OnTimeItemErrorImg}" /> <div class="time-info"><p>${v.title}</p> <p>${v.timeline}</p> </div>`
                : `<div class="time-info"><p>${v.title}</p> <p>${v.timeline}</p> </div>`
            }
            </div>`;
      } else {
        timeItems += `
            <div class="time-item ${
              i === 0 && isSelectedChannel && v.type !== "PPV"
                ? "now"
                : i === 0 && v.type !== "PPV"
                ? "first"
                : v.type === "PPV"
                ? "ppv"
                : ""
            }" style="width: ${v.w}px">
                ${
                  i === 0 && v.type !== "PPV"
                    ? `<img src="${v.thumbnail}" onError="${OnTimeItemErrorImg}" />`
                    : ``
                }
                <div class="time-info">${v.timeline}</div>
            </div>`;
      }
    });
    return `<div class="internal ${
      isSelectedChannel ? "now" : ""
    }">${timeItems}</div>`;
  }
};

export const filterTimelineVideos = ({ videos }, playingPosition) => {
  let tmpVideos = videos.filter((v) => v.sort_order >= playingPosition);
  tmpVideos = tmpVideos.map((v) => v.video);
  return tmpVideos;
};

export const renderStreamTimelineContent = async (
  playingChannel,
  channels,
  slider,
  { playingTime, position },
  { baseUrl, apiKey }
) => {
  const timeline = $(".stream-channel-timeline");
  const localTime = $(timeline).find(".local-time");
  const timelineContent = $(".stream-timeline-content");
  timelineContent.empty();
  localTime.html(moment(new Date()).format("lll"));
  if (!window.liveLocalTime) {
    window.liveLocalTime = setInterval(() => {
      localTime.html(moment(new Date()).format("lll"));
    }, 1000);
    $(timelineContent).empty();
  }
  if (!playingTime) {
    playingTime = 0;
  }

  let content = "";
  $(".streaming-timeline-title").hide();
  if (
    $(".streaming-timeline-title").next().hasClass("streaming-timeline-video")
  ) {
    $(".streaming-timeline-title").next().remove();
  }
  // const ppvEventData = await getPPVEvents(baseUrl, apiKey, {});
  // // render timeline content
  // for (let i = 0; i < channels.results.length; i++) {
  //   const channel = channels.results[i];
  //   const ppvEvents = ppvEventData
  //     .filter((ppvEvent) => {
  //       const today = moment();
  //       const eventDatesEnd = moment(ppvEvent.event_dates[2]);
  //       return ppvEvent.channel_id === channel.id && eventDatesEnd >= today;
  //     })
  //     .map((item) => {
  //       const today = moment();
  //       const eventDatesStart = moment(item.event_dates[1]);
  //       const eventDatesEnd = moment(item.event_dates[2]);
  //       const startTimeline = today.isSame(eventDatesStart, "day")
  //         ? eventDatesStart
  //         : moment().startOf("day");
  //       const endTimeline = today.isSame(eventDatesEnd, "day")
  //         ? eventDatesEnd
  //         : moment().endOf("day");
  //       const widthPPVEntd = Math.floor(
  //         ((endTimeline - startTimeline) / 60000) * 20
  //       );
  //       let timeline = `${startTimeline.format("LT")} - ${endTimeline.format(
  //         "LT"
  //       )}`;
  //       if (
  //         (widthPPVEntd >= 280 && i === 0) ||
  //         (widthPPVEntd >= 100 && i !== 0)
  //       ) {
  //         timeline = `<p>${item.name}</p>
  //         <p>${timeline}</p>`;
  //       }
  //       return {
  //         ...item,
  //         title: item.name,
  //         startTimeline: startTimeline,
  //         timeline,
  //         type: "PPV",
  //         w: widthPPVEntd,
  //       };
  //     });
  //   const isSelectedChannel = channel.id === playingChannel.id;
  //   const isExternal = channel.stream_mode === "EXTERNAL";
  //   content += `<div class="channel-timeline-list  ${
  //     isExternal && isSelectedChannel ? "now" : ""
  //   }">
  //           <div class="channel-timeline">
  //             <img src="${
  //               channel.thumbnail
  //             }" onError="${OnErrorImg}" data-id="${
  //     channel.id
  //   }" class="channel-timeline-thumbnail"  data-index="${i}" />
  //             <div  class="channel-timeline-description">${
  //               channel.short_description
  //             }</div>
  //           </div>
  //           <div class="timeline-content">
  //               ${await renderPlaylists(
  //                 channel,
  //                 isSelectedChannel,
  //                 {
  //                   playingTime,
  //                   position,
  //                 },
  //                 ppvEvents
  //               )}
  //           </div>    
  //       </div>`;
  // }
  $(".streaming-timeline-title").show();
  $(timelineContent).append(content);

  $(".stream-channel-timeline").ready(function () {
    $('[data-toggle="popover"]').popover({
      container: ".stream-timeline-content",
    });
  });

  $(".channel-timeline-thumbnail").click((e) => {
    const slideContainer = $(slider).find("[role=slidelist]");
    const index = e.currentTarget.attributes["data-index"].value;
    slideContainer.slick("slickGoTo", index);
  });
};
