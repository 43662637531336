import { redirectTo } from "../../utils/httpHelper";
import {
  purchasePPVOrder,
  purchaseSubscription,
  verifyCouponCode,
} from "../../services/paymentService";
import { formatUrl } from "../../utils/domHelper";
import { getGatewayActive } from "../../services/paymentService";
import { isAuth } from "../../utils/authHelper";
import {
  createStripePurchaseCard,
  addCardFeilds,
} from "../../components/PPV/StripePurchaseCard";

let selectedPlan = null;

// PPV
export const registerPayment = async (
  baseUrl,
  apiKey,
  data,
  container,
  is_subscription = false
) => {
  const activeGW = await getGatewayActive(baseUrl, apiKey);
  if (activeGW.gateway_provider == 2) {
    let stripeApiKey = activeGW.credential_information.publishable_api_key;
    stripePayment(
      baseUrl,
      apiKey,
      stripeApiKey,
      data,
      container,
      is_subscription
    );
  }
};

// Stripe Payment
async function stripePayment(
  baseUrl,
  apiKey,
  stripeApiKey,
  data,
  container,
  is_subscription
) {
  let isLogged = isAuth();
  let loginToken = JSON.parse(localStorage.getItem("loginToken") || "{}");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const script_script = document.createElement("script");
  script_script.src = "https://js.stripe.com/v3/";
  script_script.async = true;

  // Load stripe scrip
  script_script.addEventListener("load", () => {
    $(createStripePurchaseCard(data, is_subscription)).appendTo(container);

    if ($.type(data) === "array") {
      initStripe(true);
      const selectPlanDropdown = $('select[name="plan_id"]');

      if (data.length > 1) {
        selectedPlan = data[0];
        selectPlanDropdown.val(selectedPlan.uuid);
        selectPlanDropdown.change(function () {
          selectedPlan = data.find((item) => item.uuid === this.value);
          initStripe(is_subscription);
        });
      } else if (data.length === 1) {
        // If there's only one plan, select it by default and trigger the change event
        selectedPlan = data[0];
        selectPlanDropdown.val(selectedPlan.uuid).change();
      }
    } else {
      // Single plan
      selectedPlan = data;
      addCardFeilds(data, is_subscription);
      initStripe(is_subscription);
    }
  });

  document.head.appendChild(script_script);

  // init Stripe
  async function initStripe(is_subscription) {
    const stripe = Stripe(stripeApiKey);
    const elements = stripe.elements();
    let cardElement = elements.create("card", {
      hidePostalCode: true,
    });
    if (isLogged) {
      let user_email = loginToken.user
        ? loginToken.user.email
        : loginToken.profile.email;
      $("#email").val(user_email).attr("disabled", true);
    }
    if (true) {
      addCardFeilds();
      cardElement.mount("#stripe-card-element");
    }

    // On Submit Payment Form
    let errorDiv = $("#stripe-card-errors");
    let stripeForm = $("#stripe-payment-form");
    let submitButton = $("#stripe-submit-payment");

    submitButton.on("click", function (event) {
      event.preventDefault();

      submitButton.attr("disabled", true);
      submitButton.html("Processing...");

      errorDiv.html("");
      let email = stripeForm.find("#email").val();
      let first_name = stripeForm.find("#first_name").val();
      let last_name = stripeForm.find("#last_name").val();
      let password = stripeForm.find("#password").val();
      let coupon_code = stripeForm.find("#coupon_code").val();

      // Check if all fields are empty
      if (
        email.trim() === "" &&
        first_name.trim() === "" &&
        last_name.trim() === "" &&
        password.trim() === "" &&
        coupon_code.trim() === ""
      ) {
        handleValidationError("Missing Require Fields!!");
        return false;
      }

      // Validate other fields
      if (first_name.trim() === "") {
        handleValidationError("Enter your first name !!");
        return false;
      }

      if (last_name.trim() === "") {
        handleValidationError("Enter your last name !!");
        return false;
      }
      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        handleValidationError("Enter a valid email address !!");
        return false;
      }
      if (password.trim() === "") {
        handleValidationError("Enter your password !!");
        return false;
      }
      function handleValidationError(message) {
        submitButton.attr("disabled", false);
        submitButton.html("Submit Payment");
        errorDiv.html(message);
      }

      let payload = {
        email: email,
        first_name: first_name,
        last_name: last_name,
        password: password,
        uuid: selectedPlan.uuid,
      };
      if (
        coupon_code !== undefined &&
        coupon_code !== "" &&
        coupon_code !== null
      ) {
        payload["coupon_code"] = coupon_code;
      }

      if (
        params.hasOwnProperty("deviceType") &&
        params.hasOwnProperty("deviceId")
      ) {
        payload["deviceType"] = params.deviceType;
        payload["deviceId"] = params.deviceId;
      }

      if (is_subscription && selectedPlan.is_free) {
        capturePayment(payload, submitButton);
      } else {
        stripeCard(stripe, cardElement, payload, submitButton);
      }
    });

    // close popup form
    $("#closeStripePurchaseCard").click(function () {
      $("#stripePurchaseCard").remove();
    });

    // Apply coupon code
    $("#coupon_apply_btn").on("click", function () {
      applyCouponCode(stripeForm.find("#coupon_code").val());
    });
  }

  // Stripe Card
  async function stripeCard(stripe, cardElement, payload, submitButton) {
    stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: `${payload["first_name"]} ${payload["last_name"]}`,
          email: payload["email"],
        },
      })
      .then(async function (result) {
        if ("paymentMethod" in result) {
          payload["payment_method_id"] = result.paymentMethod.id;
          capturePayment(payload, submitButton);
        } else {
          submitButton.attr("disabled", false);
          submitButton.html("Submit Payment");
        }
      });
  }

  // Capture Payment
  async function capturePayment(payload, submitButton) {
    let res;
    if (is_subscription) {
      res = await purchaseSubscription(
        baseUrl,
        apiKey,
        JSON.stringify(payload)
      );
    } else {
      payload["content_type"] = selectedPlan.content_type;
      res = await purchasePPVOrder(baseUrl, apiKey, JSON.stringify(payload));
    }

    if (res !== undefined) {
      if ("credentials" in res && res.credentials) {
        localStorage.setItem("loginToken", JSON.stringify(res.credentials));
        isLogged = true;
        loginToken = res.credentials;
      }
      $("#stripePurchaseCard").remove();

      if (window.location.href.indexOf("/purchase-ppv-event") !== -1) {
        let current_datetime = moment();
        let event_datetime = moment(selectedPlan.event_dates.lower);
        let diff_datetime = current_datetime.diff(event_datetime, "minuts");
        if (diff_datetime >= 0) {
          $(".ppv-event-banner-btn")
            .html("Watch Now")
            .addClass("ppv_event_watch_live_btn");
        } else {
          $(".ppv-event-banner-btn").remove();
          $(".ppv-event-banner-btn-wrapper").html(`
            <p style="color:green">
              This event will start at ${moment(
                selectedPlan.event_dates.lower
              ).format("hh:mm A")} on ${moment(
            selectedPlan.event_dates.lower
          ).format("ll")} you will be able to watch it by going to this page 
              <b><a href="#" class="ppv_event_watch_live_btn">${
                selectedPlan.title
              }</a></b>
            </p>
          `);
        }

        $(".ppv_event_watch_live_btn").on("click", function () {
          const nextURL = `/watch-live`;
          window.location = formatUrl(nextURL);
        });
      } else {
        window.location.reload();
      }
    } else {
      console.log("error");
      console.log(res);
    }

    submitButton.attr("disabled", false);
    submitButton.html("Submit Payment");
  }

  // Apply Coupon Code
  async function applyCouponCode(coupon_code) {
    let payload = JSON.stringify({
      plan_uuid: selectedPlan.uuid,
      offer_code: coupon_code,
    });
    let res = await verifyCouponCode(baseUrl, apiKey, payload);
    if (res != undefined && res.hasOwnProperty("discounted_price")) {
      $(".price-p")
        .css({
          "text-align": "center",
          "font-size": "20px",
          color: "black",
          "text-decoration": "line-through",
        })
        .after(
          `<p class="purchase-card-price" style="text-align:center;">${res.discounted_price}</p>`
        );
      $("#coupon_apply_btn").remove();
      $("#coupon_code").attr("disabled", true);
    }
  }
}

export const registerCancelCheckoutOrder = () => {
  $(".cancel-redirect").click(function () {
    redirectTo();
  });

  $(".do-redirect").click(function () {
    redirectTo();
  });
};
