import { redirectPageLogin } from "../utils/httpHelper";

export const buildHeader = (key) => {
  const tokenObj = JSON.parse(
    window.localStorage.getItem("loginToken") || "{}"
  );
  const access_token =
    (tokenObj.token ? tokenObj.token.access : tokenObj.access_token) || "";
  if (access_token === "") {
    return buildPublicApiHeader(key);
  }
  return buildSecureApiHeader();
};

export const buildSecureApiHeader = () => {
  const tokenObj = JSON.parse(
    window.localStorage.getItem("loginToken") || "{}"
  );
  const access_token =
    (tokenObj.token ? tokenObj.token.access : tokenObj.access_token) || "";
  if (access_token === "") {
    redirectPageLogin();
    return;
  }
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${access_token}`,
  };
};

export const buildPublicApiHeader = (apiKey) => {
  return {
    "Content-Type": "application/json",
    Authorization: `Tenant-Key ${apiKey}`,
  };
};

export const buildQueryParam = (url, params = {}) => {
  const urlParams = new URLSearchParams();
  Object.keys(params).forEach((k) => {
    if (params[k]) {
      urlParams.append(k, params[k]);
    }
  });
  return encodeURI(`${url}?${urlParams.toString()}`.replace(/\+/g, " "));
};
