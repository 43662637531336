import { convertTime } from "../../utils/formatter";
import { OnAudioErrorImg } from "../../constants/Media";

export const createAudioNextContent = (audio) => {
  return `
    <div class="next-audio-thumbnail-container">
        <img src="${
          audio.thumbnail
        }" alt="thumbnail next audio" class="next-audio-thumbnail" onerror="${OnAudioErrorImg}"/>
    </div>
    <div class="next-audio-content">
        <h3 class="next-audio-title">${audio.title}</h3>
        <p class="next-audio-time">${convertTime(Number(audio.duration))}</p>
    </div>
    `;
};
