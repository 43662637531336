import { $ } from "@clappr/core";
import { hideLoading, showLoading } from "../Loading";
import { getVideoListByCategory } from "../../services/videoService";

export const getLatestVideos = async (
  { generalSettings: settings, apiKey },
  baseUrl
) => {
  const lastVideoContainer = $(".latest-video-banner");
  const videosContainer = $(".latest-video-banner-videos");
  const videoItem = $(".latest-video-banner-videos")
    .find("[role=latestvideo]")
    .first()
    .clone();
  const dialogVideo = $("[role=dialogvideo]");
  const dialogVideoClose = $("[role=buttonclosedialog]");

  const videoPlayer = $("[role=dialogvideo]").find(
    ".latest-video-banner-dialog-video-ratio"
  );
  videoPlayer.empty().attr("id", "playerClappr");
  const categoryId = lastVideoContainer.attr("categoryid");
  if (videosContainer && categoryId && categoryId.length > 0) {
    showLoading(videosContainer);
    videosContainer.empty();
    const player = new Clappr.Player({
      // plugins: [HlsjsPlayback, ClapprStats],
      poster:
        "https://tvstartupcdn.com/static/site-builder/video-placeholder.png",
      width: "100%",
      height: "100%",
      autoPlay: false,
      mimeType: "video/mp4",
      chromeless: true,
      parentId: "#playerClappr",
      mute: false,
      maxBufferLength: 6,
    });
    $(dialogVideo).click((event) => {
      if (
        $(event.target).hasClass(`latest-video-banner-dialog-video-container`)
      ) {
        player.pause();
      }
    });
    $(dialogVideoClose).click(() => {
      player.pause();
    });

    const videos = await getVideoListByCategory(
      baseUrl,
      apiKey,
      settings.tenantId,
      categoryId,
      { page: 1, page_size: 3 }
    );
    videos?.results
      ?.filter((item) => item.type !== "playlist")
      .forEach((video) => {
        const videoItemClone = videoItem.clone();
        let poster = video?.thumbnail;
        if (!poster) {
          poster =
            "https://tvstartupcdn.com/static/site-builder/video-placeholder.png";
        }
        videoItemClone.find("img").attr("src", poster);

        videoItemClone.on("click", function () {
          player.configure({ chromeless: false, poster });
          player.load(video.url, "video/mp4", true);
          $("[role=dialogvideo]").show();
        });

        videosContainer.append(videoItemClone);
      });
    hideLoading(videosContainer);
  }
};
