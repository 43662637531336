export class PlaybackControl extends Clappr.UICorePlugin {
  
  get events() {
    const events = { click: 'onClick' };
    return events;
  }

  constructor(core) {
    super(core);
    this.bindEvents();
  }

  bindEvents() {
    this.stopListening(this.core)
    this.listenTo(this.core, Clappr.Events.CORE_ACTIVE_CONTAINER_CHANGED, this.onContainerChanged)
    this.listenTo(this.core, Clappr.Events.CORE_READY, this.bindMediaControlEvents)
  }

  bindMediaControlEvents() {
    this.stopListening(this.core.mediaControl)
    this.listenTo(this.core.mediaControl, Clappr.Events.MEDIACONTROL_RENDERED, this.render)
  }

  onContainerChanged() {
    this.container && this.stopListening(this.container)
    this.container = this.core.activeContainer
  }

  onClick() {
    // this.core.getCurrentPlayback().seek(this.core.getCurrentPlayback().getCurrentTime() + 5)
    this.handleClickEvent();
  }

  show() {
    this.$el.show()
  }

  hide() {
    this.$el.hide()
  }

  render() {
    this.renderControl();
    // this.$el.append(Clappr.Styler.getStyleFor(`
    //   .fast-forward { 
    //     position: static;
    //      float: left;
    //      height: 20px;
    //      width: 20px;
    //      z-index: 999;
    //      background-color: yellow;
    //    }
    // `))
    // this.core.mediaControl && this.core.mediaControl.$('.media-control-right-panel').append(this.el)
    // return this
  }
}