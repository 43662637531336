import { getGoogleFonts } from "./services/googleService";
import { loadGoogleFonts, showLoading } from "./utils/domHelper";
import App from "./views/App";

const app = async () => {
  let dataJson;
  let data;

  showLoading();

  const location = window.location.pathname.split("/").pop();

  if (process.env.WEB_APP_ENV !== "develop") {
    dataJson = await fetch(
      `data/pages/${location.split(".").shift() || "index"}.json`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    data = await dataJson.json();
  } else {
    dataJson = await fetch(
      // `https://api.tvsdevops.com/api/v1/template/list/?uuid=96217865-1388-47e7-94c0-6f3f06e69f3f`, //not present in the DB
      // `https://api.tvsdevops.com/api/v1/template/list/?uuid=0a976486-ccad-4aeb-8d57-f64a4529bedd`, // staging DB 
      `http://localhost:9005/api/v1/template/list/?uuid=461b1a4c-87e1-44a8-88c9-c95569ea8ff4`,   // local DB
      {
        method: "GET",
        headers: {
          Authorization: `Tenant-Key 3cT7HL8f.AFcS0Meiz29AfOcSorb4d1UWswb9IpVl`,
        },
      }
    );
    data = await dataJson.json();
    data = data[0];
    let fName = location.split(".").shift();
    fName = fName === "index" || !fName ? "home" : fName.split("-").join(" ");
    data = {
      ...data,
      settings: {
        ...data.settings,
        apiKey: data.settings.apiKey || process.env.WEB_API_KEY,
      },
      configs: data.configs.find(
        (config) => config.name.toLowerCase() == fName
      ),
    };
  }
  const webfonts = await getGoogleFonts();
  const fontFamily = webfonts.items
    .slice(0, 50)
    .map((item) => item.family)
    .join("|");
  loadGoogleFonts(fontFamily);
  if (document.getElementById("app-container")) {
    document.getElementById("app-container").appendChild(await App(data));
  }
};

// Load app
app();
