import { createButtonPlay } from "./ButtonPlay";
import { createAudioDescription } from "./AudioDescription";
import { getStatus } from "../../utils/analyticsHelper";
import ClapprStats from "../AnalyticsVideo/clappr-stats";
import { mediaControl } from "./AudioControl";
import { getAudioById } from "../../services/audioService";
import { FastForward } from "./FastForward";
import { FastBackward } from "./FastBackward";
import { ContentType } from "../../constants/ContentType";
import { setupPPV } from "../../utils/paymentHelper";
import { NotFoundBannerResource } from "../../constants/Media";
import { searchUrlParam } from "../../utils/httpHelper";
import { appContentAccess } from "../../services/paymentService";

export const renderAudioNoCategory = async (
  { audioPlayerContainer, audioPlayer, audioDescrption, track },
  { generalSettings: settings, apiKey },
  baseUrl,
  audioId
) => {
  $(audioPlayerContainer).css("grid-template-columns", "1fr");
  $(audioPlayerContainer).find(".audio-player-item.utils").remove();
  audioPlayer.empty().attr("id", "playerClappr");
  audioDescrption.empty();

  const audio = await getAudioById(baseUrl, apiKey, audioId);
  if (!audio) {
    return;
  }

  // Setup audio
  const content = $(createAudioDescription(audio));
  $(audioDescrption).append(content);
  mediaControl(audio);

  const player = new Clappr.Player({
    plugins: [HlsjsPlayback, ClapprStats, FastForward, FastBackward],
    poster: audio.banner || NotFoundBannerResource,
    width: "100%",
    height: "100%",
    autoPlay: true,
    mimeType: "video/mp4",
    parentId: "#playerClappr",
    maxBufferLength: 6,
    hlsPlayback: {
      preload: true,
    },
    hideMediaControl: false,
    clapprStats: {
      runEach: "extra.duration",
      onFirstReport: async () => {
        track.resetStatus(getStatus());
        track.send("Start", track.getRqStart());
      },
      onReport: (metrics) => {
        const reportStatus = track.getCurrent().reportStatus;
        if (!reportStatus?.send?.end) {
          track.send("End", track.getRqEnd(metrics));
        }
      },
      runBandwidthTestEvery: 10,
    },
    playback: {
      disableContextMenu: true,
      extrapolatedWindowNumSegments: 10,
      triggerFatalErrorOnResourceDenied: false,
    },
  });

 // if (!audio.trailer?.url) {
    player.configure({
      source: Clappr.Utils.Media.mp4,
      poster: audio.banner || NotFoundBannerResource,
      autoPlay: false,
      chromeless: true,
    });
    player.getPlugin("poster").$playWrapper.hide();
  // } else {
  //   player.configure({
  //     source: audio.trailer?.url,
  //     poster: audio.banner || NotFoundBannerResource,
  //     autoPlay: true,
  //     chromeless: true,
  //   });
  // }

  // Validate Content Access
  let app_content_plans = await appContentAccess(baseUrl, apiKey);
  if (app_content_plans.hasOwnProperty('plans') && app_content_plans['plans'].length > 0) {
    setupPPV(
      baseUrl,
      apiKey,
      app_content_plans['plans'],
      audioPlayer,
      ContentType.TENANT,
      {},
    );
    return;
  }

  const isOrderProcessing =
    localStorage.getItem("isOrderProcessing") ||
    searchUrlParam("isOrderProcessing") === "true";
  if (isOrderProcessing && audio.packages.length > 0) {
    let content_type;
    if (audio.packages.filter((p) => p.content_type == ContentType.AOD)[0]) {
      content_type = ContentType.AOD;
    } else if (
      audio.packages.filter((p) => p.content_type == ContentType.AUDIO)[0]
    ) {
      content_type = ContentType.AUDIO;
    }
    setupPPV(
      baseUrl,
      apiKey,
      audio,
      audioPlayer,
      content_type,
      {},
      true,
    );
  }
  const playButton = $(createButtonPlay(audio));
  playButton.on("click", function () {
    if (audio.packages.length > 0) {
      let content_type;
      if (audio.packages.filter((p) => p.content_type == ContentType.AOD)[0]) {
        content_type = ContentType.AOD;
      } else if (
        audio.packages.filter((p) => p.content_type == ContentType.AUDIO)[0]
      ) {
        content_type = ContentType.AUDIO;
      }
      setupPPV(
        baseUrl,
        apiKey,
        audio,
        audioPlayer,
        content_type,
        {},
        true,
      );
    } else {
      playButton.hide();
      player.configure({ chromeless: false });
      player.load(audio.url, "video/mp4", true);
    }
  });
  playButton.appendTo(audioPlayer);
};
