import {
  hideLoading,
  loadingPosition,
  loadingSize,
  showLoading,
} from "../../Loading";
import { createChannelCarouselSlider } from "./Channel-Carousel-Slider";
import { getChannels } from "../../../services/channelService";
import {
  getDefaultReportVideoChannel,
  sendReportChannelStatistic,
  getRqStartVideoChannel,
  getStatus,
} from "../../../utils/analyticsHelper";
import Tracking from "../../AnalyticsVideo/Tracking";
import { renderStreamTimelineContent } from "../../StreamTimeline";
import AntPlayer from "../../AntPlayer";
import { setupUpComingStream } from "../../../utils/streamHelper";
import { setupPPV } from "../../../utils/paymentHelper";
import { ContentType } from "../../../constants/ContentType";
import { getPPVEventList } from "../../../services/ticketService";
import { appContentAccess } from "../../../services/paymentService";

let ppvEventInterval;


export const getChannelSliders = async (settings, baseUrl) => {
  const { apiKey } = settings;
  const sliders = $("[role=channelsliderwrapper]").toArray();
  // Clone slide template
  const slider_0 = sliders[0];
  const slideContainer = $(slider_0).find("[role=slidelist]")[0];
  const slideTmpl = $(slideContainer).find("[role=slide]").first().clone();
  $(sliders).find("[role=slidelist]").empty();
  const channels = await getChannels(baseUrl, apiKey);
  if (sliders && sliders.length > 0) {
    sliders.forEach(async (item) => {
      const slideContainer = $(item).find("[role=slidelist]");
      await createChannelSlider(
        item,
        slideContainer,
        slideTmpl,
        { settings, apiKey, baseUrl },
        channels
      );
    });
  }
};

export const SliderType = {
  CHANNEL_CAROUSEL_SLIDER_1: "channel-carousel-slider-style-1",
  CHANNEL_CAROUSEL_SLIDER_2: "channel-carousel-slider-style-2",
};

export const createChannelSlider = async (
  slider,
  slideContainer,
  slideTmpl,
  { settings, apiKey, baseUrl },
  channels
) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // Sort
  let selected_channel_obj;
  if(params.channelId != undefined){
    selected_channel_obj = $.grep(channels.results, function(item) {return item.id === params.channelId;})[0];
    let matchingIndex = $.inArray(selected_channel_obj, channels.results);
    delete channels.results[matchingIndex];
    channels.results = [selected_channel_obj].concat(channels.results);
  }

  const attributes = ["slidertype"].reduce(
    (pre, cur) => ({ ...pre, [cur]: $(slider).attr(cur) }),
    {}
  );
  const { slidertype } = attributes;
  await createChannelCarouselSlider(
    slideContainer,
    slideTmpl,
    { settings, apiKey, baseUrl },
    channels,
    slidertype
  );
  // Tracking
  let channelInfo = channels.results[0];

  const track = new Tracking(
    { settings, apiKey, baseUrl },
    sendReportChannelStatistic,
    getRqStartVideoChannel
  );

  $(document).ready(async function () {
    const streamPlayer = new AntPlayer(".stream-channel-player", {
      playOrder: "hls",
      onPlayerStarted: () => {
        track.init(getDefaultReportVideoChannel(channelInfo?.id));
        const reportStatus = track.getCurrent().reportStatus;
        if (reportStatus?.send?.start === false) {
          track.resetStatus(getStatus(true));
          track.send("Start", track.getRqStart());
        }
      },
      onPlayerEnded: (metrics) => {
        const reportStatus = track.getCurrent().reportStatus;
        if (!reportStatus?.send?.end) {
          track.send("End", track.getRqEnd(metrics));
        }
      },
    });

    window.onbeforeunload = function () {
      if (streamPlayer) {
        streamPlayer.destroy();
      }
      return null;
    };
  
    // Setup Slick
    setupSliderSlick(streamPlayer, slider, slidertype, channelInfo, {
      settings,
      apiKey,
      baseUrl,
    });
    await renderStreamTimeline(streamPlayer, slider, channelInfo, channels, {
      settings,
      baseUrl,
      apiKey,
    });
  });

  return slider;
};



const renderNewChannel = async ({
  streamPlayer,
  slider,
  channelInfo,
  settings,
  baseUrl,
  apiKey,
}) => {
  const channelId = $(slider).find(".slick-current").attr("data-id");
  showLoading($(document.body), loadingSize.LARGE, loadingPosition.CENTER);
  const channels = await getChannels(baseUrl, apiKey);
  channelInfo = channels.results.filter((c) => c.id === channelId)[0];
  await renderStreamTimeline(streamPlayer, slider, channelInfo, channels, {
    settings,
    baseUrl,
    apiKey,
  });
  hideLoading($(document.body), loadingSize.LARGE, loadingPosition.CENTER);
};

export const renderStreamTimeline = async (
  streamPlayer,
  slider,
  channelInfo,
  channels,
  { settings, baseUrl, apiKey }
) => {
  const streamTimeline = $(slider).next(".streaming-timeline");
  const paymentCard = $('.streaming-timeline');

  if(streamPlayer){
    clearInterval(ppvEventInterval);
    streamPlayer.destroy();
  }

  // Validate Content Access
  let app_content_plans = await appContentAccess(baseUrl, apiKey);
  if (app_content_plans.hasOwnProperty('plans') && app_content_plans['plans'].length > 0) {
    setupPPV(
      baseUrl,
      apiKey,
      app_content_plans['plans'],
      paymentCard,
      ContentType.TENANT,
      {},
    );
    return;
  }

  // Check live stream subscription
  if(channelInfo.live_stream_subscription_plans.length > 0){
    setupPPV(baseUrl, apiKey, channelInfo.live_stream_subscription_plans, paymentCard, ContentType.CHANNEL, {});
    return;
  }

  checkActivePPVEvent(baseUrl, apiKey, channelInfo, streamPlayer, paymentCard);

  
  if (!streamTimeline) {
    return;
  }

  if (!channelInfo || channelInfo?.stream_url.length === 0) {
    return;
  }

  let playingTime = 0;
  if (window.activeChannelSocket) {
    window.activeChannelSocket.close();
    clearInterval(window.retrivePlaying);
  }

  streamPlayer.playExternalStream(channelInfo.stream_url);

  // if (channelInfo.stream_mode === "EXTERNAL") {
  //   streamPlayer.playExternalStream(channelInfo.stream_url);
  // } else {
  //   setupUpComingStream(streamPlayer, channelInfo, settings, apiKey);
  // }

  await renderStreamTimelineContent(
    channelInfo,
    channels,
    slider,
    {
      playingTime,
    },
    { baseUrl, apiKey }
  );

  // Check PPV Event
  ppvEventInterval = setInterval(async function() {
    checkActivePPVEvent(baseUrl, apiKey, channelInfo, streamPlayer, streamTimeline);
  }, 60 * 1000);
};



const checkActivePPVEvent = async (baseUrl, apiKey, channel, streamPlayer, container) => {
  let ppvEventData = await getPPVEventList(baseUrl, apiKey, {'active': true, 'channel_id': channel.id});
  ppvEventData = ppvEventData['event_list'];
  if (ppvEventData.length > 0 && !ppvEventData[0].is_purchased){
    streamPlayer.destroy();
    ppvEventData = ppvEventData[0];
    if (!$(`#stripePurchaseCard`).length) {
      let data = {
        'uuid': ppvEventData.uuid,
        'title': ppvEventData.name,
        'price': ppvEventData.price,
        'price_currency': ppvEventData.price_currency,
        'event_dates': ppvEventData.event_dates,
        'content_type': ContentType.PPV_EVENT,
      }
      setupPPV(baseUrl, apiKey, data, container, ContentType.PPV_EVENT, {});
    }
  }
}

const setupSliderSlick = (
  streamPlayer,
  slider,
  slidertype,
  channelInfo,
  { settings, apiKey, baseUrl }
) => {
  const slideContainer = $(slider).find("[role=slidelist]");
  let optsSlick;
  if (slidertype === SliderType.CHANNEL_CAROUSEL_SLIDER_2) {
    optsSlick = {
      slidesToShow: 1,
      arrows: true,
      dots: true,
    };
    $(slideContainer)
      .slick(optsSlick)
      .on("afterChange", async function (event, slick, currentSlide) {
        renderNewChannel({
          streamPlayer,
          slider,
          channelInfo,
          settings,
          baseUrl,
          apiKey,
        });
      })
      .on("breakpoint", function () {
        const current_slider = $(this);
        current_slider.slick("unslick");
        current_slider.find(".slick-list").remove();
        current_slider.slick(optsSlick);
      });
  } else {
    optsSlick = {
      centerMode: true,
      centerPadding: 0,
      slidesToShow: 5,
      arrows: false,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 3,
            centerMode: true,
            centerPadding: 0,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: 0,
          },
        },
      ],
    };
    $(slideContainer).removeClass("slick-initialized slick-slider");
    $(slideContainer)
      .on("init", () => {
        $('.slick-slide[data-slick-index="-2"]').addClass("lt2");
        $('.slick-slide[data-slick-index="-1"]').addClass("lt1");
        $('.slick-slide[data-slick-index="1"]').addClass("gt1");
        $('.slick-slide[data-slick-index="2"]').addClass("gt2");
      })
      .slick(optsSlick)
      .on("beforeChange", (event, slick, current, next) => {
        if (current !== next) {
          $(".slick-slide.gt2").removeClass("gt2");
          $(".slick-slide.gt1").removeClass("gt1");
          $(".slick-slide.lt1").removeClass("lt1");
          $(".slick-slide.lt2").removeClass("lt2");

          if (
            (current % 2 === 0 && next % 2 === 0) ||
            (current % 2 === 1 && next % 2 === 1)
          ) {
            $(`.slick-slide[data-slick-index="${next - 2}"]`).addClass("lt2");
            $(`.slick-slide[data-slick-index="${next - 1}"]`).addClass("lt1");
            $(`.slick-slide[data-slick-index="${next + 1}"]`).addClass("gt1");
            $(`.slick-slide[data-slick-index="${next + 2}"]`).addClass("gt2");
          } else {
            const lt2 = current < next && current > 0 ? current - 1 : next - 2;
            const lt1 = current < next && current > 0 ? current : next - 1;
            const gt1 = current < next || next === 0 ? next + 1 : current;
            const gt2 = current < next || next === 0 ? next + 2 : current + 1;

            $(`.slick-slide[data-slick-index="${lt2}"]`).addClass("lt2");
            $(`.slick-slide[data-slick-index="${lt1}"]`).addClass("lt1");
            $(`.slick-slide[data-slick-index="${gt1}"]`).addClass("gt1");
            $(`.slick-slide[data-slick-index="${gt2}"]`).addClass("gt2");

            // Clone processing when moving from 5 to 0
            if (current === 5 && next === 0) {
              $(`.slick-slide[data-slick-index="${current - 1}"]`).addClass(
                "lt2"
              );
              $(`.slick-slide[data-slick-index="${current}"]`).addClass("lt1");
              $(`.slick-slide[data-slick-index="${current + 2}"]`).addClass(
                "gt1"
              );
              $(`.slick-slide[data-slick-index="${current + 3}"]`).addClass(
                "gt2"
              );
            }

            // Clone processing when moving from 0 to 5
            if (current === 0 && next === 5) {
              $(`.slick-slide[data-slick-index="${current - 1}"]`).addClass(
                "gt2"
              );
              $(`.slick-slide[data-slick-index="${current}"]`).addClass("gt1");
              $(`.slick-slide[data-slick-index="${current - 2}"]`).addClass(
                "lt1"
              );
              $(`.slick-slide[data-slick-index="${current - 3}"]`).addClass(
                "lt2"
              );
            }
          }
        }
      })
      .on("afterChange", async (event, slick, currentSlide) => {
        renderNewChannel({
          streamPlayer,
          slider,
          channelInfo,
          settings,
          baseUrl,
          apiKey,
        });
      })
      .on("breakpoint", function () {
        const current_slider = $(this);
        current_slider.slick("unslick");
        current_slider.find(".slick-list").remove();
        current_slider.slick(optsSlick);
      });
  }
};
