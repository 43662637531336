import { createSlide } from "./Slide";
import { formatUrl, hideLoading, showLoading } from "../../../utils/domHelper";
import {getVideoListByCategory, getVideosByCategory} from "../../../services/videoService";

export const getBannerSlider = async (
  { generalSettings: settings, apiKey },
  baseUrl
) => {
  const slideBanner = $("#slideBanner");
  const slidesContainer = $(`.slides-container`);
  const categoryId = slideBanner.attr("categoryid");
  if (slideBanner && categoryId && categoryId.length > 0) {
    showLoading();
    const slideFirst = slidesContainer
      .find(".banner-slide")
      .not(".slick-cloned")
      .first();
    slidesContainer.empty();
    // Fetch video list
    const videos = await getVideosByCategory(
      baseUrl,
      apiKey,
      settings.tenantId,
      categoryId
    );
    const attributes = [
      "arrows",
      "infinite",
      "autoplay",
      "dots",
      "prevArrow",
      "nextArrow",
    ].reduce((pre, cur) => ({ ...pre, [cur]: slideBanner.attr(cur) }), {});
    const { arrows, infinite, autoplay, dots, prevArrow, nextArrow } =
      attributes;

    videos?.medias
      ?.filter((item) => item.type !== "playlist")
      .forEach((item) => {
        const slideItem = slideFirst.clone();
        const slide = createSlide(slideItem, item);
        const slideContainer = $(`.slides-container`);
        slideContainer.append(slide);

        const optsSlick = {
          arrows: arrows === "" || false,
          infinite: infinite === "" || true,
          autoplay: autoplay === "autoplay" || false,
          dots: dots === "" || false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
          prevArrow: $(prevArrow),
          nextArrow: $(nextArrow),
        };
        const slickSlider = $(slideContainer).slick("getSlick");
        if (slickSlider) {
          $(slideContainer).slick("unslick");
        }
        $(slideContainer).slick(optsSlick);
        const playUrl = `/watch-movies?categoryId=${categoryId}&videoId=${item.id}`;
        if (slide.find(".link-class-basic").length) {
          slide.find("a").attr("href", playUrl);
        } else {
          slide.find(".bannerbtn").on("click", function () {
            window.location = formatUrl(playUrl);
          });
        }
      });

    hideLoading();
  }
};
