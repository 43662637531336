import { convertTime } from "../../utils/formatter";
import { OnAudioErrorImg } from "../../constants/Media";

export const createAudioItem = (audio) => {
  return `
    <li class="audio-item">
        <div class="thumbnail-audio-container">
            <img src="${
              audio.thumbnail
            }" alt="thumbnail next audio" class="thumbnail-audio" onerror="${OnAudioErrorImg}">
        </div>
        <div class="audio-content">
            <h3 class="audio-title">${audio.title}</h3>
            <p class="audio-time">${convertTime(Number(audio.duration))}</p>
        </div>
    </li>
    `;
};
