import { currencyFormatter, dateFormatter } from "../../utils/formatter";

export const planTwoMapping = (plan) => {
  document.querySelector('.subscription-card-two-header').style.backgroundColor = plan.membershipColor;
  document.querySelector('.subscription-card-two-square').style.backgroundColor = plan.membershipColor;
  document.querySelector('.subscription-card-two-title').innerHTML = plan.title;

  if (plan.isFree) {
    document.querySelector('.subscription-card-two-num').innerHTML = plan.trialPeriodInput;
    document.querySelector('.subscription-card-two-free-trial').innerHTML = `${plan.trialPeriodSelect} Free Trial`;
  }

  document.querySelector('.subscription-card-two-text-des').innerHTML = plan.description;
  document.querySelector('.subscription-card-two-text-discount').innerHTML = `${currencyFormatter.format(plan.regularPriceInput)} / ${plan.regularPriceSelect}`;

  if (plan.isDiscount) {
    document.querySelector('.subscription-card-two-text-discount').classList.add('subscription-card-two-per-month');
    document.querySelector('.subscription-card-two-price').innerHTML = `${currencyFormatter.format(plan.discountedPriceValue)}`;
    document.querySelector('.subscription-card-two-discount-info').innerHTML = `Valid until <span class="text-weight-bold">${dateFormatter(plan.discountFor)}</span>`;
  }

  // tslint:disable-next-line:max-line-length
  document.querySelector('.subscription-card-two-actions-button').style.backgroundColor = plan.btnAddColor;
}

export const createCardTwo = (plan) => {
  return `
    <div class="subscription-card-two">
      <div class="subscription-card-two-header" style="background-color: ${plan.membershipColor}">
        <div class="subscription-card-two-title">${plan.title}</div>
        <div class="subscription-card-two-price text-weight-bold" style="display: ${plan.isDiscount ? 'block' : 'none'}">
          ${currencyFormatter.format(plan.discountedPriceValue)}
        </div>
        <div class="subscription-card-two-text-discount text-weight-bold ${plan.isDiscount ? 'per-month' : ''}">
          ${currencyFormatter.format(plan.regularPriceInput)} / ${plan.regularPriceSelect}
        </div>
        <div class="text-des subscription-card-two-discount-info" style="display: ${plan.isDiscount ? 'block' : 'none'}">
          Valid until <span class="text-weight-bold">${dateFormatter(plan.discountFor)}</span>
        </div>
        <div class="subscription-card-two-square" style="background-color: ${plan.membershipColor}"></div>
      </div>
      <div class="subscription-card-two-content">
        <div class="text-weight-bold subscription-card-two-num" style="display: ${plan.isFree ? 'block' : 'none'}">
          ${plan.trialPeriodInput}
        </div>
        <div class="text-weight-bold subscription-card-two-free-trial" style="display: ${plan.isFree ? 'block' : 'none'}">
          ${plan.trialPeriodSelect} Free Trial
        </div>
        <div class="subscription-card-two-text-des">
          ${plan.description}
        </div>
      </div>
      <div class="subscription-card-two-actions " style="background-color: ${plan.btnAddColor}">
        <a class="subscription-card-two-actions-button subscription-card-btn-purchase">PURCHASE</a>
      </div>
    </div>
  `
}