
export const createDivContainer = (className) => $(`<div class="${className}"></div>`);

export const CATEGORY_SERIES_GRID = {
    classGridDrop: 'category-series-grid',
    classGridDropItem: 'category-series-grid-item',
    classGridDropContent: 'category-series-grid-content',
    componentsGridDropItem: (item) => $(`
        <div class="category-series-grid-item">
            <img width="100%" height="100%" src="${item.thumbnail}" alt="IMAGE VIDEO">
        </div>
    `),
    componentsGridDropContent: () => createDivContainer('category-series-grid-content')

};

export const LevelDrop = {
    Level0: '0',
    Level1: '1',
    Level2: '2',
};

export const VIDEO_SERIES_GRID = {
    classGridDrop: 'video-series-grid',
    classGridDropItem: 'video-series-grid-item',
    classGridDropContent: 'video-series-grid-content',
    componentsGridDropItem: (item) => $(`
    <div class="video-series-grid-item" data-category-id ="${item.id}">
        <img width="100%" height="100%" src="${item.thumbnail}" alt="IMAGE VIDEO">
        <div class="s-bottom-arrow"></div>
    </div>
`),
    componentsGridDropContent: (item, gridContent) => $(`
    <div class="video-series-grid-content">
        <div class="video-series-grid-content-container">
            <h3 class="video-series-grid-content-title">${item.title}</h3>
            <p  class="video-series-grid-content-description">${item.short_description}</p>
        </div>
    </div>`).append(gridContent)

};

export const VIDEO_SEASON_GRID = {
    classGridDrop: 'video-season-grid',
    classGridDropItem: 'video-season-grid-item',
    classGridDropContent: 'video-season-grid-content',
    selectorGridDropContentSlider: '[role=sliderwrapper]',
    componentsGridDropItem: (item) => $(`
    <div class="video-season-grid-item">
        <p class="video-season-grid-item-title">${item.title}</p>
    </div>`),
    componentsGridDropContent: (item, slider) => {
        const sliderContainer = $(`<div class="video-season-grid-content-slider-container"></div>`).append(slider)
        const gridContent = $(`
            <div class="video-season-grid-content">
                <div class="video-season-grid-content-container">
                    <img width="100%" src="${item.thumbnail}" alt="IMAGE VIDEO">
                    <div class="video-season-grid-content-info">
                        <h3 class="video-season-grid-content-title">${item.title}</h3>
                        <p  class="video-season-grid-content-description">${item.long_description}</p>
                    </div>
                </div>
            </div>`
        ).append(sliderContainer)
        return gridContent;
    }
};
