export const renderAuthHeader = (token) => {
    const information = JSON.parse(token);
    $(
      ".entertainment-header-account-actions, .ministries-header-account-actions, .broadcaster-header-account-actions"
    ).html(`
      <div class="dropdown-nav-user">
        <a class="dropdown-nav-user-btn">
          <img width="50" height="50" style="border-radius: 50%;"
            src="https://tvs-sandbox.b-cdn.net/static/site-builder/avatar.png">
           <span>${
             information.user
               ? information.user.username
               : information.profile.username
           }</span>
        </a>
        <ul class="dropdown-nav-user-content">
          <li id="btn-nav-logout" class="dropdown-nav-user-logout">Logout</li>
        </ul>
      </div>
    `);

    // Broadcaster
    $(".broadcaster-header-sign-up").hide();
    $(".broadcaster-header-login").hide();
    $(".broadcaster-header-account-actions").append(
      '<i class="fa fa-search ${classPfx}-search" aria-hidden="true"></i>'
    );
    // End Broadcaster

    $(".main-banner-btn-trial-banner").html("Upgrade Your Plan");
    $(".tryIt-btn-trial-banner").html("Upgrade Your Plan");

    setTimeout(() => {
      $("#btn-nav-logout, [role=logout]").click(() => {
        localStorage.clear();
        window.location.reload();
      });
    }, 0);
}