require("es6-promise").polyfill();

import { buildPublicApiHeader } from "./baseService";

const originalFetch = require("isomorphic-fetch");
const fetch = require("fetch-retry")(originalFetch);

export const sendChannelAnalytics = async (baseUrl, apiKey, data) => {
  const response = await fetch(`${baseUrl}/clients/stat/`, {
    retryOn: [500, 400],
    retries: 3,
    retryDelay: 1000,
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: buildPublicApiHeader(apiKey),
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer",
    body: data,
    keepalive: true,
  });
  return response.json();
};
