import { NotFoundResource, OnErrorImg } from "../../../constants/Media";

export const createSlide = (domCurrent, slide) => {
  domCurrent
    .find("img")
    .attr("src", slide.banner || NotFoundResource)
    .attr("onError", OnErrorImg);
  domCurrent.find(".banner-slide-title").html(slide.title);
  domCurrent.find(".banner-slide-description").html(slide.description);
  return domCurrent;
};
