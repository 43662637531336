import { createButtonPlay } from "./ButtonPlay";
import { createVideoDescription } from "./VideoDescription";
import { createVideoItem } from "./VideoItem";
import { createVideoNextContent } from "./VideoNextContent";
import {
  getDefaultReportVOD,
  getRqStartVOD,
  getStatus,
  sendReportChannelStatistic,
} from "../../utils/analyticsHelper";
import ClapprStats from "../AnalyticsVideo/clappr-stats";
import Tracking from "../AnalyticsVideo/Tracking";
import { mediaControl } from "./VideoControl";
import { getVideoById } from "../../services/videoService";
import { getSingleVODCategoryById } from "../../services/categoryService";
import { ContentType } from "../../constants/ContentType";
import { setupPPV } from "../../utils/paymentHelper";
import { searchUrlParam } from "../../utils/httpHelper";
import { renderVideoNoCategory } from "./SingleVideoPlayer";
import { appContentAccess } from "../../services/paymentService";

export const getVideoPlayer = async (
  { generalSettings: settings, apiKey },
  baseUrl
) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const videoPlayerContainer = document.querySelector(
    ".video-player-container"
  );
  if (videoPlayerContainer == null) {
    return;
  }

  const videoPlayer = $(videoPlayerContainer).find(".video-player-item.player");

  const videoDescrption = $(videoPlayerContainer).find(
    ".video-player-item.description"
  );

  const track = new Tracking(
    { settings, apiKey, baseUrl },
    sendReportChannelStatistic,
    getRqStartVOD
  );
  track.init(getDefaultReportVOD(params));

  // Validate render options
  if (!params.categoryId && !params.videoId) {
    return;
  } else if (!params.categoryId) {
    await renderVideoNoCategory(
      { videoPlayerContainer, videoPlayer, videoDescrption, track },
      { settings, apiKey },
      baseUrl,
      params.videoId
    );
    return;
  }

  const videoList = $(videoPlayerContainer).find(
    ".video-player-item.suggestion .video-list"
  );
  const videoNextContent = $(videoPlayerContainer).find(
    ".video-player-item.next .autoplay-content"
  );

  if (!settings.showContinueWatching) {
    $(videoPlayerContainer).find(".video-player-item.next").remove();
    $(videoPlayerContainer).find(".video-player-item").addClass("no-grid");
    videoList.addClass("no-grid-list");
  }

  videoList.empty();
  videoPlayer.empty().attr("id", "playerClappr");
  videoNextContent.empty();
  videoDescrption.empty();

  const category = await getSingleVODCategoryById(
    baseUrl,
    apiKey,
    settings.tenantId,
    params.categoryId
  );

  const videos = category.medias?.filter((item) => item.type !== "playlist");
  videoPlayerContainer.setAttribute("player-type", settings.videoStyle);

  let videoIndex = videos.findIndex((item) => item.id === params.videoId);
  videoIndex = videoIndex === -1 ? 0 : videoIndex;
  let autoNext = settings.showContinueWatching;

  const player = new Clappr.Player({
    plugins: [HlsjsPlayback, ClapprStats],
    poster: videos[0]?.thumbnail,
    width: "100%",
    height: "100%",
    autoPlay: false,
    mimeType: "video/mp4",
    chromeless: true,
    parentId: "#playerClappr",
    mute: true,
    maxBufferLength: 6,
    hlsPlayback: {
      preload: true,
    },
    clapprStats: {
      runEach: "extra.duration",
      onFirstReport: async () => {
        track.resetStatus(getStatus());
        track.send("Start", track.getRqStart());
      },
      onReport: (metrics) => {
        const reportStatus = track.getCurrent().reportStatus;
        if (!reportStatus?.send?.end) {
          track.send("End", track.getRqEnd(metrics));
        }
      },
      runBandwidthTestEvery: 10,
    },
    playback: {
      disableContextMenu: true,
      extrapolatedWindowNumSegments: 10,
      triggerFatalErrorOnResourceDenied: false,
      playInline: Clappr.Browser.isMobile,
      recycleVideo: Clappr.Browser.isMobile,
    },
  });
  const mapVideo = async (index) => {
    videoPlayer.empty();
    // if (!videos[index]?.trailer?.url) {
    player.configure({
      source: Clappr.Utils.Media.mp4,
      poster: videos[index]?.thumbnail,
      autoPlay: false,
      chromeless: true,
    });
    player.getPlugin("poster").$playWrapper.hide();
    //}
    // else {
    //   player.configure({
    //     source: formatCacheFlyVideoUrl(videos[index]?.trailer?.url),
    //     poster: videos[index]?.thumbnail,
    //     autoPlay: true,
    //     chromeless: true,
    //   });
    // }

    // Validate Content Access
    let app_content_plans = await appContentAccess(baseUrl, apiKey);
    if (
      app_content_plans.hasOwnProperty("plans") &&
      app_content_plans["plans"].length > 0
    ) {
      setupPPV(
        baseUrl,
        apiKey,
        app_content_plans["plans"],
        videoPlayer,
        ContentType.TENANT,
        {}
      );
      return;
    }

    const isOrderProcessing =
      localStorage.getItem("isOrderProcessing") ||
      searchUrlParam("isOrderProcessing") === "true";
    if (
      isOrderProcessing &&
      videos[index].packages &&
      videos[index].packages.length > 0
    ) {
      let content_type;
      if (
        videos[index].packages.filter(
          (p) =>
            p.content_type == ContentType.VOD &&
            category.related_ids.includes(p.content_uuid)
        )[0]
      ) {
        content_type = ContentType.VOD;
      } else if (
        videos[index].packages.filter(
          (p) => p.content_type == ContentType.VIDEO
        )[0]
      ) {
        content_type = ContentType.VIDEO;
      }
      setupPPV(
        baseUrl,
        apiKey,
        videos[index],
        videoPlayer,
        content_type,
        category
      );
    }
    const playButton = $(createButtonPlay(videos[index]));
    playButton.on("click", function () {
      if (videos[index].packages && videos[index].packages.length > 0) {
        let content_type;
        if (
          videos[index].packages.filter(
            (p) =>
              p.content_type == ContentType.VOD &&
              category.related_ids.includes(p.content_uuid)
          )[0]
        ) {
          content_type = ContentType.VOD;
        } else if (
          videos[index].packages.filter(
            (p) => p.content_type == ContentType.VIDEO
          )[0]
        ) {
          content_type = ContentType.VIDEO;
        }
        setupPPV(
          baseUrl,
          apiKey,
          videos[index],
          videoPlayer,
          content_type,
          category
        );
      } else {
        playButton.hide();
        player.configure({ chromeless: false });
        player.load(videos[index].url, "video/mp4", true);
        if (autoNext) {
          player.on(Clappr.Events.PLAYER_ENDED, () => {
            videoIndex = videoIndex + 1;
            selectVideo(videoIndex);
          });
        }
      }
    });
    playButton.appendTo(videoPlayer);
  };

  const mapVideoList = () => {
    videos?.forEach((item, index) => {
      const videoItem = $(createVideoItem(item));
      videoItem.css("cursor", "pointer");
      $(videoList).append(videoItem);
      $(videoItem).on("click", async () => {
        if (videoIndex !== index) {
          await selectVideo(index);
          videoIndex = index;
          const video = videos[videoIndex];
          if (video && video?.id) {
            track.init(
              getDefaultReportVOD({
                categoryId: video.vod,
                videoId: video.id,
              })
            );
          }
        }
      });
    });
  };

  const mapVideoNext = (index) => {
    videoNextContent.empty();
    const video = videos[index];
    const inputSlide = $(videoPlayerContainer).find("#checkbox");
    inputSlide.attr("checked", autoNext);
    inputSlide.on("click", function () {
      autoNext = !autoNext;
      if (!autoNext) {
        $("video").off("ended");
      } else {
        $("video").on("ended", function () {
          selectVideo(index);
        });
      }
    });
    if (!video) {
      index = 0; // if the end of list will be going about the top item
    }
    inputSlide.attr("disabled", false);
    const content = $(createVideoNextContent(videos[index]));
    videoNextContent.css("cursor", "pointer");
    content.on("click", function () {
      selectVideo(index);
    });
    $(videoNextContent).append(content);
  };

  const mapVideoDescription = (index) => {
    videoDescrption.empty();
    const content = $(createVideoDescription(videos[index]));
    $(videoDescrption).append(content);
  };

  const selectVideo = async (index) => {
    if (videos[index]) {
      const videoId = videos[index]?.id;
      const video = await getVideoById(baseUrl, apiKey, videoId);
      if (!video) {
        return;
      }
      videos[index] = video;
      mapVideo(index);
      mapVideoDescription(index);
      mediaControl(videos[index]);
      if (settings.showContinueWatching) {
        mapVideoNext(index + 1);
      }
    }
  };

  const iniVideo = () => {
    mapVideoList();
    selectVideo(videoIndex);
  };
  iniVideo();
};
