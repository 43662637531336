import { currencyFormatter } from "../../../utils/formatter";
import {
  getSubscriptionPlans,
  processSubscriptionCheckout,
} from "../../../services/paymentService";

export default async function (settings, form, baseUrl) {
  const subscriptionChannelId = localStorage.getItem("subscriptionChannel");
  $(".order-price-table").hide();

  const selectedPlan = localStorage.getItem("selectedPlan");
  const jsonPlans = await getSubscriptionPlans(
    baseUrl,
    settings.apiKey,
    subscriptionChannelId || "empty",
    { uuid: selectedPlan }
  );
  const loginToken = JSON.parse(localStorage.getItem("loginToken") || "{}");

  form.submission = {
    data: {
      username: loginToken.user
        ? loginToken.user.username
        : loginToken.profile.username,
      email: loginToken.user ? loginToken.user.email : loginToken.profile.email,
      password: loginToken.user
        ? loginToken.user.password
        : loginToken.profile.password,
    },
  };

  const iPlan = jsonPlans[0];
  // const totalPrice = (iPlan.discount || iPlan.price);
  const pricing = {
    title: iPlan.title,
    price: iPlan.discount || iPlan.price,
  };

  setTimeout(() => {
    $(".order-price-table").show();
  }, 0);

  $(".choices__list--dropdown").css("color", "black");
  $(".order-price-table").html(`
    <div class="gjs-cell">
      <div>${pricing.title}</div>
    </div>
    <div class="gjs-cell">
      <div>${currencyFormatter.format(pricing.price)}</div>
    </div>
  `);
}

export const completeCheckout = async (
  {
    address,
    cardNumber,
    city,
    company,
    country,
    cvv,
    email,
    expMonth,
    expYear,
    firstName,
    lastName,
    nameOnCard,
    state,
    username,
    zipCode,
  },
  settings,
  baseUrl
) => {
  const selectedPlan = localStorage.getItem("selectedPlan");
  const data = JSON.stringify({
    subs_plan_uuid: selectedPlan,
    source: {
      cardholders_name: nameOnCard,
      number: cardNumber,
      month: expMonth,
      year: expYear,
      verification_value: cvv,
    },
    subscriber: {
      f_name: firstName,
      l_name: lastName,
      email,
      username,
      password,
    },
    bill_to: {
      f_name: firstName,
      l_name: lastName,
      company,
      address,
      city,
      state,
      zip: zipCode,
      country,
    },
  });
  return processSubscriptionCheckout(baseUrl, data);
};
