import { sendChannelAnalytics } from "../services/analyticService";

export const sendReportChannelStatistic = async (
  { apiKey, baseUrl },
  eventName,
  data,
  callback
) => {
  const body = JSON.stringify(data);
  const res = await sendChannelAnalytics(baseUrl, apiKey, body);
  if (callback != null) {
    callback(res);
  }
  return res;
};

export const getDefaultReportVOD = function (params) {
  return {
    currentPlaying: { vod: params.categoryId, video: params.videoId, uuid: "" },
    reportStatus: { send: { start: false, end: false, closingTab: false } },
  };
};

export const getDefaultReportAOD = function (params) {
  return {
    currentPlaying: { aod: params.categoryId, audio: params.audioId, uuid: "" },
    reportStatus: { send: { start: false, end: false, closingTab: false } },
  };
};

export const getDefaultReportVideoChannel = function (channel) {
  return {
    currentPlaying: { uuid: channel },
    reportStatus: { send: { start: false, end: false, closingTab: false } },
  };
};

export const getStatus = function () {
  return { send: { start: false, end: false, closingTab: false } };
};

export const getRqStartVOD = function (rp_data) {
  const { vod, video } = rp_data.currentPlaying;
  return { vod, video, platform: `WEB` };
};

export const getRqStartVideoChannel = function (rp_data) {
  const { uuid } = rp_data.currentPlaying;
  return { channel: uuid, platform: `WEB` };
};

export const getRqStartAOD = function (rp_data) {
  const { aod, audio } = rp_data.currentPlaying;
  return { aod, audio, platform: `WEB` };
};
