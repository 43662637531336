export const isAuth = () => {
  const tokenObj = JSON.parse(
    window.localStorage.getItem("loginToken") || "{}"
  );
  const access_token =
    (tokenObj.token ? tokenObj.token.access : tokenObj.access_token) || "";
  return !!access_token;
};

export const getAccessToken = () => {
  const tokenObj = JSON.parse(
    window.localStorage.getItem("loginToken") || "{}"
  );
  return (tokenObj.token ? tokenObj.token.access : tokenObj.access_token) || "";
};
